import { Outlet } from "react-router-dom";
import { GridWrapper, StackWrapper } from "../components";
import { Logo } from "../components/Logo/Logo";

export const AuthLayout = (): JSX.Element => {
  return (
    <GridWrapper
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: 'background.default' }}
    >
      <GridWrapper
        item
        className="box-shadow"
        xs={3}
        sx={{
          width: '100%',
          backgroundColor: 'primary.main',
          padding: 3,
          textAlign: 'center'
        }}
      >
        <StackWrapper
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Logo />
        </StackWrapper>
      </GridWrapper>
      <Outlet />
    </GridWrapper>
  )
}
