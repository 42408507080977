import { SaveNewDate } from '../../../infrastructure/dtos/NewDate'
import { NewDateService } from '../Persistencia/NewDateService'

export interface NewDateData {
  status: number
  data: string
}
export async function PostNewDateUtility(
  dataNewDate: SaveNewDate
): Promise<NewDateData> {
  try {
    const valores = await NewDateService(dataNewDate)
    if (valores.data.statusCode === 400) {
      return {
        data: 'El horario escogido ya no está disponible.',
        status: 0
      };
    }
    if (valores.status !== 1 || valores.data.statusCode !== 200) {
      return {
        data: 'Ups! Hubo complicaciones intente mas tarde',
        status: 0
      }
    }

    return {
      data: valores.data.body,
      status: valores.status
    }
  } catch (error) {
    return {
      data: 'Ups! Hubo complicaciones intente mas tarde',
      status: 0
    }
  }
}
