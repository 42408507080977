import { ButtonWrapper } from "../wrapper";
import { styled } from "@mui/material/styles";


export const ButtonGeneric = styled(ButtonWrapper)(() => ({
    width: '95%',
    borderRadius: '2.625rem',
    backgroundColor: '#6191fe',
    fontSize: '14px',
    height: '3.4375rem',
    textTransform: 'none'
}))