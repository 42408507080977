import { styled } from '@mui/material'
import CardWrapper from '../wrapper/CardWrapper'

export const CardInfo = styled(CardWrapper)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#F7F8FD',
  padding: '0 1.5%',
  borderRadius: '20px',
  width: '450px'
}))
