import { GridWrapper } from '../../../../../components'
import Marker from '../../../DoctorResultsGoogleMap/Components/Marker'
import { MedicalOffice } from '../../../../../infrastructure/dtos/Offices'

export interface IDataLocation {
  officeId?: string
  userId?: string
  name: string
  position: {
    lat: number
    lng: number
  }
  address: string
  price: string
  rating: string
  type: string
  photo?: string
}

interface ILocationPanelMap {
  map: google.maps.Map | undefined
  offices: MedicalOffice[]
  selectedMarker: (officeId: string) => void
}

export default function LocationPanelMap({
  map,
  offices,
  selectedMarker
}: ILocationPanelMap) {
  const handleMarkerClick = (officeId: string) => {
    selectedMarker(officeId)
  }

  return (
    <>
      {offices?.map((item) => {
        return (
          <Marker
            key={`officePosition-${item.office_id}`}
            map={map}
            position={{
              lat: Number(item.coordinates_medical_office.latitude),
              lng: Number(item.coordinates_medical_office.longitude)
            }}
            testId="patient-locate"
            onClick={() => handleMarkerClick(item.office_id)}
            children={
              <GridWrapper
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: item.selected ? 'primary.main' : '#878787',
                  borderRadius: 50
                }}
              />
            }
          />
        )
      })}
    </>
  )
}
