import React, { FunctionComponent } from "react";
import { SquareCheckbox } from "../StyledComponents/SquareCheckbox";
import { Checkbox } from "../StyledComponents/Checkbox";
import { GridGeneric } from "./CheckboxSquareStyled";



interface ICheckboxSquareProps {
    id: string,
    text: string,
    value: boolean,
    onChange: (value: React.SetStateAction<boolean>) => void
}

export const CheckboxSquare: FunctionComponent<ICheckboxSquareProps> = (
    { text, id, value, onChange }
) => {


    return (
        <GridGeneric item xs={12} >
            <SquareCheckbox
                control={
                    <Checkbox
                        id={id}
                        value={value}
                        onChange={(event) => onChange(event.target.checked)}
                    />
                }
                label={text}
            />
        </GridGeneric>
    )
};
