import { useContext, useEffect, useState } from 'react'
import CalendarInfo from '../../components/CalendarInfo'
import DoctorInfo from '../../components/DoctorInfo'
import { OfficeList } from '../../components/OfficesList/OfficesList'
import {
  GridContent,
  GridLayoutBottom,
  GridLayoutRight
} from './SelectScheduleStyle'
import dayjs from 'dayjs'
import { AvailableHours } from '../../components/AvailableHours/AvailableHours'
import SubmitButton from '../../../../components/SubmitButton'
import { SelectChangeEvent } from '@mui/material'
import { PatientAppointmentContext } from '../../../../contexts/PatientAppointmentContext'
import { GridHalfScreen } from '../../../../components/StyledComponents/GridHalfScreen'
import { useParams } from 'react-router-dom'
import {
  IAvailableHours,
  IResponseCalendar
} from '../../../../infrastructure/dtos/Calendar'
import { ICalendarData } from '../../../../infrastructure/dtos/CalendarInfo'
import { getAvailableHoursInADay } from '../../../../services/Contracts/Persistencia/Calendar'
import { GetDaysAvailabilityInAMonth } from '../../../../services/Contracts/Persistencia/CalendarInfoService'
import GridWrapper from '../../../../components/wrapper/GridWrapper'
import { useDataDoctor } from '../../Hooks/useDataDoctor'
import { LoaderWrapper } from '../../../../components'

interface SelectScheduleProps {
  onStepper: () => void
}

export const SelectSchedule = ({
  onStepper
}: SelectScheduleProps): JSX.Element => {
  const { PatientAppointment, saveScheduleSelected } = useContext(
    PatientAppointmentContext
  )
  const { idDoctor } = useParams<string>()
  const { dataDoctor } = useDataDoctor(idDoctor as string)
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [loadCalendar, setLoadCalendar] = useState<boolean>(false)
  const [showAvailableHours, setShowAvailableHours] = useState<boolean>(false)
  const [loadAvailableHours, setLoadAvailableHours] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(true)

  const [selectedOffice, setSelectedOffice] = useState<string>(
    PatientAppointment?.office_id
  )
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
    PatientAppointment?.date_appointment
      ? dayjs(PatientAppointment?.date_appointment)
      : null
  )
  const [selectedHour, setSelectedHour] = useState<string>(
    PatientAppointment?.hour_appointment
  )

  const [availableHours, setAvailableHours] = useState<IAvailableHours>()
  const [consultationTimeMinutes, setConsultationTimeMinutes] =
    useState<string>('')
  const [daysAvailability, setDaysAvailability] = useState<ICalendarData>()

  const fetchDaysAvailabilityInAMonth = async () => {
    const {
      data: { body }
    }: { data: { body: ICalendarData } } =
      await GetDaysAvailabilityInAMonth(idDoctor as string, selectedOffice)
    return body
  }

  useEffect(() => {
    if (selectedOffice) {
      setShowAvailableHours(false)
      setLoadCalendar(true)
      fetchDaysAvailabilityInAMonth().then((result) => {
        setDaysAvailability(result)
        setLoadCalendar(false)
        setShowCalendar(true)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffice])

  useEffect(() => {
    if (selectedDate) {
      setShowAvailableHours(true)
      fetchAvailableHoursInADay()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  useEffect(() => {
    if (selectedHour) setDisabledButton(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHour])

  const handleChangeOffice = (event: SelectChangeEvent<unknown>): void => {
    setSelectedOffice((event.target as HTMLSelectElement)?.value)
    setSelectedDate(null)
    setSelectedHour('')
    setDisabledButton(true)
  }
  const handleChangeDate = (value: dayjs.Dayjs | null): void => {
    setSelectedDate(value)
    setSelectedHour('')
    setDisabledButton(true)
  }
  const handleSelectedHour = (event: React.MouseEvent<HTMLElement>): void =>
    setSelectedHour((event.target as HTMLInputElement).value)

  const setSaveScheduleSelected = () => {
    saveScheduleSelected({
      ...PatientAppointment,
      office_id: selectedOffice,
      date_appointment: selectedDate?.format('YYYY-MM-DD') as string,
      hour_appointment: selectedHour,
      consultation_time_minutes: parseInt(consultationTimeMinutes.split(' ')[0])
    })
  }

  const handleGoNext = () => {
    setSaveScheduleSelected()
    onStepper()
  }

  const fetchAvailableHoursInADay = async (): Promise<void> => {
    try {
      setLoadAvailableHours(true)
      const data: IResponseCalendar<IAvailableHours> =
        await getAvailableHoursInADay({
          user_id: idDoctor as string,
          date_search: selectedDate?.format('YYYY-MM-DD') as string,
          office_id: selectedOffice
        })
      const availableHours: IAvailableHours = data.body as IAvailableHours
      setAvailableHours(availableHours)
      setConsultationTimeMinutes(availableHours.consultation_time_minutes)
      setShowAvailableHours(true)
      setLoadAvailableHours(false)
    } catch (error) {
      console.error('Error in fetchAvailableHoursInADay:', error)
      setShowAvailableHours(true)
      setLoadAvailableHours(false)
    }
  }

  return (
    <>
      <GridContent container>
        <GridHalfScreen
          item
          sm={6}
          style={{
            marginTop: '2%'
          }}
        >
          <DoctorInfo
            message="Selecciona el dia y hora para tu cita con Dr. "
            info={dataDoctor}
          />
          <GridWrapper
            sx={{ margin: '50px 20px', display: 'grid', width: '500px' }}
          >
            <OfficeList
              handleChangeOffice={handleChangeOffice}
              office={selectedOffice}
              idDoctor={idDoctor as string}
            />
          </GridWrapper>
          {loadCalendar && (
            <GridWrapper item display={'flex'} justifyContent={'center'}>
              <LoaderWrapper></LoaderWrapper>
            </GridWrapper>
          )}
          {!loadCalendar && showCalendar && (
            <CalendarInfo
              onChangeDate={handleChangeDate}
              selectedDate={selectedDate}
              daysAvailability={daysAvailability as ICalendarData}
            />
          )}
        </GridHalfScreen>
        <GridLayoutRight item sm={4}>
          {loadAvailableHours && (
            <GridWrapper item display={'flex'} justifyContent={'center'}>
              <LoaderWrapper></LoaderWrapper>
            </GridWrapper>
          )}
          {!loadAvailableHours && showAvailableHours && (
            <AvailableHours
              availableHours={availableHours as IAvailableHours}
              handleSelectedHour={handleSelectedHour}
              selectedHour={selectedHour}
            />
          )}
        </GridLayoutRight>
      </GridContent>
      <GridLayoutBottom>
        <SubmitButton
          id="next-button"
          dataTestid="next-button"
          onClick={handleGoNext}
          variant="contained"
          type="button"
          fullWidth
          disabled={disabledButton}
          text="Siguiente"
          sx={{ width: '60%' }}
        />
      </GridLayoutBottom>
    </>
  )
}
