import {
  IPhoneVerification,
  IPhoneVerificationResponse,
  IResendCodeParams,
  IUpdatePatientPhoneParams,
  IVerifyCodeParams
} from '../../../infrastructure/dtos/PhoneVerification'
import {
  ICodeService,
  resendCodeService,
  sendCodeService,
  verifyPhoneValidationService,
  verifyCodeService,
  updatePatientPhoneService
} from '../Persistencia/PhoneVerificationService'

export interface ICodeUtility<T> {
  status: number
  data: IPhoneVerificationResponse<T>
}

export async function resendCodeUtility(
  params: IResendCodeParams
): Promise<ICodeUtility<IPhoneVerification>> {
  try {
    const { data, status }: ICodeService<IPhoneVerification> =
      await resendCodeService(params)
    return {
      data: data.body,
      status: data.statusCode === 200 && status === 1 ? status : 0
    }
  } catch (error: any) {
    return {
      status: 0,
      data: error?.message
    }
  }
}

export async function sendCodeUtility(
  userId: string
): Promise<ICodeUtility<IPhoneVerification>> {
  try {
    const { data, status }: ICodeService<IPhoneVerification> =
      await sendCodeService(userId)
    return {
      data: data.body,
      status: data.statusCode === 200 && status === 1 ? status : 0
    }
  } catch (error: any) {
    return {
      status: 0,
      data: error?.message
    }
  }
}

export async function verifyPhoneValidationUtility(
  userId: string
): Promise<ICodeUtility<IPhoneVerification>> {
  try {
    const { data, status }: ICodeService<IPhoneVerification> =
      await verifyPhoneValidationService(userId)
    return {
      data: data.body,
      status: data.statusCode === 200 && status === 1 ? status : 0
    }
  } catch (error: any) {
    return {
      status: 0,
      data: error?.message
    }
  }
}

export async function VerifyCodeUtility(
  params: IVerifyCodeParams
): Promise<ICodeUtility<IPhoneVerification>> {
  try {
    const { data, status }: ICodeService<IPhoneVerification> =
      await verifyCodeService(params)
    return {
      data: data.body,
      status: data.statusCode === 200 && status === 1 ? status : 0
    }
  } catch (error: any) {
    return {
      status: 0,
      data: error?.message
    }
  }
}

export async function updatePatientPhoneUtility(
  params: IUpdatePatientPhoneParams
): Promise<ICodeUtility<string>> {
  try {
    const { data, status }: ICodeService<string> =
      await updatePatientPhoneService(params)
    return {
      data: data.body,
      status: data.statusCode === 200 && status === 1 ? status : 0
    }
  } catch (error: any) {
    return {
      status: 0,
      data: error?.message
    }
  }
}
