import { styled } from '@mui/material/styles'
import {
  ButtonWrapper,
  BoxWrapper,
  GridWrapper
} from '../../../components'

export const BoxPendingsStyled = styled(BoxWrapper)(() => ({
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    minHeight: '70px', 
    background: 'white', 
    borderRadius: '21px', 
    paddingLeft: '30px', 
    paddingRight: '30px',
    gridColumn: 'span 12'
}))

export const ButtonPendingsStyled = styled(ButtonWrapper)(() => ({
    borderRadius: '2rem', 
    padding: '3px 15px', 
    minWidth: '130px', 
    height: '40px', 
    textTransform: 'none'
}))

export const ButtonDoneStyled = styled(ButtonWrapper)(() => ({
    borderRadius: '2rem', 
    padding: '3px 15px', 
    minWidth: '130px', 
    height: '40px', 
    textTransform: 'none', 
    color: '#3850B2', 
    background: '#DCE7FF'
}))