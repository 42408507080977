import React from 'react'
import {
  GridWrapper,
  ListItemButtonWrapper,
  ListItemTextWrapper,
  ListWrapper
} from '../../../../components'
import { links } from './NavBarData'
import { NavigateFunction, useNavigate } from 'react-router-dom'

function NavBar(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()
  return (
    <ListWrapper style={{ display: 'flex' }}>
      {links.map((link) => (
        <ListItemButtonWrapper
          key={link.id}
          style={{
            display: 'block',
            textAlign: 'center',
            marginRight: '20px'
          }}
          onClick={() => navigate(link.path)}
        >
          <GridWrapper style={{ margin: 'auto' }}>{link.icon}</GridWrapper>
          <ListItemTextWrapper primary={link.name} />
        </ListItemButtonWrapper>
      ))}
    </ListWrapper>
  )
}

export default NavBar
