import { styled } from "@mui/material";
import { TypographyWrapper } from "../wrapper";

export const LabelInputError = styled(TypographyWrapper)(
  () => ({
    color: "red",
    fontSize: ".9rem",
    fontFamily: "Helvetica",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  })
);
