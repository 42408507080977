/* eslint-disable react-hooks/exhaustive-deps */
import { useState, MouseEvent, useEffect } from 'react'
import {
  IPastAppointment,
  IPastAppointmentResponse
} from '../../../../../infrastructure/dtos/Appointments'
import { pastAppointmentsUtility } from '../../../../../services/Contracts/Utility/AppointmentsUtility'

interface IPastDatesListHook {
  anchorEl: HTMLElement | null
  pastAppointments: IPastAppointment[]
  appointmentToModal?: IPastAppointment
  message?: string
  handleClick: (event: MouseEvent<HTMLElement>, appointmentIndex: number) => void
  handleClose: (appointmentIndex: number) => void
  handleAppointmentToModal: (appointment: IPastAppointment) => void
}

export function usePastAppointmentsList(): IPastDatesListHook {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [user_id] = useState<string>(
    JSON.parse(localStorage.getItem('userData') as string)?.userId
  )
  const [message, setMessage] = useState<string>()
  const [pastAppointments, setPastAppointments] = useState<IPastAppointment[]>(
    []
  )
  const [appointmentToModal, setAppointmentToModal] = useState<IPastAppointment>()
  const handleAppointmentToModal = (appointment: IPastAppointment): void => setAppointmentToModal(appointment)

  const fetchPastAppointments = async (): Promise<void> => {
    setMessage('')
    try {
      const { data, status } = await pastAppointmentsUtility(user_id)
      if (
        !status ||
        !(data as IPastAppointmentResponse).success ||
        (data as IPastAppointmentResponse).appointment.length === 0
      ) {
        setMessage(
          (data as IPastAppointmentResponse).message ||
            'Error al obtener los datos'
        )
        return
      }
      setPastAppointments((data as IPastAppointmentResponse).appointment)
    } catch (error: unknown) {
      console.error('error in fetchPastAppointments', error)
    }
  }

  useEffect(() => {
    fetchPastAppointments()
  }, [])

  const handleClick = (event: MouseEvent<HTMLElement>, appointmentIndex: number): void => {
    const newAppointments: IPastAppointment[] = [...pastAppointments]
    newAppointments[appointmentIndex].showMenu = true
    setPastAppointments(newAppointments)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (appointmentIndex: number): void => {
    const newAppointments: IPastAppointment[] = [...pastAppointments]
    newAppointments[appointmentIndex].showMenu = false
    setPastAppointments(newAppointments)
    setAnchorEl(null)
  }

  return {
    anchorEl,
    message,
    pastAppointments,
    appointmentToModal,
    handleClick,
    handleClose,
    handleAppointmentToModal
  }
}
