import { useState } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import {
  ButtonCorreo,
  ButtonSelect,
  TextFielRegister,
} from '../../../utils/RegisterMedicalFormStyles'
import { MapsGoogle } from './MapComponents/MapsGoogle'
import { AutocompleteGoogleMaps } from './MapComponents/AutocompleteGoogle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { StackWrapper, GridWrapper } from '../../../components/wrapper'
import { GridFullScreen } from '../../../components/GridFullScreen/GridFullScreen'
import { Title } from '../../../components/StyledComponents/Title'
import { GridHalfScreen } from '../../../components/StyledComponents/GridHalfScreen'
import { BackButton } from '../../../components/BackButton/BackButton'
import SubmitButton from '../../../components/SubmitButton'
import { Loading } from '../../../components/Loading/Loading'
import { useMaps } from './Hooks/useMaps'
import { LabelInput } from '../../../components/StyledComponents/LabelInput'
import { List } from '../../../components/List/List'
import { IAddressPatient, IPatientInfo } from '../types'

export interface IMapPageFormProps {
  onBack: () => void
  handlePatientAddresses: (addresses: IAddressPatient[]) => void
  patientInfo: IPatientInfo
  onChangeSubmit: () => void
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string
  structured_formatting: StructuredFormatting
}

export const RegisterAddressPatient: React.FC<IMapPageFormProps> = ({
  onBack,
  handlePatientAddresses,
  patientInfo,
  onChangeSubmit
}: IMapPageFormProps) => {
  const [, setValueEdition] = useState<PlaceType | null>(null)
  const [addressPatient, setAddressPatient] = useState<IAddressPatient[]>(patientInfo.addresses);
  const { zoom,
    markerPosition,
    address,
    selected,
    setAddress,
    setMapCenter,
    setMarkerPosition,
    setSelected,
    setZoom,
    mapCenter,
    nameLocation,
    setNameLocation,
    editar,
    eliminar,
    modificar,
    newAddressPatient,
    optionsList,
    addressEdition,
    value, 
    setValue
  } = useMaps({ addressPatient, setAddressPatient })



  const { isLoaded } = useJsApiLoader({
    // googleMapsApiKey: process.env.REACT_KEY_GOOGLE_MAP ?? "",
    googleMapsApiKey: "AIzaSyA6dlQ5VQlycFtui2MCzPoMbnPji7sLiJw",
    libraries: ["places"],
  });
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const onChangeNameAddress = (value: string): void => {
    setNameLocation(value)
  }

  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();
    addressPatient.map((value: any) => {
      let aux = value;
      delete aux.value
      delete aux.id
      delete aux.state

      return aux
    })

    handlePatientAddresses(addressPatient)
    onChangeSubmit()
    //setIsSubmited(true)
  }

  const handleGoBack = () => {
    handlePatientAddresses(addressPatient)
    onBack()
  }

  return isLoaded ? (
    <GridFullScreen id="RegisterConsultoryForm">
      <GridHalfScreen
        style={{
          flexBasis: '10%'
        }}
      >
        <BackButton
          id="backButton"
          text="Regresar"
          onClick={handleGoBack}
        />
      </GridHalfScreen>
      <GridHalfScreen
        style={{
          flexBasis: '90%',
          borderRadius: 2,
          textAlign: 'center',
          marginLeft: '10%',
          marginRight: '20%',
          marginTop: '2%'
        }}
      >
        <Title>Guarda tu dirección</Title>
        <form onSubmit={handleSubmit} data-testid="consultory-form">
          <GridWrapper item xs={12} sx={{ mt: 2 }}>
            <StackWrapper spacing={2} direction="row" sx={{ marginBottom: 4 }}>
              <GridWrapper item xs={6}>
                <GridWrapper sx={{ width: "100%" }}>
                  <LabelInput>Guarda tus direcciones para buscar los médicos que se encuentren cerca a ti</LabelInput>
                  <LabelInput sx={{ marginTop: 4 }}>Nombre</LabelInput>
                  <List
                    id="nameLocation"
                    lists={optionsList}
                    minWidth='100%'
                    onClick={onChangeNameAddress}
                    value={nameLocation}
                  />
                  <LabelInput sx={{ marginTop: 2 }}>Dirección</LabelInput>
                  <AutocompleteGoogleMaps
                    address={address}
                    setMarkerPosition={setMarkerPosition}
                    setZoom={setZoom}
                    setAddress={setAddress}
                    setMapCenter={setMapCenter}
                    isLoaded={isLoaded}
                    setSelected={setSelected}
                    selected={selected}
                    value={value}
                    setValue={setValue}
                  />
                </GridWrapper>

                <StackWrapper
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mb: 4, mt: 2 }}
                >
                  <GridWrapper sx={{ width: "300px" }}></GridWrapper>
                  <ButtonCorreo
                    id="add"
                    variant="contained"
                    role="button"
                    sx={{ width: '45%' }}
                    onClick={newAddressPatient}
                    disabled={
                      (!nameLocation || !address) || addressPatient.length >= 5
                    }
                  >
                    Agregar otra
                  </ButtonCorreo>
                </StackWrapper>
                {
                  addressPatient.length > 0 && (
                    <LabelInput sx={{ marginTop: 2 }}>Direcciones guardadas</LabelInput>
                  )

                }
                {addressPatient?.map((item: any, index: number) => {
                  return (
                    <GridWrapper
                      key={index}
                      item
                      sx={{
                        mb: 4,
                        mt: 2,
                        background: 'white',
                        borderRadius: '40px'
                      }}
                    >
                      <AutocompleteGoogleMaps
                        address={address}
                        setMarkerPosition={setMarkerPosition}
                        setZoom={setZoom}
                        setAddress={setAddress}
                        setMapCenter={setMapCenter}
                        isLoaded={isLoaded}
                        setSelected={setSelected}
                        selected={selected}
                        isDisabled={!item.state}
                        value={item.value}
                        setValue={setValueEdition}
                        editingValueEdition={addressEdition}
                        id={item.id}
                      />

                      <TextFielRegister
                        sx={{
                          height: !item.state ? '35px' : 'auto',
                          '& .MuiInputBase-root': {
                            backgroundColor: !item.state ? 'white' : '#fafafa'
                          }
                        }}
                        placeholder="Edificio, Piso, Consultorio"
                        disabled
                        type="text"
                        value={item.name}
                        onChange={(event) => editar(item, event.target.value)}
                        fullWidth
                        multiline
                        maxRows={4}
                      />

                      <StackWrapper
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ButtonSelect
                          sx={{ mb: 2, mt: 2, height: '30px' }}
                          variant="contained"
                          role="button"
                          onClick={() => modificar(item)}
                        >
                          {item.state ? (
                            <>
                              <SaveIcon /> &nbsp; Guardar
                            </>
                          ) : (
                            <>
                              <EditIcon /> &nbsp; Modificar
                            </>
                          )}
                        </ButtonSelect>

                        <ButtonSelect
                          sx={{ mb: 2, mt: 2, height: '30px' }}
                          variant="contained"
                          role="button"
                          onClick={() => eliminar(item)}
                        >
                          <DeleteOutlineIcon />
                          &nbsp; Eliminar
                        </ButtonSelect>
                      </StackWrapper>
                    </GridWrapper>
                  )
                })}
              </GridWrapper>

              <MapsGoogle
                map={map}
                markerPosition={markerPosition}
                zoom={zoom}
                address={address}
                mapCenter={mapCenter}
                setMap={setMap}
                setMarkerPosition={setMarkerPosition}
                setZoom={setZoom}
                setAddress={setAddress}
                setMapCenter={setMapCenter}
                isLoaded={isLoaded}
                setSelected={setSelected}
              />
            </StackWrapper>
          </GridWrapper>
          <GridWrapper item xs={12} sx={{ my: 2 }}>
            <SubmitButton
              id="button"
              type="submit"
              variant="contained"
              fullWidth
              text="Siguiente"
              data-testid="ok-button"
              disabled={addressPatient.length === 0}
            />
          </GridWrapper>
        </form>
      </GridHalfScreen>
    </GridFullScreen>
  ) : (
    <Loading />
  )
}

export default RegisterAddressPatient
