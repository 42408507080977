import React, { createContext, ReactNode, useEffect, useReducer } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { Auth0UserProfile } from 'auth0-js'
import { IPatientInfo } from '../views/Register/types'
import { GetPatientRegisterUtility } from '../services/Contracts/Utility/PatientUtility'
import {
  BodyPatientInfo,
  IUtilityPatientInfo
} from '../infrastructure/dtos/Patient'
// import {
//   AuthenticationDetails,
//   CognitoUser,
//   CognitoUserSession
// } from 'amazon-cognito-identity-js'
import UserPool from '../utils/UserPool'
import { authReducer, AuthState, GetSessionProps, Severity } from './AuthReducer'
import AsyncStorage from '@react-native-async-storage/async-storage'
import CustomSnackbar from '../components/Snackbar'

interface Props {
  children?: ReactNode
}

export type AuthContextType = {
  errorMessage: string
  token: string | null
  user: any | null
  status: 'checking' | 'authenticated' | 'not-authenticated'
  patient: IPatientInfo
  getSession?: () => Promise<GetSessionProps | undefined>
  authenticate: (token: string, user: Auth0UserProfile) => void
  notAuthenticate: (error: string) => void
  logout: () => void
  removeError: () => void
  savePatientData: (patient: IPatientInfo) => void
  CheckToken: (path: string, token: string, user: Auth0UserProfile) => void
  handleAlert(open: boolean, message: string, severity?: Severity): void
}

export const initialPatient: IPatientInfo = {
  name: '',
  lastName: '',
  idNumber: '',
  mobile: '',
  addresses: []
}

const authInicialState: AuthState = {
  status: 'checking',
  token: null,
  user: null,
  errorMessage: '',
  patient: initialPatient,
  snack: {
    open: false,
    message: '',
    severity: undefined
  }
}

const AccountContext = createContext<AuthContextType | null | boolean>(null)

const AuthProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(authReducer, authInicialState)
  const navigate = useNavigate()
  const location = useLocation()

  const handleAlert = (open: boolean, message: string, severity?: Severity): void => dispatch({
    type: 'snack',
    payload: {
      open, message, severity
    }
  })

  useEffect(() => {
    const cookies = new Cookies()
    if (cookies.get('access_token')) {
      console.log('TEST ACCOUNT CONTEXT t')
      CheckToken(location.pathname, cookies.get('access_token'))
    } else {
      dispatch({ type: 'notAuthenticated' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const checkToken = async () => {
  //   getSession()
  //     .then(async (session: GetSessionProps | undefined) => {
  //       if (session?.headers.Authorization === undefined) {
  //         return dispatch({ type: "notAuthenticated" });
  //       }
  //       localStorage.setItem('userData', JSON.stringify({
  //         userId: session.user.getSignInUserSession()?.getIdToken().payload.sub,
  //         email: session.user.getSignInUserSession()?.getIdToken().payload.email
  //       }));
  //       dispatch({
  //         type: "signUp",
  //         payload: {
  //           token: session.headers.Authorization,
  //           user: session,
  //         },
  //       });
  //       const user: IServiceResponse = await DoctorInfoService(
  //         session.user.getSignInUserSession()?.getIdToken().payload.sub
  //       )
  //       saveInfoScreen1(user.data.body)
  //       if (Object.keys(user.data.body).length === 0) {
  //         navigate("/medico/register/profile")
  //       }
  //     })
  //     .catch((error) => {
  //       dispatch({ type: "notAuthenticated" });
  //     });
  // };

  const CheckToken = async (
    path: string,
    token?: string,
    user?: Auth0UserProfile
  ) => {
    const cookies = new Cookies()
    if (
      cookies.get('access_token') &&
      JSON.parse(localStorage.getItem('userData') as string)
    ) {
      const { userId }: { userId: string | undefined } = JSON.parse(
        localStorage.getItem('userData') as string
      )

      dispatch({
        type: 'signUp',
        payload: {
          token: cookies.get('access_token'),
          user: {
            user,
            headers: {
              Authorization: cookies.get('access_token')
            }
          }
        }
      })

      const userBody: IUtilityPatientInfo = await GetPatientRegisterUtility(
        userId as string
      )

      console.log('.......')
      console.log(userBody)

      if (
        userBody.status === 0 &&
        !path.includes('/medical-consultation/')
      ) {
        navigate('/register')
        return
      }
      savePatientData({
        userId: (userBody?.data as BodyPatientInfo).user_id,
        name: (userBody?.data as BodyPatientInfo).name_patient,
        lastName: (userBody?.data as BodyPatientInfo).last_name_patient,
        idNumber: (userBody?.data as BodyPatientInfo).identification_patient,
        mobile: (userBody?.data as BodyPatientInfo).number_phone_patient,
        email: (userBody?.data as BodyPatientInfo).email_patient,
        addresses:
          (userBody?.data as BodyPatientInfo)?.locations_patient &&
          (userBody?.data as BodyPatientInfo)?.locations_patient?.map(
            (address) => ({
              address: address.address_locations_patient,
              name: address.name_locations_patient,
              coordinates: address.coordinates_medical_office
            })
          )
      })
    } else {
      return dispatch({ type: 'notAuthenticated' })
    }

    // getSession()
    //   .then(async (session: GetSessionProps | undefined) => {
    //     if (session?.headers.Authorization === undefined) {
    //       return dispatch({ type: "notAuthenticated" });
    //     }
    //     localStorage.setItem('userData', JSON.stringify({
    //       userId: session.user.getSignInUserSession()?.getIdToken().payload.sub,
    //       email: session.user.getSignInUserSession()?.getIdToken().payload.email
    //     }));
    //     dispatch({
    //       type: "signUp",
    //       payload: {
    //         token: session.headers.Authorization,
    //         user: session,
    //       },
    //     });
    //     const user: IServiceResponse = await DoctorInfoService(
    //       session.user.getSignInUserSession()?.getIdToken().payload.sub
    //     )
    //     saveInfoScreen1(user.data.body)
    //     if (Object.keys(user.data.body).length === 0) {
    //       navigate("/medico/register/profile")
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch({ type: "notAuthenticated" });
    //   });
  }

  // const getSession = async () =>
  //   await new Promise<GetSessionProps | undefined>((resolve, reject) => {
  //     const user: CognitoUser | null = UserPool.getCurrentUser();
  //     if (user) {
  //       user.getSession((err: any, session: CognitoUserSession) => {
  //         if (err) {
  //           reject(err);
  //         } else {
  //           const token = session.getAccessToken().getJwtToken();

  //           resolve({
  //             user,
  //             headers: {
  //               Authorization: token,
  //             },
  //             session,
  //           });
  //         }
  //       });
  //     } else {
  //       reject("Sin usuario");
  //     }
  //   });

  // const authenticate = async (Username: string, Password: string) =>
  //   await new Promise<CognitoUserSession | null>((resolve, reject) => {
  //     const user = new CognitoUser({ Username, Pool: UserPool });
  //     const authDetails = new AuthenticationDetails({ Username, Password });

  //     user.authenticateUser(authDetails, {
  //       onSuccess: (data) => {
  //         checkToken();
  //         resolve(data);
  //       },
  //       onFailure: (err) => {
  //         reject(err);
  //       },
  //       newPasswordRequired: (data) => {
  //         resolve(data);
  //       },
  //     });
  //   });

  const authenticate = (token: string, user: any): void => {
    // checkToken(token, user);

    dispatch({
      type: 'signUp',
      payload: {
        token,
        user
      }
    })
  }

  const notAuthenticate = (error: string): void => {
    dispatch({ type: 'notAuthenticated' })

    // dispatch({
    //   type: "signUp",
    //   payload: {
    //     token,
    //     user,
    //   },
    // });
  }

  const logout = async () => {
    const user = UserPool.getCurrentUser()
    if (user) {
      user.signOut()
      await AsyncStorage.removeItem('token')
      savePatientData(initialPatient)
      dispatch({ type: 'logout' })
    }

    localStorage.removeItem('userData')
    const cookies = new Cookies()
    cookies.remove('access_token', { path: '/' })
    dispatch({ type: 'notAuthenticated' })
    navigate('/auth/login')
  }

  const savePatientData = async (patient: IPatientInfo) => {
    dispatch({
      type: 'patientData',
      payload: {
        patient: patient
      }
    })
  }

  const removeError = () => {
    dispatch({ type: 'removeError' })
  }

  return (
    <AccountContext.Provider
      value={{
        ...state,
        authenticate,
        logout,
        CheckToken,
        removeError,
        savePatientData,
        notAuthenticate,
        handleAlert
      }}
    >
      {children}
      <CustomSnackbar
        handleAlert={handleAlert}
        openAlert={state.snack.open}
        message={state.snack.message}
        severity={state.snack.severity}
      />
    </AccountContext.Provider>
  )
}

export { AuthProvider, AccountContext }
