import React, { SyntheticEvent, useEffect, useState } from 'react'
import { GridWrapper, ModalWrapper } from '../../../../../components/wrapper'
import { Backdrop } from '@mui/material'
import {
  CloseButton,
  FadeModal,
  TypographyModal,
  TypographyTitleModal,
  BoxModal,
  GridRating,
  TypographyText,
  TextAreaField
} from './styles'

import CloseIcon from '@mui/icons-material/Close'
import RatingWrapper from '../../../../../components/wrapper/RatingWrapper'
import SubmitButton from '../../../../../components/SubmitButton'
import { CheckboxSquare } from '../../../../../components/CheckboxSquare/CheckboxSquare'
import { QualificationQuestionsService } from '../../../../../services/Contracts/Persistencia/QualificationQuestionsService'
import {
  Question,
  QualificationQuestions
} from '../../../../../infrastructure/dtos/QualificationQuestions'
import SelectButtonList from '../../../../../components/SelectButtonList/SelectButtonList'
import { Answer } from '../../../../../infrastructure/dtos/SaveQualifications'
import { SaveQualificationService } from '../../../../../services/Contracts/Persistencia/SaveQualificationService'
import { IAppointmentQualification } from '../../../../../infrastructure/dtos/PendingTasks'

interface ModalProps {
  handleClose: () => void
  open: boolean
  pending: IAppointmentQualification | undefined
  fetchPendingsTasks: (userId: string) => Promise<void>
}

function QualifyDoctorModal({
  handleClose,
  open,
  pending,
  fetchPendingsTasks
}: ModalProps): JSX.Element {
  const [user_id] = useState<string>(
    JSON.parse(localStorage.getItem('userData') as string)?.userId
  )
  const [rating, setRating] = useState<number>(0)
  const [anonymous, setAnonymous] = useState<boolean>(false)
  const [item, setItem] = useState<QualificationQuestions>()
  const [answeredQuestions, setAnsweredQuestions] = useState<Answer[]>([])
  const [comments, setComments] = useState<string>('')

  useEffect(() => {
    GetQuestions()
  }, [])

  const GetQuestions = async (): Promise<void> => {
    try {
      const response = await QualificationQuestionsService()

      setAnsweredQuestions(
        response.data.body.questions.map((question: Question) => {
          return {
            question: question.question_id,
            answer: ''
          }
        })
      )

      setItem(response.data.body)
    } catch (error) {
      console.error('Error fetching questions:', error)
    }
  }

  const handleAnsweredQuestions = (val: string, index: number): void => {
    const newArray: Answer[] = [...answeredQuestions]
    newArray[index].answer = val
    setAnsweredQuestions(newArray)
  }

  const handleSubmit = async (e: {
    preventDefault: () => void
  }): Promise<void> => {
    e.preventDefault()
    try {
      await SaveQualificationService({
        comment: comments,
        answers: answeredQuestions,
        qualification: rating,
        anonymous: anonymous,
        appointment_id: pending?.appointment_id
      })
      fetchPendingsTasks(user_id)
      handleClose()
    } catch (error) {
      console.log('🚀 ~ file: index.tsx:89 ~ handleSubmit ~ error:', error)
    }
  }

  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-qualify-doctor"
      onClose={handleClose}
      open={open}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <FadeModal in={open}>
        <BoxModal>
          <GridWrapper sx={{ padding: '10px 30px' }}>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            <TypographyTitleModal>
              {`Gana ${item?.points_to_win} puntos por calificar`}
            </TypographyTitleModal>
            <form onSubmit={handleSubmit}>
              <GridWrapper>
                <TypographyModal>
                  {`¿En general, qué tanto recomendarías al Dr. ${pending?.last_name_doctor}?`}
                </TypographyModal>
                <GridRating>
                  <RatingWrapper
                    name="simple-controlled"
                    value={rating}
                    max={Number(item?.recommendation_scale) || 0}
                    onChange={(
                      event: SyntheticEvent,
                      newRating: number | null
                    ) => {
                      setRating(newRating as number)
                    }}
                    sx={{ color: 'primary.dark', fontSize: '40px' }}
                  />
                </GridRating>
                {item?.questions.map((question, index) => (
                  <GridWrapper
                    sx={{ margin: '10px 0' }}
                    key={question.question_id}
                  >
                    <TypographyText>{question.question}</TypographyText>
                    <GridWrapper>
                      <SelectButtonList
                        amountRanges={question.answers}
                        selectedAmountRange={answeredQuestions[index]}
                        onAmountRangeChange={(item: string) =>
                          handleAnsweredQuestions(item, index)
                        }
                      />
                    </GridWrapper>
                  </GridWrapper>
                ))}

                <GridWrapper>
                  <TypographyText>
                    {`Déjanos tus comentarios ¿Hay algo en lo que el`}
                    <br />
                    {`Dr. ${pending?.last_name_doctor} pueda mejorar?`}
                  </TypographyText>
                  <GridWrapper sx={{ margin: '10px 0' }}>
                    <TextAreaField
                      multiline
                      rows={4}
                      fullWidth
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '4.5rem',
                          fontSize: '15px'
                        }
                      }}
                      value={comments}
                      inputProps={{
                        'aria-label': 'comments',
                        maxLength: 151
                      }}
                      error={comments.length > 150}
                      onChange={(event) => setComments(event.target.value)}
                      helperText={
                        comments.length > 150
                          ? 'Máximo número de caracteres 150'
                          : ''
                      }
                      id="comments-test"
                    />
                  </GridWrapper>
                </GridWrapper>
                <GridWrapper>
                  <CheckboxSquare
                    text="Deseo que mi calificación sea anónima"
                    id="checkboxSesion"
                    value={anonymous}
                    onChange={setAnonymous}
                  />
                </GridWrapper>
                <GridWrapper xs={5} sx={{ margin: '10px auto' }}>
                  <SubmitButton
                    fullWidth
                    id="button-qualification"
                    text="Calificar"
                    type="submit"
                    variant="contained"
                    dataTestid="button-qualification-submit"
                    disabled={
                      !rating ||
                      answeredQuestions.some((x) => !x.answer) ||
                      comments.length > 150
                    }
                  />
                </GridWrapper>
              </GridWrapper>
            </form>
          </GridWrapper>
        </BoxModal>
      </FadeModal>
    </ModalWrapper>
  )
}

export default QualifyDoctorModal
