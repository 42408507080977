import {
  QualificationDoctor,
  QualificationDoctorResponse
} from '../../infrastructure/dtos/SaveQualifications'
import { SaveQualificationRepository } from '../repository/SaveQualificationRepository'

export interface SaveQualificationUseCase {
  invoke: (
    dataQuestions: QualificationDoctor
  ) => Promise<QualificationDoctorResponse>
}
export default class SaveQualification implements SaveQualificationUseCase {
  private objectUseCase: SaveQualificationRepository

  constructor(_objectUseCase: SaveQualificationRepository) {
    this.objectUseCase = _objectUseCase
  }
  invoke(dataQuestions: QualificationDoctor) {
    return this.objectUseCase.postNewDate(dataQuestions)
  }
}
