import {
  IServicePatientInfo,
  IUtilityPatientInfo,
} from "../../../infrastructure/dtos/Patient";
import { GetPatientInfoService } from "../Persistencia/PatientService";

export async function GetPatientRegisterUtility(
  data: string
): Promise<IUtilityPatientInfo> {
  const dataReturnError: IUtilityPatientInfo = {
    data: "Ups! Hubo complicaciones intente mas tarde",
    status: 0,
  };
  try {
    const valueData: IServicePatientInfo = await GetPatientInfoService(data);
    console.log('Into service')
    console.log(valueData)
    if (
      typeof valueData.data !== "string" &&
      valueData.data.statusCode === 200 &&
      valueData.status === 1
    ) {
      return {
        data: valueData.data.body,
        status: valueData.status,
      };
    } else {
      return dataReturnError;
    }
  } catch (error) {
    return dataReturnError;
  }
}
