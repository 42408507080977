import { styled } from '@mui/material'
import {
  BoxWrapper,
  FadeWrapper,
  GridWrapper,
  IconButtonWrapper,
  TextFieldWrapper,
  TypographyWrapper
} from '../../../../../components/wrapper'

export const FadeModal = styled(FadeWrapper)(() => ({
  width: '48%',
  maxWidth: '588px',
  height: 'auto',
  overflowY: 'auto'
}))

export const TypographyModal = styled(TypographyWrapper)((props) => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '15px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: props.theme.palette.primary.dark
}))

export const CloseButton = styled(IconButtonWrapper)((props) => ({
  position: 'absolute',
  right: 20,
  top: 20,
  color: props.theme.palette.primary.dark,
  marginBottom: '20px'
}))

export const TypographyTitleModal = styled(TypographyWrapper)((props) => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '20px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  lineHeight: '20px',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#000',
  marginBottom: '20px'
}))

export const BoxModal = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  minHeight: '500px',
  bgcolor: theme.palette.background.paper,
  background: '#fff',
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)',
  padding: '40px 30px'
}))

export const GridRating = styled(GridWrapper)((props) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '10px 0'
}))

export const TypographyText = styled(TypographyWrapper)((props) => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '14px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center'
}))

export const TextAreaField = styled(TextFieldWrapper)(() => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    borderRadius: '20px',
    backgroundColor: '#fff',
    fontSize: '1.125rem',
    color: '#7f7f7f',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '4rem',
    boxShadow: '2px 3px 8px 7px rgba(0, 0, 0, 0.06)',
    width: '100%',
    maxWidth: '500px'
  },
  '& fieldset': { borderColor: 'white' },
  '&::placeholder': {
    marginLeft: '1.875rem'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: 'white'
    }
  }
}))
