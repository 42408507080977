import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ISaveRegisterPatient,
  LocationsPatient
} from '../../../infrastructure/dtos/PatientRegister'
import { IPatientInfo } from '../types'
import { PostPatientRegisterUtility } from '../../../services/Contracts/Utility/RegisterPatientUtility'
import { IService } from '../../../services/Contracts/Persistencia/InterfaceServiceResponse'
import {
  AccountContext,
  AuthContextType
} from '../../../contexts/AccountContext'

interface ISaveProfileMedicalHook {
  SaveData: () => Promise<void>
  message: IMessage
  openAlert: boolean
  loading: boolean
  onOption: () => void
}
interface IMessage {
  title: string
  message: string
  navigate: boolean
}

interface IUserData {
  userId: string
  email: string
}

export function useRegisterPatient(
  patient: IPatientInfo
): ISaveProfileMedicalHook {
  const [openAlert, setOpen] = useState<boolean>(false)
  const [loading, setloading] = useState<boolean>(false)
  const { savePatientData } = useContext(AccountContext) as AuthContextType
  const [message, setMessage] = useState<IMessage>({
    message: '',
    title: 'Aviso',
    navigate: false
  })
  const [user] = useState<IUserData>({
    userId: JSON.parse(localStorage.getItem('userData') as string).userId,
    email: JSON.parse(localStorage.getItem('userData') as string).email
  })
  const navigate = useNavigate()

  const onOption = (): void => {
    if (message.navigate) {
      navigate('/Dashboard/inicio')
      setloading(false)
      window.location.reload()
    } else {
      setloading(false)
      setOpen(false)
    }
  }

  /**
   * Structuring Data medical
   * @returns dataSaveProfile: ISaveRegisterMedical
   */
  const StructuringData = (): ISaveRegisterPatient => {
    let profileDataToSend: LocationsPatient[] = []
    for (let i = 0; i < patient.addresses.length; i++) {
      profileDataToSend.push({
        address_locations_patient: patient.addresses[i].address,
        coordinates_medical_office: patient.addresses[i].coordinates,
        name_locations_patient: patient.addresses[i].name
      })
    }

    const dataSaveProfile: ISaveRegisterPatient = {
      user_id: user.userId,
      email_patient: user.email,
      name_patient: patient.name,
      last_name_patient: patient.lastName,
      number_phone_patient: patient.mobile,
      identification_patient: patient.idNumber,
      locations_patient: profileDataToSend
    }
    return dataSaveProfile
  }
  /**
   *
   * @returns Result Data
   */
  const SaveData = async (): Promise<void> => {
    setloading(true)
    const dataSavePatient: ISaveRegisterPatient = StructuringData()
    const ResultAPISavePatient: IService = await PostPatientRegisterUtility(
      dataSavePatient
    )

    console.log(
      '🚀 ~ file: useSaveProfileMedical.ts:132 ~ SaveData ~ ResultAPISaveMedical:',
      ResultAPISavePatient
    )
    setOpen(true)
    if (ResultAPISavePatient.status === 1) {
      savePatientData({
        userId: dataSavePatient.user_id,
        name: dataSavePatient.name_patient,
        lastName: dataSavePatient.last_name_patient,
        idNumber: dataSavePatient.identification_patient,
        mobile: dataSavePatient.number_phone_patient,
        email: dataSavePatient.email_patient,
        addresses:
          dataSavePatient.locations_patient &&
          dataSavePatient.locations_patient.map((address) => ({
            address: address.address_locations_patient,
            name: address.name_locations_patient,
            coordinates: address.coordinates_medical_office
          }))
      })
      setMessage({
        title: 'AVISO',
        message: ResultAPISavePatient.data,
        navigate: true
      })
    } else {
      setMessage({
        title: 'AVISO',
        message: ResultAPISavePatient.data,
        navigate: false
      })
    }
  }

  return {
    SaveData,
    message,
    onOption,
    openAlert,
    loading
  }
}
