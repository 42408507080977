import { IApiResponseSpecialties } from "../../dtos";
import { http } from "../Http";
import SpecialtiesDataSource from "../dataSource/SpecialtiesDataSource";

export default class SpecialtiesMapper implements SpecialtiesDataSource {
  async postSpecialties(specialties: string): Promise<IApiResponseSpecialties> {
    let data = await http
      .post<IApiResponseSpecialties>(`/save_data/specialties `, {
        specialties: specialties,
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        const data: IApiResponseSpecialties = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            "Access-Control-Allow-Headers": "",
            "Access-Control-Allow-Origin": "",
            "Access-Control-Allow-Methods": "",
          },
        };
        return data;
      });
    return data;
  }
  async getSpecialties(): Promise<IApiResponseSpecialties> {
    let data = await http
      .get<IApiResponseSpecialties>(`/send_data/specialties`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        const data: IApiResponseSpecialties = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            "Access-Control-Allow-Headers": "",
            "Access-Control-Allow-Origin": "",
            "Access-Control-Allow-Methods": "",
          },
        };
        return data;
      });
    return data;
  }
}
