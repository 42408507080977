import { Circle } from "@mui/icons-material";

const RadioButtonUncheckedOutlinedIcon = () => (
  <Circle sx={{
    width: '15px',
    marginRight: '5px'
    // backgroundColor: '#75c44c'
  }}  />
);

export default RadioButtonUncheckedOutlinedIcon;