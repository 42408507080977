import { SaveFollow } from '../../../domain/useCase/SaveFollow'
import { FollowRepositoryImpl } from '../../../infrastructure/RepositoryImpl/FollowRepositoryImpl'
import { SaveDataFollow } from '../../../infrastructure/dtos/Follow'
import FollowMapper from '../../../infrastructure/http/mappers/FollowMapper'

export async function FollowService(params: SaveDataFollow) {
  try {
    const UseCaseFollow: SaveFollow = new SaveFollow(
      new FollowRepositoryImpl(new FollowMapper())
    )
    return {
      status: 1,
      data: await UseCaseFollow.invoke(params)
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error
    }
  }
}
