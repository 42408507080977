import React, { useContext } from 'react'
import BreadcrumbsWrapper from '../../../../../components/wrapper/BreadcrumbsWrapper'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { LinkWrapper } from '../../../../../components'
import {
  PatientAppointmentContext,
  PatientAppointmentInitState
} from '../../../../../contexts/PatientAppointmentContext'

interface IBreadcrumbsBookingProps {
  isReschedule: boolean
}

function BreadcrumbsBooking({isReschedule}: IBreadcrumbsBookingProps): JSX.Element {
  const { saveScheduleSelected } = useContext(PatientAppointmentContext)

  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(isReschedule ? '/dates' : '/appointment')
    saveScheduleSelected(PatientAppointmentInitState)
  }

  const titleLink: string[] = [
    isReschedule ? 'Citas' : 'Resultados de busqueda',
    isReschedule ? 'Reagendar' : 'Nueva cita',
  ]

  return (
    <BreadcrumbsWrapper
      separator={<ArrowBackIosIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ margin: '10px 0 30px 0', color: 'primary.dark' }}
    >
      <LinkWrapper
        underline="hover"
        color="inherit"
        onClick={handleGoBack}
        role="link"
        aria-current="page"
      >
        {titleLink[0]}
      </LinkWrapper>
      <LinkWrapper
        underline="hover"
        color="inherit"
        aria-current="page"
        role="link"
      >
        {titleLink[1]}
      </LinkWrapper>
    </BreadcrumbsWrapper>
  )
}

export default BreadcrumbsBooking
