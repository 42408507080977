import { styled } from '@mui/material'
import {
  BoxWrapper,
  FadeWrapper,
  GridWrapper,
  IconButtonWrapper
} from '../../../../../../components/wrapper'

export const CloseButton = styled(IconButtonWrapper)((props) => ({
  position: 'absolute',
  right: 20,
  top: 20,
  color: props.theme.palette.primary.dark,
  marginBottom: '20px'
}))

export const GridConfirm = styled(GridWrapper)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '23px',
  marginTop: '30px'
}))

export const FadeModal = styled(FadeWrapper)(() => ({
  width: '35%',
  height: 'auto',
  overflowY: 'auto'
}))

export const BoxModal = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  height: '400px',
  bgcolor: theme.palette.background.paper,
  background: '#fff',
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)'
}))

export const GridModal = styled(GridWrapper)(() => ({
  padding: '60px'
}))
