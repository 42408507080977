import { styled } from '@mui/material'
import { AvatarWrapper, ButtonWrapper } from '../../../../../components/wrapper'

export const AvatarPastAppointment = styled(AvatarWrapper)(() => ({
  width: '50px',
  height: '50px'
}))

export const PrimaryDarkButton = styled(ButtonWrapper)(({theme}) => ({
  borderRadius: '2.625rem',
  backgroundColor: theme.palette.primary.dark,
  fontSize: '14px',
  height: '43px',
  textTransform: 'none'
}))