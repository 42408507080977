import { useEffect, useState } from "react"
import { GridLayout } from "../ScheduleAppointment/ScheduleAppointmentStyle"
import PatientForm from "./PatientForm/PatientForm"
import RegisterAddressPatient from "./PatientMap/RegisterAddressForm"
import { usePatient } from "./Hooks/usePatient"
import { useRegisterPatient } from "./Hooks/useRegisterPatient"
import { Advert } from "../../components/Notification/Advert/Advert"
import { Loading } from "../../components/Loading/Loading"

enum STEPS {
  PATIENT_FORM,
  SELECT_ADDRESS,
}

export const PatientRegister = (): JSX.Element => {
  const { patient, setPatientInfo, setPatientAddresses } = usePatient()
  const [inSubmit, setInSubmit] = useState<boolean>(false)
  const {
    SaveData,
    loading,
    message,
    onOption,
    openAlert
  } = useRegisterPatient(patient)
  const [stepper, setStepper] = useState<number>(0)
  const onNext = (): void => setStepper(STEPS.SELECT_ADDRESS)
  const onBack = (): void => setStepper(STEPS.PATIENT_FORM)
  const onChangeSubmit = () => setInSubmit(true)
  useEffect(() => {
    if (inSubmit) {
      SaveData()
      setInSubmit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inSubmit])

  return <>{loading ? (
    <>
      <Loading />
      <Advert
        message={message.message}
        onClose={() => { onOption() }}
        open={openAlert}
        title={message.title}
      />
    </>

  ) : (
    <GridLayout>
      {
        stepper === STEPS.PATIENT_FORM &&
        <PatientForm
          onNext={onNext}
          handlePatientInfo={setPatientInfo}
          patientInfo={patient}
        />
      }
      {
        stepper === STEPS.SELECT_ADDRESS &&
        <RegisterAddressPatient
          onBack={onBack}
          handlePatientAddresses={setPatientAddresses}
          patientInfo={patient}
          onChangeSubmit={onChangeSubmit}
        />
      }
    </GridLayout>
  )}
  </>
}