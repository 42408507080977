import { IDoctorProps } from "../../components/Carousel";
import { BadgeRatedDoctorStyled, BadgeDoctorFriendsStyled } from "./styles";

interface IBadgeDoctorProps {
  doctor?: IDoctorProps,
  children: React.ReactNode,
  anchorOrigin: {
    vertical: 'bottom' | 'top',
    horizontal: "left" | "right"
  },
  badgeContent?: React.ReactNode
}

export function BadgeRatedDoctor ({doctor, children, anchorOrigin}: IBadgeDoctorProps): JSX.Element {
  return <BadgeRatedDoctorStyled
    overlap="circular"
    anchorOrigin={anchorOrigin}
    color="secondary"
    badgeContent={doctor?.rating}
  >
    {children}
  </BadgeRatedDoctorStyled>
}

export function BadgeDoctorFriends ({children, anchorOrigin, badgeContent}: IBadgeDoctorProps): JSX.Element {
  return <BadgeDoctorFriendsStyled
    overlap="circular"
    anchorOrigin={anchorOrigin}
    color="default"
    badgeContent={badgeContent}
  >
    {children}
  </BadgeDoctorFriendsStyled>
}