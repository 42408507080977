import {
  FavoriteBorderOutlined,
  HomeOutlined,
  NotificationsOutlined,
} from "@mui/icons-material";
import { CalendarDate } from "../../../../components/StyledComponents/CalendarDate";

interface ItemsNavBar {
  id: number;
  name: string;
  path: string;
  icon: JSX.Element;
}

export const links: ItemsNavBar[] = [
  {
    id: 1,
    name: "Inicio",
    path: "/",
    icon: <HomeOutlined fontSize="large" style={{ color: "white" }} />,
  },
  {
    id: 2,
    name: "Citas",
    path: "/dates",
    icon: (
      <CalendarDate
        fontSize="large"
        style={{ color: "white", marginRight: 0 }}
      />
    ),
  },
  {
    id: 3,
    name: "Mi Salud",
    path: "#",
    icon: (
      <FavoriteBorderOutlined fontSize="large" style={{ color: "white" }} />
    ),
  },
  {
    id: 4,
    name: "Notificaciones",
    path: "#",
    icon: <NotificationsOutlined fontSize="large" style={{ color: "white" }} />,
  },
];
