import { styled } from '@mui/material'
import TextFieldWrapper from '../wrapper/TextFieldWrapper'

export const InputField = styled(TextFieldWrapper)(() => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    borderRadius: '3.4375rem',
    backgroundColor: '#fff',
    fontSize: '1.125rem',
    color: '#7f7f7f',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '3.4375rem'
  },
  '& fieldset': { borderColor: 'white' },
  '&::placeholder': {
    marginLeft: '1.875rem'
  }
}))
