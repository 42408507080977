import {
  MyLocationOutlined,
  NearMeOutlined,
} from '@mui/icons-material'
import { useContext } from 'react'
import Divider from '@mui/material/Divider'
import { SelectChangeEvent } from '@mui/material'
import { LinkWrapper } from '../../../../components'
import { SelectInput } from '../../../../components/StyledComponents/SelectInput'
import { CustomMenuItem } from './styles'
import { AccountContext, AuthContextType } from '../../../../contexts/AccountContext'
import { IAddressPatient } from '../../../Register/types'
import { AddressIconsList } from './AddressIconsList'

interface Props<T> {
  address: T
  onChange: (event: SelectChangeEvent<unknown>) => void
  setAddressData: (data: IAddressPatient | undefined) => void
}

function Address<T>({ address, onChange, setAddressData }: Props<T>): JSX.Element {
  const { patient } = useContext(AccountContext) as AuthContextType
  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    if (typeof event.target.value === 'string' && Boolean(event.target.value)) {
      const addressData: IAddressPatient | undefined = patient.addresses.find((ad) => ad.address === event.target.value)
      setAddressData(addressData)
    } else
      setAddressData(undefined)
    onChange(event)
  }
  return (
    <>
      <SelectInput value={address} onChange={handleChange} role="combobox">
        <CustomMenuItem value="">
          <NearMeOutlined />
          Usar mi ubicación actual
        </CustomMenuItem>
        <Divider />
        <CustomMenuItem value={2}>
          <MyLocationOutlined />
          Seleccionar en el mapa
        </CustomMenuItem>
        <Divider />
        {patient?.addresses && patient?.addresses.map((address, index) => {
          const addressArray: string[] = address.address.split(',')
          const formattedAddress: string = addressArray.slice(0, addressArray.length - 2).join()
          return <CustomMenuItem value={address.address} key={index}>
            <AddressIconsList addressName={address.name}/>
            {address.name + ", " + formattedAddress}
          </CustomMenuItem>
        })}
        <Divider />
        <CustomMenuItem value={4}>
          <LinkWrapper href="#" underline="hover">
            {'Agregar nueva direccion'}
          </LinkWrapper>
        </CustomMenuItem>
      </SelectInput>
    </>
  )
}

export default Address
