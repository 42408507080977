import { IApiPendingTasks, IPendingTasks } from '../../infrastructure/dtos'
import PendingTasksRepository from '../repository/PendingTasksRepository'

export interface GetPendingTasksUseCase {
  invoke(user_id: string): Promise<IApiPendingTasks<IPendingTasks | string>>
}

export class GetPendingTasks implements GetPendingTasksUseCase {
  private objectUseCase: PendingTasksRepository
  constructor(_objectUseCase: PendingTasksRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(user_id: string) {
    return this.objectUseCase.getPendingTasks(user_id)
  }
}