import SaveQualification from '../../../domain/useCase/SaveQualificationUseCase'
import SaveQualificationRepositoryImpl from '../../../infrastructure/RepositoryImpl/SaveQualificationRepositoryImpl'
import { QualificationDoctor } from '../../../infrastructure/dtos/SaveQualifications'
import SaveQualifiactionMapper from '../../../infrastructure/http/mappers/SaveQualificationMapper'
import { IServiceYearsExperience } from './InterfaceServiceResponse'

export async function SaveQualificationService(
  dataQuestions: QualificationDoctor
): Promise<IServiceYearsExperience> {
  try {
    const UseCaseApi: SaveQualification = new SaveQualification(
      new SaveQualificationRepositoryImpl(new SaveQualifiactionMapper())
    )
    return {
      status: 1,
      data: await UseCaseApi.invoke(dataQuestions)
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error
    }
  }
}
