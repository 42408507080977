import { CustomAvatar } from './styles'
import { Badge } from '@mui/material'

interface DoctorInfoProps {
  photo: string
  rating: string
}

export default function PhotoDoctor({
  photo,
  rating
}: DoctorInfoProps): JSX.Element {
  return (
    <>
      <CustomAvatar src={photo}></CustomAvatar>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        badgeContent={rating}
        color="secondary"
        sx={{
          '& .MuiBadge-badge': {
            right: -110,
            top: -110,
            borderRadius: '50%',
            width: 30,
            height: 30,
            backgroundColor: '#F79D25',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute'
          }
        }}
      />
    </>
  )
}
