import { ExpandMore } from '@mui/icons-material'
import { Menu } from '@mui/material'
import React, { useContext, useState } from 'react'
import { IconButtonWrapper, MenuItemWrapper } from '../../../../components'
import {
  AccountContext,
  AuthContextType
} from '../../../../contexts/AccountContext'

function SignOutButton(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { logout } = useContext(AccountContext) as AuthContextType
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleSignout = (): void => {
    logout()
  }

  return (
    <div>
      <IconButtonWrapper
        onClick={handleClick}
        size="large"
        edge="end"
        color="inherit"
      >
        <ExpandMore />
      </IconButtonWrapper>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItemWrapper onClick={handleSignout}>Cerrar sesión</MenuItemWrapper>
      </Menu>
    </div>
  )
}

export default SignOutButton
