import { IApiResponseOffices } from '../../dtos/Offices'
import { http } from '../Http'
import OfficesDataSource from '../dataSource/OfficesDataSource'

export default class OfficesMapper implements OfficesDataSource {
  async getOffices(idDoctor: string): Promise<IApiResponseOffices> {
    let data = await http
      .get<IApiResponseOffices>(`send_data/offices/${idDoctor}`)
      .then((response) => {
        const { data } = response
        return data
      })
      .catch((error) => {
        const data = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': ''
          }
        }
        return data
      })
    return data
  }
}
