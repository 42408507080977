import { Outlet } from "react-router-dom";
import { Logo } from "../../components/Logo/Logo";
import {
  AppBarWrapper,
  BoxWrapper,
  CssBaselineWrapper,
  GridWrapper,
  StackWrapper,
  ToolbarWrapper,
} from "../../components/wrapper";
import DateButton from "./components/DateButton";
import SignOutButton from "./components/SignOutButton";
import { ProfilePicture } from "./components/ProfilePicture";
import NavBar from "./components/NavBar";
import { ReactNode, useContext } from "react";
import { AccountContext, AuthContextType } from "../../contexts/AccountContext";

interface NavBarProps {
  children?: ReactNode;
}

const boxHeigth: number = 113;

function AppLayout(): JSX.Element {
  const { patient } = useContext(AccountContext) as AuthContextType;
  return (
    <BoxWrapper>
      <CssBaselineWrapper />
      <AppBarWrapper position="fixed">
        <ToolbarWrapper>
          <Logo />
          <StackWrapper spacing={2} direction="row" sx={{ marginLeft: "auto" }}>
            <NavBar />
          </StackWrapper>
          <StackWrapper spacing={2} direction="row" sx={{ marginLeft: "auto" }}>
            <DateButton />
            <ProfilePicture name={`${patient.name} ${patient.lastName}`} />
            <SignOutButton />
          </StackWrapper>
        </ToolbarWrapper>
      </AppBarWrapper>

      <GridWrapper sx={{ mt: `${boxHeigth}px` }}>
        <Outlet />
      </GridWrapper>
    </BoxWrapper>
  );
}

export default AppLayout;
