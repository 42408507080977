import { FormControl, FormControlLabel, FormGroup, Switch } from '@mui/material'
import { GridWrapper, StackWrapper } from '../../../../components'
import FormTextInput from '../../../../components/FormInputText'
import SubmitButton from '../../../../components/SubmitButton'
import {
  GridContent,
  GridLayoutLeft,
  GridLayoutRight
} from '../SelectSchedule/SelectScheduleStyle'
import { ButtonR, GridRow } from './FillDataStyles'
import { FormEvent, useContext, useEffect, useState } from 'react'
import {
  IAppointmentInfo,
  PatientAppointmentContext
} from '../../../../contexts/PatientAppointmentContext'
import DoctorInfo from '../../components/DoctorInfo'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import { PostNewDateUtility } from '../../../../services/Contracts/Utility/NewDateUtility'
import dayjs from 'dayjs'
import { GridHalfScreen } from '../../../../components/StyledComponents/GridHalfScreen'
import { BackButton } from '../../../../components/BackButton/BackButton'
import { useParams } from 'react-router-dom'
import { Advert } from '../../../../components/Notification/Advert/Advert'
import { validateEmail } from '../../../../utils/validateEmail'
import { validatePhoneNumber } from '../../../../utils/validatePhoneNumber'
import { useDataDoctor } from '../../Hooks/useDataDoctor'
import DataDoctor from '../../components/DataDoctor'
import LeadingDoctors from '../../components/LeadingDoctors'

interface FillDataProps {
  onStepper: () => void
}

export const FillData: React.FC<FillDataProps> = ({ onStepper }) => {
  const { idDoctor } = useParams<string>()
  const { dataDoctor } = useDataDoctor(idDoctor as string)
  const { PatientAppointment, saveAppointmentFormInfo } = useContext(
    PatientAppointmentContext
  )
  const [appointmentInfo, setAppointmentInfo] = useState<IAppointmentInfo>({
    patient_name: PatientAppointment?.patient_name,
    patient_last_name: PatientAppointment?.patient_last_name,
    patient_email: PatientAppointment?.patient_email,
    patient_phone_number: PatientAppointment?.patient_phone_number,
    appointment_reason: PatientAppointment?.appointment_reason,
    add_to_calendar: PatientAppointment?.add_to_calendar,
    patient_id_number: PatientAppointment?.patient_id_number
  })
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isSubmittedOk, setIsSubmittedOk] = useState<boolean>(false)
  const [isInputsDisabled, setIsInputsDisabled] = useState<boolean>(false)
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false
  ])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [messageModal, setMessageModal] = useState<string>('')

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]
    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  const setFormData = () => {
    saveAppointmentFormInfo({
      ...PatientAppointment,
      patient_name: appointmentInfo.patient_name,
      patient_last_name: appointmentInfo.patient_last_name,
      patient_email: appointmentInfo.patient_email,
      patient_phone_number: appointmentInfo.patient_phone_number,
      appointment_reason: appointmentInfo.appointment_reason,
      add_to_calendar: appointmentInfo.add_to_calendar,
      patient_id_number: appointmentInfo.patient_id_number
    })
  }

  const handleGoBack = () => {
    setFormData()
    onStepper()
  }

  useEffect(() => {
    if (isSubmitted) {
      PostNewDateUtility({
        appointment_date: PatientAppointment.date_appointment,
        comment: PatientAppointment.appointment_reason,
        email: PatientAppointment.patient_email,
        hour_from: PatientAppointment.hour_appointment,
        hour_to: dayjs(
          `${PatientAppointment.date_appointment}T${PatientAppointment.hour_appointment}`
        )
          .add(PatientAppointment.consultation_time_minutes, 'minutes')
          .format('HH:mm'),
        patient_id_number: PatientAppointment.patient_id_number,
        office_id: PatientAppointment?.office_id,
        patient_last_name: PatientAppointment.patient_last_name,
        patient_name: PatientAppointment.patient_name,
        phone: PatientAppointment.patient_phone_number,
        user_id: idDoctor as string
      })
        .then((result) => {
          if (result.status === 1) {
            setIsInputsDisabled(true)
            setIsSubmittedOk(true)
          } else {
            setIsSubmitted(false)
          }
          setMessageModal(result.data)
          setOpenModal(true)
        })
        .catch((error) => {
          console.error('Error guardando nuevo evento:', error)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PatientAppointment, isSubmitted])

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    setFormData()
    setIsSubmitted(true)
  }
  const openLoginHelthy = (): void => {
    const url = process.env.REACT_APP_URL
    window.open(url, '_blank')
  }

  const submitValidation = (): boolean => {
    return (
      appointmentInfo.patient_name?.length === 0 ||
      appointmentInfo.patient_last_name?.length === 0 ||
      appointmentInfo.patient_email?.length === 0 ||
      !validateEmail(appointmentInfo.patient_email) ||
      appointmentInfo.patient_phone_number?.length === 0 ||
      !validatePhoneNumber(appointmentInfo.patient_phone_number) ||
      appointmentInfo.appointment_reason?.length === 0 ||
      appointmentInfo.patient_id_number?.length === 0 ||
      isSubmitted
    )
  }

  const isDisabled: boolean = submitValidation()

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputName: string
  ) => {
    if (inputName === 'reason') {
      setAppointmentInfo({
        ...appointmentInfo,
        appointment_reason: event.target.value
      })
    }

    if (inputName === 'patient_id_number') {
      setAppointmentInfo({
        ...appointmentInfo,
        patient_id_number: event.target.value
      })
    }

    if (inputName === 'patient_phone_number') {
      setAppointmentInfo({
        ...appointmentInfo,
        patient_phone_number: event.target.value
      })
    }

    if (inputName === 'patient_email') {
      setAppointmentInfo({
        ...appointmentInfo,
        patient_email: event.target.value
      })
    }

    if (inputName === 'patient_last_name') {
      setAppointmentInfo({
        ...appointmentInfo,
        patient_last_name: event.target.value
      })
    }

    if (inputName === 'patient_name') {
      setAppointmentInfo({
        ...appointmentInfo,
        patient_name: event.target.value
      })
    }
  }

  return (
    <>
      <GridContent sx={{ height: '115%' }}>
        <GridLayoutLeft
          sx={{
            width: isSubmittedOk ? '65%' : '100%',
            paddingTop: '10px',
            justifyContent: 'flex-start',
            gridTemplateColumns: '1fr'
          }}
        >
          <GridHalfScreen item sm={2}>
            {!isInputsDisabled && (
              <BackButton
                id="backButton"
                text="Regresar"
                onClick={handleGoBack}
              />
            )}
          </GridHalfScreen>
          <GridWrapper>
            <GridRow item xs={12} marginBottom={5} marginTop={2}>
              <DoctorInfo
                message="Confirma tu cita con el Dr. "
                info={dataDoctor}
                patientAppointment={PatientAppointment}
              />
            </GridRow>
          </GridWrapper>
          <GridWrapper
            item
            xs={12}
            marginBottom={2}
            width="80%"
            justifySelf="center"
          >
            <LabelInput textAlign="left" sx={{ fontSize: '20px' }}>
              Ingresa tus datos
            </LabelInput>
          </GridWrapper>
          <GridWrapper
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            flexDirection="column"
            marginTop={0}
          >
            <form
              onSubmit={onSubmit}
              data-testid="patient-appointment-form"
              style={{ width: '100%' }}
            >
              <GridRow item xs={12}>
                <StackWrapper
                  spacing={2}
                  direction="row"
                  sx={{ marginBottom: 4, marginRight: 0, width: '80%' }}
                >
                  <GridWrapper item xs={12} sm={6}>
                    <FormTextInput
                      text="¿Cuál es tu nombre?"
                      placeholder="Joe"
                      id="name"
                      type="text"
                      fullWidth={true}
                      value={appointmentInfo.patient_name}
                      name="name"
                      disabled={isInputsDisabled}
                      onBlur={() => onValidationFocus(0)}
                      onChange={(event) =>
                        handleInputChange(event, 'patient_name')
                      }
                      error={
                        appointmentInfo.patient_name?.length === 0 &&
                        ValidationFocus[0]
                      }
                      textError={
                        appointmentInfo.patient_name?.length === 0 &&
                        ValidationFocus[0]
                          ? 'Campo requerido.'
                          : ''
                      }
                    />
                  </GridWrapper>
                  <GridWrapper item xs={12} sm={6}>
                    <FormTextInput
                      text="¿Cuál es tu apellido?"
                      placeholder="Doe"
                      id="lastName"
                      name="lastName"
                      type="text"
                      fullWidth
                      value={appointmentInfo.patient_last_name}
                      disabled={isInputsDisabled}
                      onBlur={() => onValidationFocus(1)}
                      onChange={(event) =>
                        handleInputChange(event, 'patient_last_name')
                      }
                      error={
                        appointmentInfo.patient_last_name?.length === 0 &&
                        ValidationFocus[1]
                      }
                      textError={
                        appointmentInfo.patient_last_name?.length === 0 &&
                        ValidationFocus[1]
                          ? 'Campo requerido.'
                          : ''
                      }
                    />
                  </GridWrapper>
                </StackWrapper>
              </GridRow>

              <GridRow item xs={12}>
                <StackWrapper
                  spacing={2}
                  direction="row"
                  sx={{ marginBottom: 4, width: '80%' }}
                >
                  <GridWrapper item xs={12} sm={6}>
                    <FormTextInput
                      text="¿Cuál es tu correo electrónico?"
                      placeholder="dr@email.com"
                      id="email"
                      type="email"
                      fullWidth={true}
                      value={appointmentInfo.patient_email}
                      name="email"
                      disabled={isInputsDisabled}
                      onBlur={() => onValidationFocus(2)}
                      onChange={(event) =>
                        handleInputChange(event, 'patient_email')
                      }
                      error={
                        (appointmentInfo.patient_email?.length === 0 &&
                          ValidationFocus[2]) ||
                        (!validateEmail(appointmentInfo.patient_email) &&
                          ValidationFocus[2])
                      }
                      textError={
                        appointmentInfo.patient_email?.length === 0 &&
                        ValidationFocus[2]
                          ? 'Campo requerido.'
                          : !validateEmail(appointmentInfo.patient_email) &&
                            ValidationFocus[2]
                          ? 'Por favor, ingresa una dirección de correo válida'
                          : ''
                      }
                    />
                  </GridWrapper>
                  <GridWrapper item xs={12} sm={6}>
                    <FormTextInput
                      text="¿Cuál es tu número de celular?"
                      placeholder="0999911111"
                      id="phone_number"
                      name="phone_number"
                      type="text"
                      fullWidth
                      value={appointmentInfo.patient_phone_number}
                      disabled={isInputsDisabled}
                      onBlur={() => onValidationFocus(3)}
                      onChange={(event) =>
                        handleInputChange(event, 'patient_phone_number')
                      }
                      error={
                        (appointmentInfo.patient_phone_number?.length === 0 &&
                          ValidationFocus[3]) ||
                        (!validatePhoneNumber(
                          appointmentInfo.patient_phone_number
                        ) &&
                          ValidationFocus[3])
                      }
                      textError={
                        appointmentInfo.patient_phone_number?.length === 0 &&
                        ValidationFocus[3]
                          ? 'Campo requerido.'
                          : !validatePhoneNumber(
                              appointmentInfo.patient_phone_number
                            ) && ValidationFocus[3]
                          ? 'Por favor, ingresa un teléfono celular válido'
                          : ''
                      }
                    />
                  </GridWrapper>
                </StackWrapper>
              </GridRow>

              <GridRow item xs={12}>
                <StackWrapper
                  spacing={2}
                  direction="row"
                  sx={{ marginBottom: 4, width: '80%' }}
                >
                  <GridWrapper item xs={12} sm={6}>
                    <FormTextInput
                      text="Coméntale a tu doctor el motivo de la cita"
                      placeholder=""
                      id="reason"
                      type="text"
                      fullWidth={true}
                      value={appointmentInfo.appointment_reason}
                      name="reason"
                      disabled={isInputsDisabled}
                      onBlur={() => onValidationFocus(4)}
                      onChange={(event) => handleInputChange(event, 'reason')}
                      error={
                        appointmentInfo.appointment_reason?.length === 0 &&
                        ValidationFocus[4]
                      }
                      textError={
                        appointmentInfo.appointment_reason?.length === 0 &&
                        ValidationFocus[4]
                          ? 'Campo requerido.'
                          : ''
                      }
                    />
                  </GridWrapper>
                  <GridWrapper item xs={12} sm={6}>
                    <FormTextInput
                      text="¿Cuál es tu número de cédula?"
                      placeholder=""
                      id="patient_id_number"
                      type="text"
                      fullWidth={true}
                      value={appointmentInfo.patient_id_number}
                      name="reason"
                      disabled={isInputsDisabled}
                      onBlur={() => onValidationFocus(5)}
                      onChange={(event) =>
                        handleInputChange(event, 'patient_id_number')
                      }
                      error={
                        appointmentInfo.patient_id_number?.length === 0 &&
                        ValidationFocus[4]
                      }
                      textError={
                        appointmentInfo.patient_id_number?.length === 0 &&
                        ValidationFocus[4]
                          ? 'Campo requerido.'
                          : ''
                      }
                    />
                  </GridWrapper>
                </StackWrapper>
              </GridRow>

              <GridRow item xs={12}>
                <StackWrapper
                  spacing={2}
                  direction="row"
                  sx={{ marginBottom: 4, width: '80%' }}
                >
                  <GridWrapper
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                  >
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          label="Agregar cita a mi calendario"
                          labelPlacement="start"
                          value="start"
                          control={
                            <Switch
                              checked={appointmentInfo.add_to_calendar}
                              onChange={(event) =>
                                setAppointmentInfo({
                                  ...appointmentInfo,
                                  add_to_calendar: event.target.checked
                                })
                              }
                              color="primary"
                              disabled={isInputsDisabled}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </GridWrapper>
                </StackWrapper>
              </GridRow>

              <GridRow item xs={12} width="80%" marginTop={10}>
                <StackWrapper
                  spacing={2}
                  direction="row"
                  sx={{ marginBottom: 4, width: '80%' }}
                >
                  <SubmitButton
                    id="confirm-appointment-button"
                    dataTestid="confirm-appointment-button"
                    variant="contained"
                    fullWidth={true}
                    type="submit"
                    text="Confirmar cita"
                    disabled={isDisabled}
                  />
                </StackWrapper>
              </GridRow>
            </form>
          </GridWrapper>
        </GridLayoutLeft>
        <GridLayoutRight
          sx={{ width: isSubmittedOk ? '35%' : '0%', backgroundColor: '#FFF' }}
          container={true}
        >
          {isSubmittedOk && (
            <>
              <GridWrapper sx={{ height: '40%' }} item sm={12}>
                <DataDoctor info={dataDoctor} />
              </GridWrapper>
              <GridWrapper sx={{ height: '40%' }} item sm={12}>
                <LeadingDoctors doctors={['', '']} />
              </GridWrapper>
              <GridWrapper sx={{ height: '20%' }} item sm={12}>
                <GridWrapper
                  container={true}
                  justifyContent="center"
                  alignItems="center"
                >
                  <GridWrapper item>
                    <LabelInput textAlign="center">
                      Ingresa a Helthy y realiza el seguimiento de tu cita
                    </LabelInput>
                  </GridWrapper>
                  <GridWrapper item>
                    <ButtonR
                      type="button"
                      onClick={() => {
                        openLoginHelthy()
                      }}
                      data-testid="modal-button"
                    >
                      Ingresar
                    </ButtonR>
                  </GridWrapper>
                </GridWrapper>
              </GridWrapper>
            </>
          )}
        </GridLayoutRight>
      </GridContent>
      <Advert
        message={messageModal}
        onClose={() => setOpenModal(false)}
        open={openModal}
        title="Aviso"
      />
    </>
  )
}
