import PhoneVerificationRepository from '../../domain/repository/PhoneVerificationRepository'
import {
  IApiResponsePhoneVerification,
  IPhoneVerification,
  IResendCodeParams,
  IUpdatePatientPhoneParams,
  IVerifyCodeParams
} from '../dtos/PhoneVerification'
import PhoneVerificationDataSource from '../http/dataSource/PhoneVerificationDataSource'

export class PhoneVerificationRepositoryImpl
  implements PhoneVerificationRepository
{
  dataSource: PhoneVerificationDataSource

  constructor(_datasource: PhoneVerificationDataSource) {
    this.dataSource = _datasource
  }

  resendCode(
    params: IResendCodeParams
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    return this.dataSource.resendCode(params)
  }

  sendCode(
    userId: string
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    return this.dataSource.sendCode(userId)
  }

  verifyPhoneValidation(
    userId: string
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    return this.dataSource.verifyPhoneValidation(userId)
  }

  verifyCode(
    params: IVerifyCodeParams
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    return this.dataSource.verifyCode(params)
  }
  updatePatientPhone(
    params: IUpdatePatientPhoneParams
  ): Promise<IApiResponsePhoneVerification<string>> {
    return this.dataSource.updatePatientPhone(params)
  }
}
