import { IApiResponsePatientInfo } from "../../infrastructure/dtos/Patient";
import { PatientInfoRepository } from "../repository/PatientRepository";

export interface GetPatientInfoUseCase {
  invoke: (idPatient: string) => Promise<IApiResponsePatientInfo>;
}

export class GetPatientInfo implements GetPatientInfoUseCase {
  private objectUseCase: PatientInfoRepository;
  constructor(_objectUseCase: PatientInfoRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(idPatient: string) {
    return this.objectUseCase.getPatientInfo(idPatient);
  }
}
