import { AxiosError } from "axios";
import { IApiResponseCalendar, ICalendarEvent, IEvent, INextAppointmentHour, IParamsGetCalendar, IParamsGetNextAppointmentHour } from "../../dtos/Calendar";
import { http } from "../Http";
import CalendarDataSource from "../dataSource/CalendarDataSource";

export default class CalendarMapper implements CalendarDataSource {
  async postAddCalendarEvent(event: ICalendarEvent): Promise<IApiResponseCalendar<IEvent>> {
    try {
      let { data }: { data: IApiResponseCalendar<IEvent> } = await http.post<IApiResponseCalendar<IEvent>>(`/save_data/save_non_attendance_hours`, {
        body: event,
      })
      return data
    } catch(error: any) {
      const data = {
        statusCode: error.response?.status,
        body: error.response?.data,
        headers: {
          "Access-Control-Allow-Headers": "",
          "Access-Control-Allow-Origin": "",
          "Access-Control-Allow-Methods": "",
        },
      }
      return data
    }
  }

  async getEventsByMonth({ date_search, office_id, user_id }: IParamsGetCalendar): Promise<IApiResponseCalendar<IEvent>> {
    try {
      let { data } = await http.get<IApiResponseCalendar<IEvent>>(`/send_data/events_medical/${date_search}/${office_id}/${user_id}`)
      return data
    } catch(error: any) {
      const data = {
        statusCode: error.response?.status,
        body: error.response?.data,
        headers: {
          "Access-Control-Allow-Headers": "",
          "Access-Control-Allow-Origin": "",
          "Access-Control-Allow-Methods": "",
        },
      }
      return data
    }
  }

  async getAvailableHoursInADay({ date_search, office_id, user_id }: IParamsGetCalendar): Promise<IApiResponseCalendar<IEvent>> {
    try {
      const getEcuadorTime = (): string => {
        const options: Intl.DateTimeFormatOptions = {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
          timeZone: "America/Guayaquil",
        };
        return new Date().toLocaleTimeString("en-US", options);
      };
      const ecuadorTime = getEcuadorTime();
      let { data } = await http.get<IApiResponseCalendar<IEvent>>(`/send_data/schedule_availability/${date_search}/${office_id}/${user_id}/${ecuadorTime}`)
      return data
    } catch(error: any) {
      const data = {
        statusCode: error.response?.status,
        body: error.response?.data,
        headers: {
          "Access-Control-Allow-Headers": "",
          "Access-Control-Allow-Origin": "",
          "Access-Control-Allow-Methods": "",
        },
      }
      return data
    }
  }

  async getNextAppointmentHour({ office_id, user_id, isDistance = 0, coordinates }: IParamsGetNextAppointmentHour): Promise<IApiResponseCalendar<INextAppointmentHour>> {
    try {
      let queryParams = ''
      if (isDistance) queryParams = `&log=${coordinates?.log}&lat=${coordinates?.lat}`
      let { data } = await http.get<IApiResponseCalendar<INextAppointmentHour>>(
        `/send_data/medical_next_appointment/${isDistance}/1/${user_id}?office_id=${office_id}${queryParams}`
      )
      return data
    } catch(error: unknown) {
      const data = {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: (error as AxiosError)?.response?.statusText as string,
        headers: {
          "Access-Control-Allow-Headers": "",
          "Access-Control-Allow-Origin": "",
          "Access-Control-Allow-Methods": "",
        },
      }
      return data
    }
  }
}
