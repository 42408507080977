// Componente numero 1

import { LabelInput } from '../StyledComponents/LabelInput'
import { InputField } from '../StyledComponents/InputField'
import { LabelInputError } from '../StyledComponents/LabelInputError'

export interface InputText {
  id: string
  type: string
  placeholder: string
  fullWidth: boolean
  name: string
  value: string
  text?: string
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  validate?: boolean
  textError?: string
  error?: boolean
  onBlur?: () => void
  helperText?: string
  disabled?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  inputProps?: Record<any, any>
}

const FormTextInput = ({
  id,
  type,
  placeholder,
  fullWidth,
  name,
  value,
  text,
  onChange,
  validate,
  textError,
  error,
  onBlur,
  helperText,
  disabled = false,
  inputRef,
  inputProps
}: InputText): JSX.Element => {
  return (
    <>
      <LabelInput>{text}</LabelInput>

      <InputField
        id={id}
        type={type}
        placeholder={placeholder}
        fullWidth={fullWidth}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        onBlur={onBlur}
        helperText={helperText}
        disabled={disabled}
        inputRef={inputRef}
        inputProps={inputProps}
      />

      {error && <LabelInputError>{textError}</LabelInputError>}
    </>
  )
}

export default FormTextInput
