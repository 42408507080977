import { styled } from '@mui/material'
import {
  BoxWrapper,
  GridWrapper,
  TypographyWrapper
} from '../../../../../../../components'

export const BoxModal = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '850px',
  minHeight: '650px',
  bgcolor: theme.palette.background.paper,
  background: '#fff',
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)',
  padding: '40px 60px'
}))

export const DisableTextInput = styled(GridWrapper)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1fr)',
  gridGap: '10px',
  backgroundColor: '#FAF9FD',
  borderRadius: '32px',
  padding: '20px',
  width: '100%'
}))

export const TypographyRecipeNumber = styled(TypographyWrapper)(() => ({
  fontSize: '20px',
  fontWeight: 700,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left'
}))

export const TypographyName = styled(TypographyWrapper)(() => ({
  fontWeight: 700,
  fontSize: '35px',
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
  lineHeight: '1'
}))

export const TypographyDoctorInfo = styled(TypographyWrapper)(() => ({
  fontSize: '15px',
  fontWeight: 400,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
  lineHeight: '1.2'
}))

export const TypographyLabel = styled(TypographyWrapper)(() => ({
  fontSize: '15px',
  fontWeight: 400,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left'
}))

export const DisabledInput = styled(GridWrapper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '32px'
}))
