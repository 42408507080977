import * as React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import dayjs from 'dayjs'
import { ICalendarData } from '../../../../infrastructure/dtos/CalendarInfo'

interface ICalendarProps {
  onChangeDate: (value: dayjs.Dayjs | null) => void
  selectedDate: dayjs.Dayjs | null
  daysAvailability: ICalendarData
}

export default function CalendarInfo({
  onChangeDate,
  selectedDate,
  daysAvailability
}: ICalendarProps): JSX.Element {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <DateCalendar
        shouldDisableDate={(day: dayjs.Dayjs): boolean =>
          !daysAvailability[dayjs(day).format('YYYY-MM-DD')]
        }
        value={selectedDate}
        disablePast={true}
        onChange={onChangeDate}
        sx={{
          width: '30rem', // Ajusta el ancho del calendario según tu preferencia
          alignItems: 'center',
          margin: '0',
          //slide
          '& .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition':
            {
              width: '25rem', // Ajusta el ancho del calendario según tu preferencia
              height: '30rem' // Ajusta la altura del calendario según tu preferencia
            }, //fechas
          '& .css-i81r0b-MuiButtonBase-root-MuiPickersDay-root': {
            color: '#3850B2', // Cambia el color de los días seleccionados a azul
            fontSize: '1.2rem',
            marginTop: '0.35rem',
            marginLeft: '1.5rem',
            opacity: 1,
            fontFamily: 'Helvetica'
          },
          '& .css-bt3pz1-MuiTypography-root-MuiDayCalendar-weekDayLabel': {
            color: '#B2B2B2',
            fontSize: '1.2rem',
            marginTop: '0.35rem',
            marginLeft: '1.3rem',
            opacity: 0.497931,
            fontFamily: 'Lato-Bold',
            margin: '0 10px'
          },
          //contenedor
          '& .css-15v8kdh-MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer':
            {
              // marginBottom: '16px', // Ajusta el margen inferior del mes según tu preferencia
              width: '30rem' // Ajusta el ancho del calendario según tu preferencia
              // height: '40rem', // Ajusta la altura del calendario según tu preferencia
            },
          '& .css-b6k28n-MuiPickersDay-root': {
            width: '1.5rem',
            height: '1.5rem',
            marginTop: '1.25rem',
            marginLeft: '2.2rem'
          },
          '& .css-i5q14k-MuiDayCalendar-header': {
            width: '25.5rem'
          },
          //fecha actual
          '& .css-jjo3mx-MuiButtonBase-root-MuiPickersDay-root ': {
            fontSize: '1.2rem',
            border: 'none',
            color: '#3850B2',
            marginTop: '0.35rem',
            opacity: 1,
            fontFamily: 'Helvetica',
            marginLeft: '1.5rem',
            '&:before': {
              content: '""',
              position: 'absolute',
              width: '7px', // Ancho del punto
              height: '7px', // Alto del punto
              borderRadius: '50%', // Hace que el punto tenga forma redondeada
              background: 'blue', // Color del punto
              bottom: '-4px', // Posiciona el punto debajo del día actual
              left: 'calc(50% - 2px)', // Centra el punto horizontalmente
              backgroundColor: '#31CACD'
            }
          },
          //fecha actual seleccionada
          '& .css-jjo3mx-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#31CACD',
            color: '#FFFFFF'
          },
          '& .css-jjo3mx-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)':
            {
              border: 'none'
            },
          //fecha seleccionada circulo
          '& .css-i81r0b-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#31CACD',
            color: '#FFFFFF'
          },
          //fecha superior jun 2023
          '& .css-dplwbx-MuiPickersCalendarHeader-label': {
            opacity: 1,
            color: 'rgba(0, 0, 0, 1)',
            fontFamily: 'Helvetica',
            fontSize: '1.2rem',
            fontWeight: 400,
            fontStyle: 'normal',
            letterSpacing: '0px',
            textAlign: 'center'
          }
        }}
      />
    </LocalizationProvider>
  )
}
