import { GetOffices } from '../../../domain/useCase/GetOffices'
import { OfficesRepositoryImpl } from '../../../infrastructure/RepositoryImpl/OfficesRepositoryImpl'
import OfficesMapper from '../../../infrastructure/http/mappers/OfficesMapper'
import { IServiceYearsExperience } from './InterfaceServiceResponse'

export async function OfficesService(idDoctor: string): Promise<IServiceYearsExperience> {
  try {
    const UseCaseRegistro = new GetOffices(
      new OfficesRepositoryImpl(new OfficesMapper())
    )
    return {
      status: 1,
      data: await UseCaseRegistro.invoke(idDoctor)
    }
  } catch (error) {
    return {
      status: 0,
      data: error
    }
  }
}
