import { Backdrop } from '@mui/material'
import { Close, VerticalAlignBottom } from '@mui/icons-material'
import { ButtonWrapper, GridWrapper, ModalWrapper, TypographyWrapper } from '../../../../../../../components'
import SubmitButton from '../../../../../../../components/SubmitButton'

import { GridItem } from '../../../../../../../components/GridItem/GridItem'
import {
  GridModal,
  BoxPatientRecipeModal,
  CloseButton,
  FadePatientRecipeModal,
  TypographyModal,
  TypographyTitleModal
} from './ShowRecipesListStyle'
import { IPrescription, IRecipePrescription } from '../../../../../../../infrastructure/dtos/Prescription'

interface PatientRecipesModalProps {
  handleOpenRecipesListModal: (value: boolean) => void
  handleSetPrescriptionIndex: (index: number) => void
  open: boolean
  appointmentDetails?: IRecipePrescription,
}

export function ShowRecipesListModal({
  handleOpenRecipesListModal,
  handleSetPrescriptionIndex,
  open,
  appointmentDetails,
}: PatientRecipesModalProps): JSX.Element {

  const formatRecipeNumber = (value: string): string => value.padStart(6, '0') 


  const recipesLenght = (): number => {
    return appointmentDetails?.prescription?.length || 0
  }

  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-recipes-list"
      open={open}
      onClose={() => handleOpenRecipesListModal(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <FadePatientRecipeModal in={open}>
        <BoxPatientRecipeModal>
          <GridModal>
            <GridWrapper>
              <CloseButton
                onClick={() => handleOpenRecipesListModal(false)}
                data-testid="close-button"
              >
                <Close />
              </CloseButton>
              {recipesLenght() === 0 ? 
                <GridWrapper>
                  <TypographyModal id="transition-modal-title" variant="h6">
                    Detalles cita {appointmentDetails?.appointment_date}
                  </TypographyModal>
                  <GridWrapper marginTop={5} textAlign={'center'}>
                    <TypographyWrapper sx={{ color: 'primary.dark' }}>
                      {appointmentDetails?.message}
                    </TypographyWrapper>
                  </GridWrapper>
                </GridWrapper>
              :
                <GridWrapper>
                  <TypographyModal id="transition-modal-title" variant="h6">
                    Detalles cita {appointmentDetails?.appointment_date}
                  </TypographyModal>
                  <GridWrapper marginTop={5}>
                    <TypographyTitleModal>
                      Diagnóstico
                    </TypographyTitleModal>
                    <GridWrapper
                      paddingX={2}
                      paddingY={1.5}
                      marginY={1}
                      borderRadius={8}
                      sx={{backgroundColor: '#F4F4FC'}}
                    >
                      {
                        appointmentDetails?.diagnostics?.map(d => `${d.cie_code} - ${d.diagnostic}`).join(' | ')
                      }
                    </GridWrapper>

                    <TypographyTitleModal marginTop={5}>
                      Documentos
                    </TypographyTitleModal>
                    
                    {appointmentDetails?.prescription?.map((presc: IPrescription, index: number) =>
                      <GridWrapper
                        container
                        marginTop={2}
                        display={'flex'}
                        justifyContent={'space-between'}
                        key={`document-${presc?.prescription_id}`}
                      >
                        <GridItem
                          xs={6}
                          width={100}
                          height={'auto'}
                          paddingX={1}
                          paddingY={1}
                          marginX={1}
                          borderRadius={8}
                          sx={{backgroundColor: '#F4F4FC'}}>
                            Receta No. {formatRecipeNumber(presc?.prescription_no)}
                          </GridItem>
                        <GridItem xs={3}>
                          <SubmitButton
                            fullWidth={false}
                            id="recipe-details"
                            dataTestid='recipe-details'
                            text="Ver"
                            type="button"
                            variant="contained"
                            onClick={() => handleSetPrescriptionIndex(index)}
                            sx={{
                              width: '130px',
                            }}
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <ButtonWrapper
                            data-testid="download-recipe"
                            variant='text'
                            startIcon={<VerticalAlignBottom />}
                            disableRipple
                            sx={{
                              color: 'primary.dark',
                              textTransform: 'initial',
                              ':hover': {
                                textDecoration: 'underline',
                              }
                            }}
                          >
                            Descargar
                          </ButtonWrapper>
                        </GridItem>
                      </GridWrapper>
                    )}
                  </GridWrapper>
                </GridWrapper>
              }
            </GridWrapper>
          </GridModal>
        </BoxPatientRecipeModal>
      </FadePatientRecipeModal>
    </ModalWrapper>
  )
}
