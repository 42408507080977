import { DialogActions, DialogActionsProps } from "@mui/material";
import React from "react";



const DialogActionsWrapper: React.FC<DialogActionsProps> = ({ ...props }) => (
  <DialogActions {...props} />
);

export default DialogActionsWrapper;
