import { styled } from '@mui/material'
import { AvatarWrapper, DrawerWrapper } from '../../../../../components/wrapper'

export const AvatarMap = styled(AvatarWrapper)(() => ({
  backgroundColor: '#f44336',
  border: '1px solid #ffffff',
  width: 50,
  height: 50
}))

export const DrawerMap = styled(DrawerWrapper)(() => ({
  '& .MuiDrawer-paper': {
    borderRadius: '20px 0 0 20px'
  }
}))
