import { useEffect, useState } from 'react'
import { GridWrapper, StackWrapper } from '../../../components/wrapper'
import { Title } from '../../../components/StyledComponents/Title'
import { GridFullScreen } from '../../../components/GridFullScreen/GridFullScreen'
import FormTextInput from '../../../components/FormInputText'
import { validateInput } from '../../../utils/validateInput'
import SubmitButton from '../../../components/SubmitButton'
import { IPatientForm, IPatientInfo } from '../types'
import {
  GridFormRegister,
  TypographyWrapperValidationPhoneFalse
} from './PatientFormStyle'
import { validatePhoneNumber } from '../../../utils/validatePhoneNumber'

interface PatientFormProps {
  onNext: () => void
  handlePatientInfo: (patientInfo: IPatientForm) => void
  patientInfo: IPatientInfo
}

const PatientForm = ({
  onNext,
  handlePatientInfo,
  patientInfo
}: PatientFormProps): JSX.Element => {
  const [name, setName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')
  const [idNumber, setIdNumber] = useState<string>('')
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([
    false,
    false,
    false,
    false
  ])

  useEffect(() => {
    setName(patientInfo.name)
    setLastName(patientInfo.lastName)
    setMobile(patientInfo.mobile)
    setIdNumber(patientInfo.idNumber)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]
    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  const onSubmit = (event: any) => {
    event.preventDefault()
    handlePatientInfo({
      name: name,
      lastName: lastName,
      idNumber: idNumber,
      mobile: mobile
    })
    onNext()
  }

  const validateName: boolean =
    !validateInput(name) && name.length === 0 && ValidationFocus[0]
  const validateLastName: boolean =
    !validateInput(lastName) && lastName.length === 0 && ValidationFocus[1]
  const validateMobile: boolean =
    !validateInput(mobile) && mobile.length === 0 && ValidationFocus[2]
  const validateIdNumber: boolean =
    !validateInput(idNumber) && idNumber.length === 0 && ValidationFocus[3]

  return (
    <>
      <GridFullScreen id="PatientRegistry">
        <GridFormRegister container flexDirection={'column'}>
          <Title variant="h5">Necesitamos saber un poco más de ti</Title>
          <form
            data-testid="patient-form"
            style={{ width: '55%' }}
            onSubmit={onSubmit}
          >
            <GridWrapper item xs={12} sx={{ marginTop: '2rem' }}>
              <StackWrapper
                spacing={2}
                direction="row"
                sx={{ marginBottom: 4 }}
              >
                <GridWrapper item xs={6}>
                  <FormTextInput
                    text="¿Cuál es tu nombre?"
                    placeholder="Dany"
                    id="name"
                    type="text"
                    fullWidth={true}
                    value={name}
                    name="name"
                    onBlur={() => onValidationFocus(0)}
                    onChange={(event) => setName(event.target.value)}
                    validate={validateName}
                    error={validateName}
                    textError={validateName ? 'Campo requerido.' : ''}
                  />
                </GridWrapper>

                <GridWrapper item xs={6}>
                  <FormTextInput
                    text="¿Cuál es tu apellido?"
                    placeholder="Pozo"
                    id="lastName"
                    name="lastName"
                    type="text"
                    fullWidth
                    value={lastName}
                    onBlur={() => onValidationFocus(1)}
                    onChange={(event) => setLastName(event.target.value)}
                    validate={validateLastName}
                    error={validateLastName}
                    textError={validateLastName ? 'Campo requerido.' : ''}
                  />
                </GridWrapper>
              </StackWrapper>
            </GridWrapper>

            <GridWrapper item xs={12} sx={{ mt: 2 }}>
              <StackWrapper
                spacing={2}
                direction="row"
                sx={{ marginBottom: 4 }}
              >
                <GridWrapper item xs={6}>
                  <FormTextInput
                    text="Teléfono"
                    placeholder="098232434"
                    id="mobile"
                    type="tel"
                    fullWidth={true}
                    value={mobile}
                    name="mobile"
                    onBlur={() => onValidationFocus(2)}
                    onChange={(event) => setMobile(event.target.value)}
                    validate={validateMobile}
                    error={validateMobile}
                    textError={validateMobile ? 'Campo requerido.' : ''}
                  />
                  {!validatePhoneNumber(mobile) && mobile.length > 0 && (
                    <TypographyWrapperValidationPhoneFalse>
                      Por favor, ingresa un teléfono celular válido
                    </TypographyWrapperValidationPhoneFalse>
                  )}
                </GridWrapper>
                <GridWrapper item xs={6}>
                  <FormTextInput
                    text="Cédula"
                    placeholder="12345678"
                    id="idNumber"
                    name="idNumber"
                    type="text"
                    fullWidth
                    value={idNumber}
                    onBlur={() => onValidationFocus(3)}
                    onChange={(event) => setIdNumber(event.target.value)}
                    validate={validateIdNumber}
                    error={validateIdNumber}
                    textError={validateIdNumber ? 'Campo requerido.' : ''}
                  />
                </GridWrapper>
              </StackWrapper>
            </GridWrapper>

            <GridWrapper item xs={12} sx={{ my: 2, marginTop: '4rem' }}>
              <SubmitButton
                id="button"
                type="submit"
                variant="contained"
                fullWidth
                text="Continuar"
                dataTestid="ok-button"
                disabled={
                  name.length === 0 ||
                  lastName.length === 0 ||
                  idNumber.length === 0 ||
                  mobile.length === 0
                }
              />
            </GridWrapper>
          </form>
        </GridFormRegister>
      </GridFullScreen>
    </>
  )
}

export default PatientForm
