import { AxiosError, AxiosResponse } from 'axios'
import { IApiResponseDoctorInfo, ISearchDoctorParams, ISuggestedDoctorsParams } from '../../dtos/DoctorInfo'
import { http } from '../Http'
import DoctorInfoDataSource from '../dataSource/DoctorInfoDataSource'

export default class DoctorInfoMapper implements DoctorInfoDataSource {
  async getDoctorInfo(idDoctor: string): Promise<IApiResponseDoctorInfo> {
    // const userInfo: string = localStorage.getItem('userData') || '{}'
    // const user_id: string = JSON.parse(userInfo).userId
    let data = await http
      .get(`/send_data/data_medical/${idDoctor}`)
      .then((response) => {
        const { data } = response
        return data
      })
      .catch((error) => {
        const data = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
            'Access-Control-Allow-Credentials': ''
          }
        }
        return data
      })
    return data
    // throw new Error('Method not implemented.')
  }

  async searchDoctor({page, limit, doctorName, specialty}: ISearchDoctorParams): Promise<IApiResponseDoctorInfo> {
    try {
      let params: string = `page=${page}&limit=${limit}`
      if (Boolean(doctorName)) params += `&name=${doctorName}`
      if (Boolean(specialty)) params += `&specialty=${specialty}`
      let data: AxiosResponse<IApiResponseDoctorInfo> = await http
        .get(`/send_data/search_doctor?${params}`)
      return data.data
    } catch (error: any) {
      const data: IApiResponseDoctorInfo = {
        statusCode: error.response.status,
        body: error.response.body,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        }
      }
      return data
    }
  }

  async getSuggestedDoctors({userId, officeId, specialty}: ISuggestedDoctorsParams): Promise<IApiResponseDoctorInfo> {
    try {
      const queryString: string = `user_id=${userId}&office_id=${officeId}&specialty=${specialty || ''}`
      let data: AxiosResponse<IApiResponseDoctorInfo> = await http.get(`/send_data/suggested_doctors?${queryString}`)
      return data.data
    } catch (error: unknown) {
      const data: IApiResponseDoctorInfo = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        }
      }
      return data
    }
  }
}
