// import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth0UserProfile } from "auth0-js";
import { IPatientInfo } from "../views/Register/types";

export interface GetSessionProps {
    user: Auth0UserProfile | undefined;
    headers: {
        Authorization:string;
    };
    // session: CognitoUserSession;
}

export type Severity = 'error' | 'warning' | 'info' | 'success'
export interface AuthState {
    status: 'checking' | 'authenticated' | 'not-authenticated';
    token: string | null;
    errorMessage: string;
    user: GetSessionProps | null;
    patient: IPatientInfo;
    snack: {
        open: boolean
        message: string
        severity?: Severity
    }
}

type AuthAction = 
    | { type: 'signUp', payload: { token: string, user: (GetSessionProps | null )} }
    | { type: 'addError', payload: string }
    | { type: 'removeError' }
    | { type: 'notAuthenticated' }
    | { type: 'logout' }
    | { type: 'patientData', payload: { patient: IPatientInfo }  }
    | { type: 'snack', payload: { open: boolean, message: string, severity?: Severity }  }


export const authReducer = ( state: AuthState, action: AuthAction ): AuthState => {

    switch (action.type) {
        case 'addError':
            return {
                ...state,
                user: null,
                status: 'not-authenticated',
                token: null,
                errorMessage: action.payload
            }
    
        case 'removeError':
            return {
                ...state,
                errorMessage: ''
            };

        case 'signUp':
            return {
                ...state,
                errorMessage: '',
                status: 'authenticated',
                token: action.payload.token,
                user: action.payload.user
            }

        case 'logout':
        case 'notAuthenticated':
            return {
                ...state,
                status: 'not-authenticated',
                token: null,
                user: null
            }
        case 'patientData':
            return {
                ...state,
                patient: action.payload.patient
            }
        
        case 'snack':
            return {
                ...state,
                snack: {
                    message: action.payload.message,
                    open: action.payload.open,
                    severity: action.payload.severity
                }
            }

        default:
            return state;
    }


}