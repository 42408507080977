import React from 'react'
import { IPastAppointment } from '../../../../../../../infrastructure/dtos/Appointments'
import {
  DividerWrapper,
  GridWrapper,
  ModalWrapper,
  TypographyWrapper
} from '../../../../../../../components'
import { Backdrop } from '@mui/material'
import {
  BoxModal,
  DisableTextInput,
  DisabledInput,
  TypographyDoctorInfo,
  TypographyLabel,
  TypographyName,
  TypographyRecipeNumber
} from './styles'
import {
  CloseButton,
  FadePatientRecipeModal
} from '../ShowRecipeList/ShowRecipesListStyle'
import SubmitButton from '../../../../../../../components/SubmitButton'
import { LabelInput } from '../../../../../../../components/StyledComponents/LabelInput'
import helthyLogo from '../../../../../../../assets/helthyLogo3.png'
import { Close } from '@mui/icons-material'
import { IPrescriptionItem, IRecipePrescription } from '../../../../../../../infrastructure/dtos/Prescription'

interface PatientRecipeModalProps {
  handleOpenRecipe: (value: boolean) => void
  open: boolean
  prescriptionIndex: number
  appointmentDetails: IRecipePrescription
}

function Recipe({
  handleOpenRecipe,
  open,
  prescriptionIndex,
  appointmentDetails
}: PatientRecipeModalProps): JSX.Element {
  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-recipe"
      open={open}
      onClose={() => handleOpenRecipe(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <FadePatientRecipeModal in={open}>
        <BoxModal>
          <GridWrapper>
            <GridWrapper
              width={'100%'}
              height={'auto'}
              display={'flex'}
              container
              marginBottom={2}
              sx={{ color: 'primary.dark' }}
            >
              <CloseButton
                onClick={() => handleOpenRecipe(false)}
                data-testid="close-button"
              >
                <Close />
              </CloseButton>
              <GridWrapper item xs={4}>
                <img
                  src={helthyLogo}
                  alt="Helthy logo"
                  width={'90%'}
                  height={'auto'}
                />
              </GridWrapper>
              <GridWrapper item xs={8} data-testid="diagnostic-header">
                <TypographyRecipeNumber>
                  Receta No. {appointmentDetails?.prescription[prescriptionIndex]?.prescription_no?.padStart(6, '0')}
                </TypographyRecipeNumber>
                <TypographyName variant="h6">
                  Dr. {`${appointmentDetails?.doctor?.name_doctor} ${appointmentDetails?.doctor?.last_name_doctor}`}
                </TypographyName>
                <TypographyLabel>{appointmentDetails?.doctor?.speciality_doctor?.join(', ')}</TypographyLabel>
                <form data-testid="code-access">
                  <GridWrapper container spacing={3}>
                    <GridWrapper
                      item
                      xs={6}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <TypographyLabel>
                        Reg. ACESS: {appointmentDetails?.doctor?.code_msp}
                      </TypographyLabel>
                    </GridWrapper>
                  </GridWrapper>
                </form>
                <TypographyDoctorInfo>
                  {appointmentDetails?.office?.name_medical_office}
                </TypographyDoctorInfo>
                <TypographyDoctorInfo>
                  {appointmentDetails?.doctor?.doctor_phone}
                  {appointmentDetails?.office?.office_phone ? ` - ${appointmentDetails?.office?.office_phone}` : ''}
                </TypographyDoctorInfo>
                <TypographyDoctorInfo>
                  {appointmentDetails?.doctor?.email_doctor}
                </TypographyDoctorInfo>
              </GridWrapper>
            </GridWrapper>

            <DividerWrapper variant="fullWidth" flexItem />
            <GridWrapper container display={'flex'} flexDirection={'row'}>
              <GridWrapper item xs={6}>
                <GridWrapper container display={'flex'} flexDirection={'row'}>
                  <GridWrapper item xs={6} padding={2}>
                    <LabelInput sx={{ marginLeft: 1.4 }}>
                      Nombre del paciente
                    </LabelInput>
                    <DisabledInput
                      padding={2}
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      {`${appointmentDetails?.patient?.name} ${appointmentDetails?.patient?.last_name}`}
                    </DisabledInput>
                  </GridWrapper>
                  <GridWrapper item xs={6} padding={2}>
                    <LabelInput sx={{ marginLeft: 1.4 }}>Fecha</LabelInput>
                    <DisabledInput
                      padding={2}
                      display={'flex'}
                      justifyContent={'center'}
                      data-testid={'prescription-date'}
                    >
                      {appointmentDetails?.appointment_date?.split('-').reverse().join('/')}
                    </DisabledInput>
                  </GridWrapper>
                </GridWrapper>

                <GridWrapper marginY={3} paddingX={2}>
                  <LabelInput sx={{ marginLeft: 1.4 }}>Diagnóstico</LabelInput>
                  <DisableTextInput>
                    {appointmentDetails?.diagnostics?.map(d => `${d?.cie_code} - ${d?.diagnostic}`).join(' | ')}
                  </DisableTextInput>
                </GridWrapper>

                <GridWrapper marginY={1} paddingX={2}>
                  <LabelInput sx={{ marginLeft: 1.4 }}>Receta</LabelInput>
                  <DisableTextInput>
                    {appointmentDetails?.prescription[prescriptionIndex]?.prescription_items?.map((item: IPrescriptionItem, index: number) =>
                      <TypographyWrapper key={`receta-${index}`}>{`${item?.quantity} ${item?.medicament} (${item?.dosage})`}</TypographyWrapper>
                    )}
                  </DisableTextInput>
                </GridWrapper>
              </GridWrapper>

              <GridWrapper item xs={6}>
                <GridWrapper marginY={1} paddingX={2}>
                  <LabelInput sx={{ marginLeft: 1.4 }}>Prescripción</LabelInput>
                  <DisableTextInput>
                    {appointmentDetails?.prescription[prescriptionIndex]?.prescription_items?.map((item: IPrescriptionItem, index: number) =>
                      <TypographyWrapper key={`prescription-${index}`}>
                        <b>{item.medicament}</b>
                        <br/>
                        {`${item?.time} ${item?.dosage}`}
                      </TypographyWrapper>
                    )}
                  </DisableTextInput>
                </GridWrapper>

                <GridWrapper padding={2}>
                  <LabelInput sx={{ marginLeft: 1.4 }}>
                    Observaciones:
                  </LabelInput>
                  <DisableTextInput>
                    {appointmentDetails?.prescription[prescriptionIndex]?.remarks || 'Sin observaciones'}
                  </DisableTextInput>
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>

            <GridWrapper display="flex" justifyContent="center" marginTop={3}>
              <SubmitButton
                id={''}
                variant={'contained'}
                dataTestid="print-button"
                fullWidth={true}
                type={'button'}
                text={'Imprimir'}
                sx={{
                  width: '25%',
                  margin: '0 20px',
                  backgroundColor: 'primary.main'
                }}
              />
            </GridWrapper>
          </GridWrapper>
        </BoxModal>
      </FadePatientRecipeModal>
    </ModalWrapper>
  )
}

export default Recipe
