import React, { FunctionComponent } from "react";

import { FormControlSelect } from "./ListStyle";
import { MenuItemWrapper, SelectWrapper } from "../wrapper";


interface IComponentProps {
    id: string,
    value: string,
    onClick: (value: string) => void,
    lists: string[],
    minWidth: string,
    disabled?: boolean
}

export const List: FunctionComponent<IComponentProps> = (
    { lists, id, value, onClick, minWidth, disabled }
) => {
    return (
        <FormControlSelect style={{ minWidth: minWidth }}>
            <SelectWrapper
                name={id}
                labelId={id}
                id={id}
                value={value}
                onChange={(event) =>
                    onClick(event.target?.value as string)
                }
                disabled={disabled}
            >
                {lists?.map((item, index) => {
                    return (
                        <MenuItemWrapper key={`specialist${index}`} value={item}>
                            {item}
                        </MenuItemWrapper>
                    )
                })}
            </SelectWrapper>
        </FormControlSelect>
    )
};