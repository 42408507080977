import { OfficesRepository } from '../repository/OfficesRepository'
import { IApiResponseOffices } from '../../infrastructure/dtos/Offices'

export interface GetOfficesUseCase {
  invoke: (idDoctor: string) => Promise<IApiResponseOffices>
}

export class GetOffices implements GetOfficesUseCase {
  private objectUseCase: OfficesRepository
  constructor(_objectUseCase: OfficesRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(idDoctor: string) {
    return this.objectUseCase.getOffices(idDoctor)
  }
}
