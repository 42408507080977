import { styled } from '@mui/material/styles'
import CardWrapper from '../../../../components/wrapper/CardWrapper'
import { AvatarWrapper, TypographyWrapper } from '../../../../components'

export const CustomAvatar = styled(AvatarWrapper)(() => ({
  backgroundColor: '#f44336',
  border: '1px solid #ffffff',
  width: 100,
  height: 100
}))

export const CardInfo = styled(CardWrapper)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: 600,
  border: 'none',
  boxShadow: 'none',
  backgroundColor: '#F7F8FD'
}))

export const TypographyInfo = styled(TypographyWrapper)(() => ({
  fontWeight: 'bold'
}))
