import { FormControlWrapper } from "../wrapper";
import { styled } from "@mui/material/styles";


export const FormControlSelect = styled(FormControlWrapper)(() => ({
    minWidth: '70%',
    borderRadius: '2rem',
    height: "3rem",
    backgroundColor: 'white',
    '& fieldset': { border: 'none' }
}))
