import React, { FunctionComponent, ReactNode } from "react";
import { StackFullScreen } from "../StyledComponents/StackFullScreen";

interface IGridFullScreenProps {
    children: ReactNode,
    id: string,
    direction?: "row" | "row-reverse" | "column" | "column-reverse"
}

export const GridFullScreen: FunctionComponent<IGridFullScreenProps> = (
    { children, id, direction = 'row' }
) => {
    return (
        <StackFullScreen spacing={2} direction={direction} id={id}>
            {children}
        </StackFullScreen>
    )
};