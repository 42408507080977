import { createContext, useState } from 'react'
import { IAddressPatient } from '../views/Register/types'

export interface ISearchContextProps {
  doctor_name: string
  speciality: string
  address: string
  addressData: IAddressPatient | undefined
}

export const SearchInitState: ISearchContextProps = {
  doctor_name: '',
  speciality: '',
  address: '',
  addressData: undefined
}

type SearchContextProps = {
  searchOffices: ISearchContextProps
  savelastSearch: (lastSearch: ISearchContextProps) => void
}

export const SearchOfficesContext = createContext({} as SearchContextProps)

export const SearchOfficesProvider = ({ children }: any): any => {
  const [searchOffices, setSearchOffices] = useState(SearchInitState)

  const savelastSearch = (lastSearch: ISearchContextProps): void => {
    setSearchOffices(lastSearch)
  }

  return (
    <SearchOfficesContext.Provider
      value={{
        searchOffices,
        savelastSearch
      }}
    >
      {children}
    </SearchOfficesContext.Provider>
  )
}
