import { styled } from '@mui/material'
import { MenuItemWrapper } from '../../../../components'

export const CustomMenuItem = styled(MenuItemWrapper)({
  color: 'grey',
  '& svg': {
    marginRight: '5px'
  },
  '& img': {
    marginRight: '7px',
    width: '1.4rem',
    height: '1.4rem',
  }
})
