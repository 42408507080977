import { createTheme } from '@mui/material/styles'
import { styled } from '@mui/material'
import CardHeaderWrapper from '../../../../components/wrapper/CardHeaderWrapper'

export const theme = createTheme({
  components: {
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: '16px',
          fontWeight: 'bold'
        },
        subheader: {
          fontSize: '12px'
        }
      }
    }
  }
})

export const StyledCardHeader = styled(CardHeaderWrapper)(() => ({
  '& .MuiTypography-h5': {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  '& .MuiTypography-body1': {
    fontSize: '12px'
  }
}))
