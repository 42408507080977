import { styled } from "@mui/material/styles";
import { FormControlLabelWrapper } from "../wrapper";
export const SquareCheckbox = styled(FormControlLabelWrapper)(
    () => ({
        color: ' #7f7f7f',
        fontSize: '0.875rem',
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left'
    })
)