import { styled } from "@mui/material";
import { BadgeWrapper } from "../../../../../../../components";

export const BadgeRatedDoctorStyled = styled(BadgeWrapper)((props) => ({
  zIndex: -1,
  position: "relative",
  "& .MuiBadge-badge": {
    position: "absolute",
    right: 10,
    top: 16,
    borderRadius: "50%",
    width: 30,
    height: 30,
    backgroundColor: props.theme.palette.secondary,
    color: props.theme.palette.background.paper,
  },
}));

export const BadgeDoctorFriendsStyled = styled(BadgeWrapper)((props) => ({
  zIndex: -1,
  position: "relative",
  "& .MuiBadge-badge": {
    position: "absolute",
    bottom: 55,
    right: 3,
    borderRadius: "50%",
    width: 55,
    height: 30,
    fontWeight: '400',
    letterSpacing: -1,
    color: '#878787',
  },
}));