import React from "react"
import { NavigateFunction, useNavigate } from 'react-router-dom'
import {
  INextAppointmentHour, IParamsGetNextAppointmentHour
} from '../../../infrastructure/dtos/Calendar'
import { DoctorDetailsRightPanelProps } from './'
import { IDoctorProps } from './components/RecommendedDoctors/components/Carousel'
import { IGetNextAppointmentHourUtility, getNextAppointmentHourUtility } from "../../../services/Contracts/Utility/CalendarUtility"
import { IDoctorUtility, getSuggestedDoctorsUtility } from "../../../services/Contracts/Utility/DoctorInfoUtility"
import { ISuggestedDoctors } from "../../../infrastructure/dtos/DoctorInfo"
import { MedicalOffice } from "../../../infrastructure/dtos/Offices"


export interface IUseDoctorDetailsRightPanel {
  loading: boolean,
  doctorInfo: INextAppointmentHour | undefined
  suggestedDoctors: string | IDoctorProps[]
  navigate: NavigateFunction
  selectedMarker: (officeId: string) => void 
}

export function useDoctorDetailsRightPanel({
  toggleDrawer,
  doctor,
  selectedPatientAddress
}: DoctorDetailsRightPanelProps): IUseDoctorDetailsRightPanel {
  const [doctorInfo, setDoctorInfo] = React.useState<
    INextAppointmentHour | undefined
  >(undefined)
  const [suggestedDoctors, setSuggestedDoctors] = React.useState<IDoctorProps[] | string>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [selectedOffice, setSelectedOffice] = React.useState<
    string | undefined
  >(doctor?.office_id)
  const [selectedDoctor, setSelectedDoctor] = React.useState<
    IParamsGetNextAppointmentHour | undefined
  >(doctor)

  const navigate = useNavigate()

  const fettNextAppointmentHour = async (): Promise<void> => {
    if (selectedOffice && doctor?.user_id) {
      setLoading(true)
      const [netxAppointment, suggestedDoctors]: [IGetNextAppointmentHourUtility, IDoctorUtility<ISuggestedDoctors[]>] = await Promise.all([
        getNextAppointmentHourUtility({
          ...selectedDoctor as IParamsGetNextAppointmentHour,
          office_id: selectedOffice,
          isDistance: Boolean(selectedPatientAddress) ? 1 : 0,
          coordinates: {
            lat: selectedPatientAddress?.coordinates.latitude || '',
            log: selectedPatientAddress?.coordinates.longitude || '',
          }
        }),
        getSuggestedDoctorsUtility({ userId: selectedDoctor?.user_id as string, officeId: selectedOffice, specialty: selectedDoctor?.specialty as string })
      ])
      if (suggestedDoctors.status === 1) {
        const transformedData = (typeof suggestedDoctors.data !== 'string' && (suggestedDoctors.data as ISuggestedDoctors[])?.reduce((acc: IDoctorProps[], curr: ISuggestedDoctors) => {
          acc.push({
            id: curr?.user_id,
            name_doctor: curr?.name_doctor,
            photo_doctor: curr?.photo_doctor,
            price:
              Number(curr?.medical_office?.consultation_value as string) || 0,
            rating: curr?.rating as number,
            advertised: curr?.paid_medical,
            suggested: !curr?.paid_medical,
            next_appointment_hour: curr?.next_appointment,
            officeId: curr?.medical_office?.office_id,
            specialty: curr?.specialty_doctor
          })
          return acc
        }, [])) || (suggestedDoctors.data as string)
        setSuggestedDoctors(typeof suggestedDoctors.data !== 'string' ? transformedData : [])
      }
      setDoctorInfo(
        netxAppointment.status === 1
          ? (netxAppointment.data as INextAppointmentHour)
          : undefined
      )
      setLoading(false)
    }
  }

  React.useEffect(() => {
    fettNextAppointmentHour()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffice, selectedDoctor])

  React.useEffect(() => {
    if (
      selectedOffice &&
      doctorInfo?.data_office &&
      doctorInfo.data_office.length > 0
    ) {
      const updatedInfo = doctorInfo?.data_office.map((ofc: MedicalOffice) => {
        if (
          ofc.office_id === doctorInfo.office_selected.office_id &&
          !ofc.selected
        )
          ofc.selected = true
        else if (ofc.selected) delete ofc.selected
        return ofc
      })

      setDoctorInfo({
        ...doctorInfo,
        data_office: updatedInfo
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorInfo?.office_selected])

  const selectedMarker = (officeId: string, doctor?: IParamsGetNextAppointmentHour): void => {
    setSelectedOffice(officeId)
    if (doctor)
      setSelectedDoctor(doctor)
  }
  return {
    loading,
    doctorInfo,
    suggestedDoctors,
    navigate,
    selectedMarker,
  }
}