import { Dispatch, useEffect, useState } from 'react'
import { GridWrapper, StackWrapper } from '../../../../../components/wrapper'
import SubmitButton from '../../../../../components/SubmitButton'
import FormInputText from '../../../../../components/FormInputText'
import { validateInput } from '../../../../../utils/validateInput'

interface IBookigForm {
  disabledButton: boolean
  comments: string
  reference: string
  disabledForm: boolean
  onSubmit: (e: { preventDefault(): void }) => Promise<void>
  setComments: Dispatch<React.SetStateAction<string>>
  setReference: Dispatch<React.SetStateAction<string>>
}

function BookigForm({
  disabledButton,
  comments,
  reference,
  disabledForm,
  onSubmit,
  setComments,
  setReference
}: IBookigForm) {
  // Form
  const [validLength, setValidLength] = useState<boolean>(false)
  const [blur, setBlur] = useState<boolean[]>([false, false])
  const [requiredLength] = useState<number>(40)

  useEffect(() => {
    setValidLength(comments.length >= requiredLength ? true : false)
  }, [comments, requiredLength])

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...blur]
    newValidationFocus[index] = true
    setBlur(newValidationFocus)
  }

  const validatorTextArea = (): string => {
    let textError = ''
    if (validLength) {
      textError = 'Máximo número de caracteres 40.'
    }
    if (!validateInput(comments) && comments.length === 0 && blur[0]) {
      textError = 'Campo requerido.'
    }
    return textError
  }
  const validatorErrorTextArea: string = validatorTextArea()

  return (
    <>
      <form
        data-testid="patient-form"
        style={{ width: '60%', marginRight: '3.125rem' }}
        onSubmit={onSubmit}
      >
        <StackWrapper spacing={6} direction="row" sx={{ marginBottom: 4 }}>
          <GridWrapper item xs={4}>
            <FormInputText
              text="Comentarios"
              type="text"
              id="booking-comments"
              placeholder="Tengo dolor de cabeza"
              fullWidth
              name="comments"
              value={comments}
              onChange={(event) => setComments(event.target.value)}
              error={(comments.length === 0 && blur[0]) || validLength}
              onBlur={() => onValidationFocus(0)}
              textError={validatorErrorTextArea}
              disabled={disabledForm}
            />
          </GridWrapper>
          <GridWrapper item xs={4}>
            <FormInputText
              text="¿Alguien te refirió?"
              type="text"
              id="booking-ref"
              placeholder="Juan Forero"
              fullWidth
              name="reference"
              value={reference}
              onChange={(event) => setReference(event.target.value)}
              disabled={disabledForm}
            />
          </GridWrapper>
          <GridWrapper item xs={4}>
            <SubmitButton
              id="button"
              type="submit"
              variant="contained"
              fullWidth
              text="Confirmar Cita"
              dataTestid="ok-button"
              sx={{ margin: '29px' }}
              disabled={comments.length < 5 || disabledButton}
            />
          </GridWrapper>
        </StackWrapper>
      </form>
    </>
  )
}

export default BookigForm
