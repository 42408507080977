import { GetPendingTasks } from "../../../domain"
import { PendingTasksRepositoryImpl } from "../../../infrastructure/RepositoryImpl/PendingTasksRepositoryImpl"
import { IApiPendingTasks, IPendingTasks } from "../../../infrastructure/dtos"
import PendingTasksMapper from "../../../infrastructure/http/mappers/PendingTasksMapper"

export interface IGetPendingTasksService {
  status: number
  data: IApiPendingTasks<IPendingTasks | string>
}

export async function getPendingTasksService(
  user_id: string
): Promise<IGetPendingTasksService> {
  try {
    const UseCase = new GetPendingTasks(
      new PendingTasksRepositoryImpl(new PendingTasksMapper())
    )
    return {
      status: 1,
      data: await UseCase.invoke(user_id)
    }
  } catch (error) {
    return {
      status: 0,
      data: error as IApiPendingTasks<IPendingTasks | string>
    }
  }
}