import React, { useEffect, useRef, useState } from 'react'
import LocationDoctor from '../LocationDoctor'
import { ISearchDoctor } from "../../../../../infrastructure/dtos/DoctorInfo";
import { IAddressPatient } from "../../../../Register/types";

interface MapConfig {
  center: google.maps.LatLngLiteral
  zoom: number
}

interface GoogleMapProps {
  results: ISearchDoctor[]
  selectedAddress: IAddressPatient | undefined
}

export default function GoogleMap({ results, selectedAddress }: GoogleMapProps) {
  const [map, setMap] = useState<google.maps.Map | undefined>();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      const mapConfig: MapConfig = {
        center: {
          lat: Number(selectedAddress?.coordinates.latitude) || -2.8949329,
          lng: Number(selectedAddress?.coordinates.longitude) || -79.0041135
        },
        zoom: 13,
      };

      //   const styledMapType = new google.maps.StyledMapType(
      //     [
      //       {
      //         featureType: "poi",
      //         stylers: [{ visibility: "off" }],
      //       },
      //       {
      //         featureType: "transit",
      //         stylers: [{ visibility: "off" }],
      //       },
      //       {
      //         featureType: "road",
      //         elementType: "labels.icon",
      //         stylers: [{ visibility: "off" }],
      //       },
      //       {
      //         featureType: "road",
      //         elementType: "labels.text",
      //         stylers: [{ visibility: "on" }],
      //       },
      //     ],
      //     { name: "Styled Map" }
      //   );

      const mapOptions: google.maps.MapOptions = {
        ...mapConfig,
        mapTypeControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        streetViewControl: false,
        fullscreenControl: false,
        mapId: 'hjerwhjkhlk' // TODO, set correct mapId
      }

      const googleMap = new google.maps.Map(ref.current, mapOptions)
      //   googleMap.mapTypes.set("styled_map", styledMapType);
      //   googleMap.setMapTypeId("styled_map");
      // Agregar control de posición actual
      //   if (navigator.geolocation) {
      //     navigator.geolocation.getCurrentPosition(
      //       (position) => {
      //         const { latitude, longitude } = position.coords;
      //         const currentPosition = new google.maps.LatLng(latitude, longitude);
      //         new google.maps.Marker({
      //           position: currentPosition,
      //           map: googleMap,
      //           icon: {
      //             url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
      //           },
      //         });
      //         googleMap.setCenter(currentPosition);
      //       },
      //       (error) => {
      //         console.error("Error al obtener la posición actual:", error);
      //       }
      //     );
      //   }

      setMap(googleMap)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div ref={ref} id="map" style={{ width: '100%', height: '78vh' }} data-testid="container-maps-div" />
      {map && <LocationDoctor map={map} results={results} selectedPatientAddress={selectedAddress} />}
    </>
  )
}
