import React from "react";
import { WhiteButton } from "../../../../components/StyledComponents/WhiteButton";
import { CalendarDate } from "../../../../components/StyledComponents/CalendarDate";
import { useNavigate } from "react-router-dom";

function DateButton(): JSX.Element {
  const navigate = useNavigate();
  return (
    <WhiteButton
      id="new-date-button"
      data-testid="new-date-button"
      onClick={() => {
        navigate("/appointment");
      }}
    >
      <CalendarDate />
      Nueva Cita
    </WhiteButton>
  );
}

export default DateButton;
