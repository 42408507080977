import { IRecipePrescription } from "../../../infrastructure/dtos/Prescription"
import { IGetAppointmentRecipesService, getAppointmentRecipesService } from "../Persistencia/PrescriptionsService"

export interface IGetAppointmentRecipesUtility {
  status: number,
  data: IRecipePrescription | string
}

export async function getAppointmentRecipesUtility(
  appointmentId: string
): Promise<IGetAppointmentRecipesUtility> {
  try {
    const { data, status }: IGetAppointmentRecipesService =
      await getAppointmentRecipesService(appointmentId)
    return {
      data: data.body,
      status: status === 1 || data.statusCode === 200 ? status : 0
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as string
    }
  }
}