import { PostNewDate } from '../../../domain/useCase/NewDateUseCase'
import { NewDateRepositoryImpl } from '../../../infrastructure/RepositoryImpl/NewDateRepositoryImpl'
import { SaveNewDate } from '../../../infrastructure/dtos/NewDate'
import NewDateMapper from '../../../infrastructure/http/mappers/NewDateMapper'
import { IServiceYearsExperience } from './InterfaceServiceResponse'

export async function NewDateService(
  dataNewDate: SaveNewDate
): Promise<IServiceYearsExperience> {
  try {
    const UseCaseApi: PostNewDate = new PostNewDate(
      new NewDateRepositoryImpl(new NewDateMapper())
    )
    return {
      status: 1,
      data: await UseCaseApi.invoke(dataNewDate)
    }
  } catch (error) {
    return {
      status: 0,
      data: error
    }
  }
}
