import { SaveQualificationRepository } from '../../domain/repository/SaveQualificationRepository'
import {
  QualificationDoctor,
  QualificationDoctorResponse
} from '../dtos/SaveQualifications'
import SaveQualificationDataSource from '../http/dataSource/SaveQualificationDataSource'

export default class SaveQualificationRepositoryImpl
  implements SaveQualificationRepository
{
  dataSource: SaveQualificationDataSource

  constructor(_dataSource: SaveQualificationDataSource) {
    this.dataSource = _dataSource
  }
  postNewDate(
    dataQuestions: QualificationDoctor
  ): Promise<QualificationDoctorResponse> {
    return this.dataSource.saveQuestions(dataQuestions)
  }
}
