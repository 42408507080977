import { useEffect, useState } from "react";
import { IDoctorInfo } from "../../../infrastructure/dtos/DoctorInfo";
import { IServiceYearsExperience } from "../../../services/Contracts/Persistencia/InterfaceServiceResponse";
import { DoctorInfoService } from "../../../services/Contracts/Persistencia/DoctorInfoService";

interface IuseHook {
  dataDoctor: IDoctorInfo;
}

export function useDataDoctor(idDoctor: string): IuseHook {
  const [dataDoctor, setDataDoctor] = useState<IDoctorInfo>({
    description_doctor: "",
    name_doctor: "",
    user_id: "",
    years_experience_doctor: "",
    speciality_doctor: [],
    university_doctor: "",
    photo_doctor: "",
    last_name_doctor: "",
    email_doctor: "",
  });

  useEffect(() => {
    fetchDoctorInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDoctorInfo = async (): Promise<void> => {
    try {
      const valores: IServiceYearsExperience = await DoctorInfoService(
        idDoctor
      );
      if (valores.data.body) {
        setDataDoctor(valores.data.body);
      }
    } catch (error) {
      console.log("Error fetching Doctor Info", error);
    }
  };

  return {
    dataDoctor,
  };
}
