import { useState, useContext } from 'react'
import {
  AccountContext,
  AuthContextType
} from '../../../contexts/AccountContext'
import { GridWrapper } from '../../../components'
import {
  LoginContentsStyle,
  TypographyLabel,
  TypographyLabelContentsStyle
} from '../../../utils/RegisterMedicalFormStyles'
import { validateEmail } from '../../../utils/validateEmail'
import { Title } from '../../../components/StyledComponents/Title'
import FormTextInput from '../../../components/FormInputText'
import { CheckboxSquare } from '../../../components/CheckboxSquare/CheckboxSquare'
import { SecondaryMediumButton } from '../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { GridPartialScreen } from '../../../components/StyledComponents/GridPartialScreen'
import SubmitButton from '../../../components/SubmitButton'
import FormInputIconText from '../../../components/FormInputIconText'
import { TabLink } from '../../../components/TabLink/TabLink'
import { Advert } from '../../../components/Notification/Advert/Advert'
import { Auth0Error } from 'auth0-js'
import { auth } from '../../../utils/auth0'
export interface ILoginPageFormProps {}

export const LoginPageForm: React.FunctionComponent<ILoginPageFormProps> = (
  props
) => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState<string>('')
  const [showPassword, setShowPassword] = useState(false)
  const [agreement, setAgreement] = useState<boolean>(false)
  const { authenticate } = useContext(AccountContext) as AuthContextType

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()

    // authenticate(email, password).catch((err: any) => {
    //   setMessage(
    //     'El correo electrónico no está confirmado. Revisa tu bandeja de entrada para continuar con el ingreso al sistema.'
    //   )
    //   setOpen(true)
    // })

    auth.login({
      username: email,
      password: password,
      realm: process.env.REACT_APP_AUTH0_REALM,
      redirectUri: process.env.REACT_APP_AUTH_LOGIN_REDIRECT_URI,
      responseType: 'token',
    }, (err: Auth0Error | null, result: any) => {
      if (err) console.log(err);
      return console.log(result);
    })
  }

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show)
  }

  const handleFederatedSignInGoogle = async (): Promise<void> => {
    // await Auth.federatedSignIn({
    //   provider: CognitoHostedUIIdentityProvider.Google
    // })
    auth.authorize({
      responseType: 'token',
      redirectUri: process.env.REACT_APP_AUTH_LOGIN_REDIRECT_URI,
      connection: 'google-oauth2',
    }, (err: Auth0Error | null) => {
      if (err) {
        setMessage('No se puede Conectar con Google.')
        setOpen(true)
      } else {
        setMessage('Inicio de sesión exitoso')
        setOpen(true) 
      }
    })
  }
  
  const handleFederatedSignInFacebook = async (): Promise<void> => {
    // try {
    //   await Auth.federatedSignIn({
    //     provider: CognitoHostedUIIdentityProvider.Facebook
    //   })
    // } catch (error) {
    //   setMessage('No se puede Conectar con Facebook.')
    //   setOpen(true)
    // }
    auth.authorize({
      responseType: 'token',
      redirectUri: process.env.REACT_APP_AUTH_LOGIN_REDIRECT_URI,
      connection: 'facebook',
    }, (err: Auth0Error | null) => {
      if (err) {
        setMessage('No se puede Conectar con Facebook.')
        setOpen(true)
      } else {
        setMessage('Inicio de sesión exitoso')
        setOpen(true) 
      }
    })
  }

  return (
    <>
      <GridWrapper item>
        <form onSubmit={onSubmit} data-testid="login-form">
          <LoginContentsStyle item xs={12}>
            <Title>Hola de nuevo, inicia sesión aquí</Title>
          </LoginContentsStyle>
          <GridWrapper item xs={12} sx={{ mt: 2 }}>
            <FormTextInput
              id="imput_email"
              type="email"
              placeholder="correo@google.com"
              fullWidth
              name="email"
              value={email}
              text="Ingresa tu correo electrónico"
              onChange={(event) => setEmail(event.target.value)}
              textError="Por favor, introduce una dirección de correo electrónico
                    válida"
              validate={!validateEmail(email) && email.length > 0}
            />
          </GridWrapper>

          <GridWrapper container sx={{ mt: 2 }}>
            <GridWrapper item xs={12} sx={{ mt: 2 }}>
              <FormInputIconText
                id="input_password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Contraseña"
                fullWidth
                text="Ingresa tu contraseña"
                name="password"
                value={password}
                onChange={(event: any) => setPassword(event.target.value)}
                onClick={handleClickShowPassword}
                showPassword
              />
            </GridWrapper>
            <GridWrapper container>
              <CheckboxSquare
                text="Mantener mi sesión iniciada"
                id="checkboxSesion"
                value={agreement}
                onChange={setAgreement}
              />
            </GridWrapper>
          </GridWrapper>

          <GridWrapper container sx={{ mb: 2, mt: 1 }}>
            <GridWrapper item xs={12}>
              <SubmitButton
                dataTestid="login-button"
                id="button"
                type="submit"
                variant="contained"
                fullWidth
                text="Ingresar"
              />
            </GridWrapper>
          </GridWrapper>

          <TypographyLabelContentsStyle>
            <TypographyLabel>o</TypographyLabel>
          </TypographyLabelContentsStyle>

          <GridWrapper container sx={{ mt: 1 }}>
            <SecondaryMediumButton
              id="ButtonLoginGmail"
              text="Ingresar con Gmail"
              type="button"
              role="button"
              onClick={handleFederatedSignInGoogle}
            />
            <SecondaryMediumButton
              id="ButtonLoginFacebook"
              text="Ingresar con Facebook"
              type="button"
              role="button"
              onClick={handleFederatedSignInFacebook}
            />
          </GridWrapper>

          <GridWrapper container sx={{ mt: 2 }}>
            <TabLink
              color="inherit"
              id="TabLinkRegistrate"
              text="¿Olvidaste tu contraseña? Recupérala"
            />
          </GridWrapper>
          <GridPartialScreen container></GridPartialScreen>
        </form>
      </GridWrapper>

      <Advert
        message={message}
        onClose={handleClose}
        open={open}
        title="Aviso"
      />
    </>
  )
}
