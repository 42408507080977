import { GridWrapper } from '../../../../../components'
import { CarouselFunction, IRecommendedDoctorsProps } from './components/Carousel'

export function RecommendedDoctors({doctors, getDoctorInfo}: IRecommendedDoctorsProps): JSX.Element {
  return (
    <GridWrapper item maxWidth={"460px"} data-testid="carousel-root"
      sx={{
        '& .carousel-root > .carousel .slider-wrapper.axis-horizontal .slider': {
          justifyContent: doctors.length > 3 ? 'start' : 'center'
        }
      }}
    >
      <CarouselFunction doctors={doctors} getDoctorInfo={getDoctorInfo}/>
    </GridWrapper>
  );
}
