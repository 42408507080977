import {
  GridWrapper,
  IconButtonWrapper,
  ListItemWrapper,
  ListWrapper,
  MenuItemWrapper,
  TypographyWrapper
} from '../../../../../components/wrapper'
import { Title } from '../../../../../components/StyledComponents/Title'
import { AvatarNextDate } from './styles'
import { SecondaryMediumButton } from '../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { OutLineButton } from '../../../../../components/StyledComponents/OutLineButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Menu } from '@mui/material'
import { OutLineDarkButton } from '../../../../../components/StyledComponents/OutLineDarkButton'
import StarIcon from '@mui/icons-material/Star'
import { INextAppointment } from '../../../../../infrastructure/dtos/Appointments'
import { useNewDatesList } from './NewDatesLitsHook'
import { useConfirm } from './ConfirmAppointments/hooks/useConfirmModal'
import ConfirmAppointments from './ConfirmAppointments/ConfirmAppointments'
import { useState } from 'react'
import { FollowUtility } from '../../../../../services/Contracts/Utility/FollowUtility'
import { useCancelAppointment } from './CancelAppointment/useCancelAppointment'
import { useNavigate } from 'react-router-dom'
import { CancelAppointment } from './CancelAppointment/CancelAppointment'

const options: string[] = ['Reagendar', 'Cancelar']

interface IGridBody {
  value: string
  width: string
}

interface IGridHeader {
  title: string
  width: string
}

interface INextDateListProps {
  fromHome: boolean
}

function NextDatesList({ fromHome }: INextDateListProps): JSX.Element {
  const navigate = useNavigate()
  const {
    anchorEl,
    message,
    nextAppointments,
    handleClick,
    handleClose,
    fetchNextAppointments
  } = useNewDatesList()

  const userInfo: any = localStorage.getItem('userData') ?? '{}'
  const idPatient = JSON.parse(userInfo)?.userId

  const [appointmentToModal, setAppointmentToModal] =
    useState<INextAppointment>({} as INextAppointment)
  const { handleCloseConfirm, handleOpenConfirm, openConfirm } = useConfirm()
  const { openCancelModal, handleOpenCancelAppointment } =
    useCancelAppointment()

  const handleClickOption = (
    option: string,
    appointment: INextAppointment,
    index: number
  ): void => {
    switch (option) {
      case 'Reagendar':
        navigate(
          `/appointment/booking/${appointment.user_id}/${appointment.appointment_id}`
        )
        break
      case 'Cancelar':
        handleOpenCancelAppointment(true)
        setAppointmentToModal(appointment)
        break
      default:
        handleClose(index)
    }
  }

  const widthGap: number = fromHome ? 4 : 0

  const gridHeader: IGridHeader[] = [
    { title: '', width: `${3.3 + widthGap}%` },
    { title: 'Fecha', width: `${11 + widthGap}%` },
    { title: 'Hora', width: `${11 + widthGap}%` },
    { title: 'Doctor', width: `${11 + widthGap}%` },
    { title: 'Lugar', width: `${11 + widthGap}%` },
    fromHome
      ? { title: '', width: '0px' }
      : { title: 'Especialidad', width: '11%' },
    { title: '', width: fromHome ? '30px' : '40%' }
  ]

  const gridBody = (item: INextAppointment): IGridBody[] => [
    {
      value: item.appointment_date.split('-').reverse().join('/'),
      width: `${11 + widthGap}%`
    },
    { value: item.hour_from.split(':').join('h'), width: `${11 + widthGap}%` },
    {
      value: `${item.name_doctor} ${item.last_name_doctor}`,
      width: `${11 + widthGap}%`
    },
    { value: item.reference_medical_office, width: `${11 + widthGap}%` },
    fromHome
      ? { value: '', width: '0px' }
      : { value: item.speciality_doctor, width: '11%' }
  ]

  const followButtonText = (follow: boolean): string =>
    follow ? 'Dejar de seguir' : 'SEGUIR'

  const follow = async (patientId: string, userId: string): Promise<void> => {
    try {
      await FollowUtility({ doctor_id: userId, patient_id: patientId })
      fetchNextAppointments()
    } catch (error: unknown) {
      console.error(error)
    }
  }

  return (
    <GridWrapper px={!fromHome ? '4rem' : ''} pt={!fromHome ? 2 : 0}>
      <GridWrapper>
        {!fromHome && (
          <Title sx={{ marginBottom: 0 }} data-testid="title-next-dates">
            Tus próximas citas
          </Title>
        )}
        {!Boolean(message) ? (
          <ListWrapper>
            <ListItemWrapper
              style={{
                borderRadius: '40px',
                margin: '10px 0'
              }}
              data-testid="next-dates"
            >
              {gridHeader.map((item: IGridHeader, index: number) => (
                <GridWrapper
                  item
                  textAlign={'center'}
                  width={item.width}
                  key={`GridHeader-${index}`}
                >
                  {item.title}
                </GridWrapper>
              ))}
            </ListItemWrapper>
            {nextAppointments.map(
              (appointment: INextAppointment, index: number) => (
                <ListItemWrapper
                  key={`appointment-${appointment.appointment_id}-${index}`}
                  style={{
                    height: '80px',
                    background: '#FFF',
                    borderRadius: '40px',
                    margin: '10px 0'
                  }}
                  data-testid="next-dates-item-list"
                >
                  <GridWrapper
                    item
                    textAlign={'center'}
                    width={`${3.3 + widthGap}%`}
                  >
                    <AvatarNextDate src={appointment.photo_doctor} />
                  </GridWrapper>
                  {gridBody(appointment).map((i: IGridBody, idx: number) => (
                    <GridWrapper
                      key={`GridBody-${appointment.appointment_id}-${idx}`}
                      item
                      textAlign={'center'}
                      width={i.width}
                      color={
                        appointment.status === 'CANCELLED'
                          ? '#e4ecf1'
                          : 'inherit'
                      }
                    >
                      {i.value}
                    </GridWrapper>
                  ))}
                  <GridWrapper item textAlign={'center'} width={'12%'}>
                    <SecondaryMediumButton
                      id="Coming"
                      text="Como llegar"
                      type="button"
                      sx={{ height: '43px' }}
                      xs={12}
                      variant="contained"
                      disabled={
                        appointment.status === 'CANCELLED' ? true : false
                      }
                    />
                  </GridWrapper>
                  {!fromHome && (
                    <GridWrapper item textAlign={'center'} width={'12%'}>
                      <OutLineButton
                        data-testid="confirm-button"
                        onClick={() => {
                          setAppointmentToModal(appointment)
                          handleOpenConfirm()
                        }}
                        variant="outlined"
                        disabled={
                          appointment.status === 'ACTIVE' ? false : true
                        }
                      >
                        {appointment.status === 'CONFIRMED'
                          ? 'Confirmada'
                          : 'Confirmar'}
                      </OutLineButton>
                    </GridWrapper>
                  )}
                  <GridWrapper item textAlign={'center'} width={'12%'}>
                    <OutLineDarkButton
                      data-testid="follow-button"
                      onClick={() => follow(idPatient, appointment.user_id)}
                      disabled={
                        appointment.status === 'CANCELLED' ? true : false
                      }
                      variant="outlined"
                    >
                      <StarIcon />
                      {followButtonText(appointment.follow)}
                    </OutLineDarkButton>
                  </GridWrapper>
                  <GridWrapper item textAlign={'center'} width={'5%'}>
                    <IconButtonWrapper
                      key={`menu-button-${appointment.appointment_id}`}
                      aria-label="more"
                      id="long-button"
                      data-testid="long-button"
                      aria-controls={
                        appointment?.showMenu ? 'long-menu' : undefined
                      }
                      aria-expanded={appointment?.showMenu ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, index)}
                      disabled={
                        appointment.status === 'CANCELLED' ? true : false
                      }
                    >
                      <MoreHorizIcon
                        fontSize="large"
                        sx={{
                          color:
                            appointment.status === 'CANCELLED'
                              ? '#e4ecf1'
                              : 'primary.dark'
                        }}
                      />
                    </IconButtonWrapper>
                    <Menu
                      key={`menu-${appointment.appointment_id}`}
                      id="long-menu"
                      data-testid="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button'
                      }}
                      anchorEl={anchorEl}
                      open={Boolean(appointment?.showMenu)}
                      onClose={() => handleClose(index)}
                      sx={{ borderRadius: '20%' }}
                    >
                      {options.map((option: string, i: number) => (
                        <MenuItemWrapper
                          key={`${option}-${i}`}
                          data-testid={`${option}`}
                          onClick={() =>
                            handleClickOption(option, appointment, index)
                          }
                        >
                          {option}
                        </MenuItemWrapper>
                      ))}
                    </Menu>
                  </GridWrapper>
                </ListItemWrapper>
              )
            )}
          </ListWrapper>
        ) : (
          <TypographyWrapper marginTop={4} marginLeft={2}>
            {message}
          </TypographyWrapper>
        )}
      </GridWrapper>
      {openConfirm && (
        <ConfirmAppointments
          handleClose={handleCloseConfirm}
          open={openConfirm}
          appointment={appointmentToModal}
          fetchAppointments={fetchNextAppointments}
        />
      )}
      {openCancelModal && (
        <CancelAppointment
          handleClose={() => handleOpenCancelAppointment(false)}
          open={openCancelModal}
          appointment={appointmentToModal}
          fetchAppointments={fetchNextAppointments}
        />
      )}
    </GridWrapper>
  )
}

export default NextDatesList
