import { useState } from "react";
import { IAddressPatient, IPatientForm, IPatientInfo } from "../types";

export const usePatient = () => {
  const [patient, setPatient] = useState<IPatientInfo>({
    name: "",
    lastName: "",
    mobile: "",
    idNumber: "",
    addresses: []
  });
  
  const setPatientInfo = (patientInfo: IPatientForm): void => {
    setPatient({
      ...patient,
      name: patientInfo.name,
      lastName: patientInfo.lastName,
      mobile: patientInfo.mobile,
      idNumber: patientInfo.idNumber,
    });
  };

  const setPatientAddresses = (addresses: IAddressPatient[]): void => {
    setPatient({
      ...patient,
      addresses      
    });
  };



  return {
    patient,
    setPatientInfo,
    setPatientAddresses,
  }

};
