import { OfficesRepository } from '../../domain'
import { IApiResponseOffices } from '../dtos/Offices'
import OfficesDataSource from '../http/dataSource/OfficesDataSource'

export class OfficesRepositoryImpl implements OfficesRepository {
  dataSource: OfficesDataSource

  constructor(_datasource: OfficesDataSource) {
    this.dataSource = _datasource
  }

  async getOffices(idDoctor: string): Promise<IApiResponseOffices> {
    return this.dataSource.getOffices(idDoctor)
  }
}
