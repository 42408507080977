import { TypographyWrapper } from "../wrapper";
import { styled } from "@mui/material/styles";

export const TypographyWrapperValidationTrue = styled(TypographyWrapper)(
    () => ({
        color: ' #75c44c',
        fontSize: '0.8rem',
        fontFamily: 'Helvetica',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    })
)

export const TypographyWrapperValidationFalse = styled(TypographyWrapper)(
    () => ({
        color: ' #7d7d7d',
        fontSize: '0.8rem',
        fontFamily: 'Helvetica',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        minWidth: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    })
)

