import React, { useEffect, useRef } from "react";
import { createRoot, Root } from "react-dom/client";
import { ILocationDoctorProps } from "../../types";
import { GridWrapper } from "../../../../../components";

export default function Marker({
  map,
  children,
  position,
  onClick,
  testId = ''
}: ILocationDoctorProps): JSX.Element {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | undefined>();
  const rootRef = useRef<Root | null>(null);

  useEffect(() => {
    if (!rootRef.current) {
      const container: HTMLDivElement = document.createElement("div");
      container.style.transform = 'translateZ(0) translate(0%, 50%)'
      rootRef.current = createRoot(container);

      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        position,
        content: container,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    rootRef.current?.render(children);
    (markerRef.current as google.maps.marker.AdvancedMarkerElement).position =
      position;
    (markerRef.current as google.maps.marker.AdvancedMarkerElement).map = map;
    (markerRef.current as google.maps.marker.AdvancedMarkerElement).title = testId;
    (markerRef.current as google.maps.marker.AdvancedMarkerElement)?.addListener("click", onClick as Function);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, position, children]);

  return <GridWrapper />;
}
