import { styled } from '@mui/material'
import ButtonWrapper from '../wrapper/ButtonWrapper'

export const OutLineDarkButton = styled(ButtonWrapper)((props) => ({
  margin: '0 5px',
  textTransform: 'capitalize',
  borderRadius: '50px',
  backgroundColor: '#ffffff',
  color: props.theme.palette.primary.dark,
  padding: '14px 24px',
  border: '1.5px solid #3850b2',
  height: '43px',
  width: '95%',
  '&:hover': {
    backgroundColor: '#efefef'
  },
  lineHeight: '13.5px'
}))
