import {
  IApiResponseSaveNewDate,
  SaveNewDate
} from '../../infrastructure/dtos/NewDate'
import { NewDateRepository } from '../repository/NewDateRepository'

export interface NewDateUseCase {
  invoke: (dataNewDate: SaveNewDate) => Promise<IApiResponseSaveNewDate>
}

export class PostNewDate implements NewDateUseCase {
  private objectUseCase: NewDateRepository
  constructor(_objectUseCase: NewDateRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(dataNewDate: SaveNewDate) {
    return this.objectUseCase.postNewDate(dataNewDate)
  }
}
