import { styled } from "@mui/material"
import { SelectWrapper, TypographyWrapper } from "../../../../components"

export const SelectOffice = styled(SelectWrapper)((props) => ({
  width: "100%",
  borderRadius: "42px",
  backgroundColor: props.theme.palette.background.default,
  boxShadow: "2px 2px 3px 2.5px rgba(0, 0, 0, 0.06)",
  height: "3.3rem",
  "& fieldset": { border: "none" },
}));

export const TypographyWrapperLabel = styled(TypographyWrapper)(() => ({
  textAlign: "left",
  color: " #000",
  fontSize: "14px",
  fontFamily: "Helvetica",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  marginBottom: "0.6875rem",
  marginLeft: "2rem",
}));
