import { GetDoctoInfo, SearchDoctor, GetSuggestedDoctor } from '../../../domain/useCase/DoctorInfo'
import { DoctorInfoRepositoryImpl } from '../../../infrastructure/RepositoryImpl/DoctorInfoRepositoryImpl'
import { IApiResponseDoctorInfo, ISearchDoctorParams, ISuggestedDoctorsParams } from '../../../infrastructure/dtos/DoctorInfo'
import DoctorInfoMapper from '../../../infrastructure/http/mappers/DoctorInfoMapper'
import { IServiceYearsExperience } from './InterfaceServiceResponse'

export interface IDoctorService {
  status: number;
  data: string | IApiResponseDoctorInfo;
}

export interface ISuggestedDoctorsService {
  status: number;
  data: string | IApiResponseDoctorInfo;
}

export async function DoctorInfoService(
  idDoctor: string
): Promise<IServiceYearsExperience> {
  try {
    const UseCaseDoctor = new GetDoctoInfo(
      new DoctorInfoRepositoryImpl(new DoctorInfoMapper())
    )
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(idDoctor)
    }
  } catch (error) {
    return {
      status: 0,
      data: error
    }
  }
}

export async function SearchDoctorService(
  params: ISearchDoctorParams
): Promise<IDoctorService> {
  try {
    const UseCaseDoctor = new SearchDoctor(
      new DoctorInfoRepositoryImpl(new DoctorInfoMapper())
    )
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(params)
    }
  } catch (error: any) {
    return {
      status: 0,
      data: error
    }
  }
}

export async function GetSuggestedDoctorsService(
  params: ISuggestedDoctorsParams
): Promise<ISuggestedDoctorsService> {
  try {
    const UseCaseDoctor = new GetSuggestedDoctor(
      new DoctorInfoRepositoryImpl(new DoctorInfoMapper())
    )
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(params)
    }
  } catch (error: any) {
    return {
      status: 0,
      data: error
    }
  }
}