import { FormControl, Pagination } from '@mui/material'
import { GridWrapper, StackWrapper, ToolbarWrapper } from '../../../components'
import { Title } from '../../../components/StyledComponents/Title'
import CardDoctor from '../../Appointment/components/CardDoctor'
import { IPagination } from '../../Appointment/Hooks/useSearchDoctor'
import { ISearchDoctor } from '../../../infrastructure/dtos/DoctorInfo'
import { useState } from 'react'
import { SwitchUI } from './style'
import DoctorResultsGoogleMap from '../DoctorResultsGoogleMap'
import { IAddressPatient } from '../../Register/types'

interface IDoctorResultsProps {
  pagination: IPagination
  results: ISearchDoctor[]
  handleChangePage(event: React.ChangeEvent<unknown>, value: number): void
  addressData: IAddressPatient | undefined
}
export const DoctorResults = ({
  pagination,
  results,
  handleChangePage,
  addressData
}: IDoctorResultsProps): JSX.Element => {
  const [showMap, setShowMap] = useState<boolean>(true)
  return (
    <GridWrapper data-testid="doctors-card-list">
      <ToolbarWrapper sx={{ marginBottom: '40px' }}>
        <StackWrapper direction="row" spacing={2} alignItems="center">
          <Title>Resultados de la búsqueda:</Title>
        </StackWrapper>
        <StackWrapper
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ marginLeft: 'auto' }}
        >
          <FormControl sx={{ display: 'block' }}>
            <label htmlFor="list">Lista</label>
            <SwitchUI
              checked={showMap}
              onChange={() => setShowMap(!showMap)}
              inputProps={{ 'aria-label': 'controlled' }}
              data-testid={"switch-map-list"}
            />
            <label htmlFor="map">Mapa</label>
          </FormControl>
        </StackWrapper>
      </ToolbarWrapper>
      {showMap ? (
        <GridWrapper
          container
          display={'flex'}
          justifyContent={'center'}
          gap={2}
        >
          {results?.length === 0 ? (
            <Title>
              Lo siento, no encontramos médicos con los datos ingresados.
            </Title>
          ) : (
            <DoctorResultsGoogleMap
              results={results}
              selectedAddress={addressData}
            />
          )}
        </GridWrapper>
      ) : (
        <>
          <GridWrapper
            container
            display={'flex'}
            justifyContent={'center'}
            gap={2}
          >
            {results?.length === 0 ? (
              <Title>
                Lo siento, no encontramos médicos con los datos ingresados.
              </Title>
            ) : (
              results &&
              results.map((result: ISearchDoctor, index: number) => (
                <CardDoctor key={`CardDoctor-${index}`} doctor={result} />
              ))
            )}
          </GridWrapper>
          <GridWrapper display={'flex'} justifyContent={'center'} mt={5}>
            <Pagination
              shape="rounded"
              count={pagination.totalPages}
              page={pagination.page}
              onChange={handleChangePage}
              disabled={results?.length === 0}
            ></Pagination>
          </GridWrapper>
        </>
      )}
    </GridWrapper>
  )
}
