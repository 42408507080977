import { useState } from "react"
import { GridLayout } from "./ScheduleAppointmentStyle"
import { SelectSchedule } from "./steps/SelectSchedule/SelectSchedule"
import { FillData } from "./steps/FillData/FillData"

enum STEPS {
  SELECT_SCHEDULE,
  FILL_DATA,
}

export const ScheduleAppointment = (): JSX.Element => {
  const [ stepper, setStepper ] = useState<number>(0)

  const onNext = (): void => setStepper(STEPS.FILL_DATA)
  const onBack = (): void => setStepper(STEPS.SELECT_SCHEDULE)

  return (
    <GridLayout>
      {stepper === STEPS.SELECT_SCHEDULE &&
        <SelectSchedule
          onStepper={onNext}
        />
      }
      {stepper === STEPS.FILL_DATA &&
        <FillData
          onStepper={onBack}
        />
      }
    </GridLayout>
  );
}
