import { useEffect, useState } from 'react'
import {
  IAppointmentQualification,
  IPendingTasks
} from '../../../infrastructure/dtos'
import { getPendingTasksUtility } from '../../../services/Contracts/Utility/PendingTasksUtility'

interface IUsePendingComponentHook {
  pendings: IPendingTasks
  loadingPendings: boolean
  openModal: boolean
  pendingId: IAppointmentQualification | undefined
  handleOpenModal: (item: IAppointmentQualification) => void
  handleCloseModal: () => void
  fetchPendingsTasks: (userId: string) => Promise<void>
}

export function usePendingComponent(): IUsePendingComponentHook {
  const [loadingPendings, setLoadingPendings] = useState<boolean>(false)
  const [user_id] = useState<string>(
    JSON.parse(localStorage.getItem('userData') as string)?.userId
  )
  const [pendings, setPendings] = useState<IPendingTasks>({
    data: {
      appointment_qualification: [],
      confirmation_appointment:[]
    },
    message: ''
  })

  const [pendingId, setPendingId] = useState<IAppointmentQualification>()

  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleOpenModal = (item: IAppointmentQualification): void => {
    setOpenModal(true)
    setPendingId(item)
  }

  const handleCloseModal = (): void => setOpenModal(false)

  const fetchPendingsTasks = async (user_id: string): Promise<void> => {
    setLoadingPendings(true)
    const { data } = await getPendingTasksUtility(user_id)
    setPendings(data as IPendingTasks)
    setLoadingPendings(false)
  }

  useEffect(() => {
    fetchPendingsTasks(user_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    pendings,
    loadingPendings,
    openModal,
    pendingId,
    handleOpenModal,
    handleCloseModal,
    fetchPendingsTasks
  }
}
