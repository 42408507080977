import { styled } from '@mui/material/styles'
import CardWrapper from '../../../../components/wrapper/CardWrapper'
import { AvatarWrapper, ButtonWrapper, TypographyWrapper } from '../../../../components'

export const CustomAvatar = styled(AvatarWrapper)(() => ({
  backgroundColor: '#f44336',
  border: '1px solid #ffffff',
  width: 100,
  height: 100
}))

export const CardInfo = styled(CardWrapper)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: 600,
  border: 'none',
  boxShadow: 'none',
  backgroundColor: '#F7F8FD',
}))

export const TypographyInfo = styled(TypographyWrapper)(() => ({
  fontSize: 20,
  fontWeight: 'bold'
}))
export const LabelText = styled(TypographyWrapper)(() => ({
  textAlign: 'left',
  color: ' #000',
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.3rem',
}))
export const ButtonR = styled(ButtonWrapper)((props) => ({
  margin: '0 5px',
  textTransform: 'capitalize',
  borderRadius: '50px',
  backgroundColor: '#ffffff',
  height: 50,
  color: props.theme.palette.primary.main,
  padding: '7px 12px',
  border: '1px solid ' + props.theme.palette.primary.main,
  '&:hover': {
    backgroundColor: props.theme.palette.primary.main,
    color: '#ffffff',
  }
}))