import { useEffect, useState } from 'react'
import { GetSpecialtiesUtility } from '../../../services/Contracts/Utility/SpecialtiesUtility'
import { ISpecialty, IUtilitySpecialties } from '../../../infrastructure/dtos'

interface IUseHook {
  listSpecialties: ISpecialty[]
  error: boolean
  message: string
  handleSetMessage(message: string): void
}

export function useSpecialties(): IUseHook {
  const [listSpecialties, setListSpecialties] = useState<ISpecialty[]>([])
  const [error, setError] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    fetchDoctorInfo()
  }, [])

  const fetchDoctorInfo = async (): Promise<void> => {
    try {
      const value: IUtilitySpecialties = await GetSpecialtiesUtility()
      setListSpecialties(value.data)
    } catch (error) {
      console.log('Error fetching Doctor Info', error)
      setError(true)
      setMessage('No se pudo cargar la lista')
    }
  }

  const handleSetMessage = (message: string): void => setMessage(message)

  return {
    listSpecialties,
    error,
    message,
    handleSetMessage
  }
}
