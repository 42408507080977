import { useMemo, useState, useEffect } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { BoxResults, GridGeneral, GridItem, GridrespuestaItem, TextFielRegister, TypographyName } from "../styles/AutocompleteGoogleStyle";
// install npm i --save-dev @types/autosuggest-highlight
// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
interface ICoordinates {
  lat: number | undefined,
  lng: number | undefined,
}
const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}
interface IPropsMap {
  address: string,
  setMarkerPosition: React.Dispatch<React.SetStateAction<google.maps.LatLng | null>>,
  setZoom: React.Dispatch<React.SetStateAction<number>>,
  setAddress: React.Dispatch<React.SetStateAction<string>>,
  setMapCenter: React.Dispatch<React.SetStateAction<any>>,
  isLoaded: boolean,
  setSelected: React.Dispatch<React.SetStateAction<any>>,
  selected: boolean,
  isDisabled?: boolean,
  value: PlaceType | null,
  setValue: React.Dispatch<React.SetStateAction<PlaceType | null>>,
  id?: number,
  editingValueEdition?: any,
}
export const AutocompleteGoogleMaps: React.FC<IPropsMap> = (props: IPropsMap) => {
  const { address, isLoaded, selected, setSelected,
    setAddress, setMapCenter, setMarkerPosition, setZoom, isDisabled = false,
    value,
    setValue,
    id,
    editingValueEdition } = props;

  // const [value, setValue] = useState<PlaceType | null>(null);
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  useEffect(() => {
    if (address === "") {
      setValue(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            {
              ...request,
              componentRestrictions: { country: "ec" }
            },
            callback,
          );
        },
        400,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (address === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    if (address.length <= 3) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: address }, (results?: readonly PlaceType[]) => {
      if (results && results.length > 0 && selected) {
        setValue(results[0])
        setSelected(false)
      }
      if ((!results) && selected) {
        setValue({
          description: address,
          structured_formatting: {
            main_text: "",
            secondary_text: "",
            main_text_matched_substrings: undefined
          }
        })
        setSelected(false)
      }


      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, address, fetch]);

  const handleSelect = async (place: PlaceType | null) => {
    try {
      if (!place) {
        return;
      }
      const geocoder = new google.maps.Geocoder();
      await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
        geocoder.geocode({ address: place.description }, function (results, status) {
          if (status === 'OK') {
            if (results && results.length > 0) {
              const lat = results[0].geometry.location.lat();
              const lng = results[0].geometry.location.lng();
              const newCenter: ICoordinates = {
                lat: lat,
                lng: lng
              };
              setMapCenter(newCenter);
              setZoom(20);
              setMarkerPosition(results[0].geometry.location!);
              setAddress(place.description);
            }
          }
          else {
            reject(new Error('Couldnt\'t find the location ' + place.description));
          }
        });
      });
    } catch (error) {

    }
  }

  return isLoaded ? (
    <>
      <Autocomplete
        id="google-map-autocomplete"
        sx={
          {
            border: "none",
            width: "100%",
            height: "55px",
            borderRadius: "55px",
            backgroundColor: "#fff",
            fontSize: "20px",
            // color: "#7f7f7f",
            fontFamily: "Helvetica",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
          }
        }
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="Sin resultados"
        onChange={(event: any, newValue: PlaceType | null) => {
          if(!id) {
            setOptions(newValue ? [newValue, ...options] : options)
            setValue(newValue)
            handleSelect(newValue)
          } else {
            setOptions(newValue ? [newValue, ...options] : options)
            setValue(newValue)
            handleSelect(newValue)
            editingValueEdition(id, newValue)
          }
        }}
        onInputChange={(event, newInputValue) => {
          setAddress(newInputValue);
        }}
        disabled={isDisabled}
        renderInput={(params) => (
          <TextFielRegister {...params}
            sx={{ height: '55px' }}
            label="Calle principal y secundaria"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <p></p>
              )
            }} />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <GridGeneral container >
                <GridItem item>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </GridItem>
                <GridrespuestaItem item >
                  {parts.map((part, index) => (
                    <BoxResults
                      key={index}
                      // component="div"
                      text={part.text}
                      highlight={part.highlight}                    >
                      {part.text}
                    </BoxResults>
                  ))}
                  <TypographyName variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </TypographyName>
                </GridrespuestaItem>
              </GridGeneral>
            </li>
          );
        }}
      />
    </>
  ) : (
    <div>Loading Autocomplete...</div>
  );
}
export default AutocompleteGoogleMaps;