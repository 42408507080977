import { useState, useEffect, useCallback } from 'react'
import { Geolocation } from './Geolocation'
import { IAddressPatient, PlaceType, IAddressPatientAux } from '../../types'

interface ICoordinates {
  lat: number | undefined
  lng: number | undefined
}

const center: ICoordinates = {
  lat: -0.0102496,
  lng: -78.4464668
}
interface IMapsHook {
  loadCoordinates: () => void
  onValidationFocus: (index: number) => void
  validateInput: (name: string) => boolean
  //mapa
  markerPosition: google.maps.LatLng | null
  setMarkerPosition: React.Dispatch<
    React.SetStateAction<google.maps.LatLng | null>
  >
  address: any
  setAddress: React.Dispatch<any>
  setMapCenter: React.Dispatch<any>
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
  selected: boolean
  setZoom: React.Dispatch<React.SetStateAction<number>>
  zoom: number
  mapCenter: any
  optionsList: Array<string>
  // DATOS
  nameLocation: string
  setNameLocation: React.Dispatch<React.SetStateAction<string>>
  ValidationFocus: boolean[]
  //EVENTOS
  newAddressPatient: () => void
  eliminar: (value: any) => void
  modificar: (value: any) => void
  editar: (value: any, valor: string) => void
  addressEdition: (id: number, valor: any) => void
  value: PlaceType | null
  setValue: React.Dispatch<React.SetStateAction<PlaceType | null>>
}
interface IMapsProps {
  addressPatient: IAddressPatient[]
  setAddressPatient: React.Dispatch<React.SetStateAction<IAddressPatient[]>>
}

export function useMaps({
  addressPatient,
  setAddressPatient
}: IMapsProps): IMapsHook {
  const { coordinates, geolocationPosition } = Geolocation()
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLng | null>(null)
  const [zoom, setZoom] = useState(5) // initial zoom
  const [address, setAddress] = useState<string>('')
  const [selected, setSelected] = useState<boolean>(false)
  const [mapCenter, setMapCenter] = useState<any>(center)
  const [nameLocation, setNameLocation] = useState<string>('')
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([false])
  const [actionButton, setactionButton] = useState<boolean>(false)
  const [optionsList, setOptionsList] = useState<string[]>([
    'Casa',
    'Oficina',
    'Otra'
  ])
  const [value, setValue] = useState<PlaceType | null>(null)

  const loadCoordinates = (): void => {
    geolocationPosition()
    setactionButton(true)
  }
  const geolocationPositionCenter = () => {
    const lat: number = coordinates.latitud
    const lng: number = coordinates.longitud
    setMapCenter({
      lat: lat,
      lng: lng
    })
    setZoom(18)
    const latLng: google.maps.LatLng = new google.maps.LatLng(lat, lng)
    getAddressFromLatLng(latLng)
    setMarkerPosition(latLng)
    setSelected(true)
  }
  useEffect(() => {
    if (actionButton) {
      geolocationPositionCenter()
      setactionButton(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates])

  const eliminar = (value: any) => {
    const newList: IAddressPatient[] = addressPatient.filter(
      (item: any) => item !== value
    )
    setAddressPatient(newList)
    if (!optionsList.includes(value.name)) {
      setOptionsList([value.name, ...optionsList])
    }
  }
  const modificar = (value: any) => {
    const newList = addressPatient.map((item: any) => {
      if (item === value) {
        const updatedItem = {
          ...item,
          coordinates: {
            latitude: markerPosition?.lat().toString(),
            longitude: markerPosition?.lng().toString()
          },
          state: !item.state
        }

        return updatedItem
      }

      return item
    })

    setAddressPatient(newList)
  }

  const addressEdition = (id: number, valor: any) => {
    const newList = addressPatient.map((item: any) => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          value: valor,
          address: valor?.description || ''
        }

        return updatedItem
      }

      return item
    })

    setAddressPatient(newList)
  }

  const editar = (value: any, valor: string) => {
    const newList = addressPatient.map((item: any) => {
      if (item === value) {
        const updatedItem = {
          ...item,
          name: valor
        }

        return updatedItem
      }

      return item
    })

    setAddressPatient(newList)
  }

  const newAddressPatient = (): void => {
    let lat: number = 0
    let lng: number = 0
    if (
      markerPosition?.lat() !== undefined &&
      markerPosition?.lng() !== undefined
    ) {
      lat = markerPosition?.lat()
      lng = markerPosition?.lng()
    }
    const newOfficeMedical: IAddressPatientAux = {
      address: address,
      name: nameLocation,
      coordinates: {
        latitude: lat.toString(),
        longitude: lng.toString()
      },
      state: false,
      id: addressPatient.length + 1,
      value: value
    }
    setAddressPatient((prevAddressPatient) => [
      ...prevAddressPatient,
      newOfficeMedical
    ])

    if (
      nameLocation !== 'Otra' ||
      addressPatient.filter((a) => a.name === 'Otra').length >= 2
    ) {
      const newOptionsList: string[] = optionsList
      const optionIndex: number = newOptionsList.indexOf(nameLocation)
      newOptionsList.splice(optionIndex, 1)
      setOptionsList(newOptionsList)
    }

    setNameLocation('')
    setValidationFocus([false])
    setAddress('')
    setValue(null)
  }

  const validateInput = (name: string): boolean => {
    const regex = /^[a-zA-Z0-9]+$/
    return regex.test(name)
  }

  const getAddressFromLatLng = useCallback(
    async (latLng: google.maps.LatLng): Promise<void> => {
      try {
        const geocoder: google.maps.Geocoder = new google.maps.Geocoder()
        await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
          geocoder.geocode(
            { location: latLng },
            function (
              results: google.maps.GeocoderResult[] | null,
              status: google.maps.GeocoderStatus
            ) {
              if (status === 'OK') {
                setAddress(results == null ? '' : results[0].formatted_address)
              } else {
                reject(new Error("Couldnt't find the location " + address))
              }
            }
          )
        })
      } catch (error) {
        console.log('Error getting address from latlng', error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAddress]
  )

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]
    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  return {
    setZoom,
    zoom,
    loadCoordinates,
    onValidationFocus,
    validateInput,
    markerPosition,
    address,
    selected,
    setAddress,
    setMapCenter,
    setMarkerPosition,
    setSelected,
    mapCenter,
    nameLocation,
    setNameLocation,
    ValidationFocus,
    editar,
    eliminar,
    modificar,
    newAddressPatient,
    optionsList,
    addressEdition,
    value,
    setValue
  }
}
