import { FunctionComponent, ReactElement } from 'react'
import { ButtonGeneric } from './SecondaryMediumButtonStyled'
import { GridGeneric } from '../StyledComponents/GridGeneric'
import { SxProps } from '@mui/material'

interface ISecondaryMediumButtonProps {
    id: string
    text: string
    role?: string
    type: 'button' | 'submit' | 'reset' | undefined
    onClick?: () => void | Promise<void>
    children?: ReactElement<any, any>
    xs?: number
    fullWidth?: boolean
    sx?: SxProps
    variant?: 'text' | 'contained' | 'outlined'
    disabled?: boolean
    size?: "small" | "medium" | "large"
}

export const SecondaryMediumButton: FunctionComponent<ISecondaryMediumButtonProps> = ({
    text,
    id,
    role,
    type,
    onClick,
    children,
    xs = 6,
    fullWidth = true,
    sx,
    variant = 'contained',
    disabled = false,
    size='medium'
}) => {
    return (
        <GridGeneric item xs={xs}>
            <ButtonGeneric
                id={id}
                variant={variant}
                role={role}
                type={type}
                fullWidth={fullWidth}
                onClick={onClick}
                sx={sx}
                disabled={disabled}
                size={size}
            >
                { text }
                { children }
            </ButtonGeneric>
        </GridGeneric>
    )
}