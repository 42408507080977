import { DialogContent, DialogContentProps } from "@mui/material";
import React from "react";



const DialogContentWrapper: React.FC<DialogContentProps> = ({ ...props }) => (
  <DialogContent {...props} />
);

export default DialogContentWrapper;
