import React, { FunctionComponent } from 'react'
import logo from "../../assets/helthyLogo.png"

interface ILogoProps {}

export const Logo: FunctionComponent<ILogoProps> = () => {
  return (
    <img
      src={logo}
      width="200"
      height="70"
      alt=""
      id="LogoHelthy"
    />
  )
}
