import React, { useContext, useEffect } from 'react'
import AutocompleteComponent from '../../components/Autocomplete'
import { useSpecialties } from './Hooks/useSpecialties'
import { GridFullScreen } from '../../components/GridFullScreen/GridFullScreen'
import { Title } from '../../components/StyledComponents/Title'
import { GridPartialScreen } from '../../components/StyledComponents/GridPartialScreen'
import { GridWrapper, StackWrapper } from '../../components/wrapper'
import SubmitButton from '../../components/SubmitButton'
import { LabelInput } from '../../components/StyledComponents/LabelInput'
import { AccountContext, AuthContextType } from '../../contexts/AccountContext'
import { DoctorResults } from '../pages/DoctorResults/DoctorResults'
import FormTextInput from '../../components/FormInputText'
import CircularProgressWrapper from '../../components/wrapper/LoaderWrapper'
import { useSearchDoctor } from './Hooks/useSearchDoctor'
import Address from './components/Address'
import { FormHelperText } from '@mui/material'
import { SearchOfficesContext } from '../../contexts/SeachOfficesContext'
import Dates from '../pages/Dates/Dates'
import { useLocation } from 'react-router-dom'

function Appointment(): JSX.Element {
  const { patient } = useContext(AccountContext) as AuthContextType
  const { searchOffices } = useContext(SearchOfficesContext)
  const { listSpecialties } = useSpecialties()
  const { pathname } = useLocation()
  const {
    showResults,
    searching,
    submitted,
    results,
    pagination,
    specialty,
    address,
    addressData,
    doctorName,
    showAppointmentList,
    handleShowAppointmentList,
    handleShowResults,
    handleSubmit,
    handleChangePage,
    handleSearchDoctor,
    handleSubmitted,
    onChangeSpecialties,
    onChangeDoctorName,
    onChangeAddress,
    onChangeAddressData
  } = useSearchDoctor()

  useEffect(() => {
    if (pagination.page > 0) handleSubmitted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page])

  useEffect(() => {
    onChangeSpecialties(searchOffices.speciality)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleShowAppointmentList(pathname === '/dates')
    if(pathname === '/dates') {
      handleShowResults(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (submitted) handleSearchDoctor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted])

  return (
    <GridFullScreen id="appointment" direction="column">
      <GridPartialScreen
        container
        flexDirection={'column'}
        data-testid="profile-form"
      >
        <Title variant="h5">{`Hola ${patient.name}, empecemos a agendar tu cita`}</Title>
        <form onSubmit={handleSubmit}>
          <GridWrapper item xs={12} sx={{ mt: 2 }}>
            <StackWrapper
              spacing={{ xs: 1, sm: 1, md: 2 }}
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ marginBottom: 4 }}
            >
              <GridWrapper item xs={6} sx={{ width: '400px' }}>
                <LabelInput>Buscar médico</LabelInput>
                <FormTextInput
                  placeholder="Dr. Jon Doe"
                  id="doctor-name"
                  type="text"
                  fullWidth={true}
                  value={doctorName}
                  name="doctor-name"
                  onChange={(event) => onChangeDoctorName(event.target.value)}
                />
              </GridWrapper>
              <GridWrapper item xs={6}>
                <LabelInput>Buscar por especialidad</LabelInput>
                <AutocompleteComponent
                  id="component-appointment-list"
                  label=""
                  list={listSpecialties.map(s => s.option_value)}
                  setValue={onChangeSpecialties}
                  value={specialty}
                />
              </GridWrapper>
            </StackWrapper>
            <StackWrapper
              spacing={{ xs: 1, sm: 1, md: 2 }}
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ marginBottom: 4 }}
            >
              <GridWrapper item xs={6} sx={{ width: '400px' }}>
                <LabelInput>Tu dirección</LabelInput>
                <Address
                  address={address}
                  onChange={onChangeAddress}
                  setAddressData={onChangeAddressData}
                />
              </GridWrapper>
              <GridWrapper item xs={6}>
                <SubmitButton
                  dataTestid="search-button"
                  id="button"
                  type="submit"
                  variant="contained"
                  fullWidth
                  text="Buscar"
                  sx={{ marginTop: '7%' }}
                  disabled={
                    (!Boolean(doctorName) && !Boolean(specialty)) ||
                    (Boolean(address) &&
                      !Boolean(specialty) &&
                      !Boolean(doctorName)) ||
                    searching
                  }
                />
                {Boolean(address) &&
                  !Boolean(specialty) &&
                  !Boolean(doctorName) && (
                    <FormHelperText sx={{ color: 'red' }}>
                      Por favor ingresa la especialidad o nombre del médico para
                      continuar.
                    </FormHelperText>
                  )}
              </GridWrapper>
            </StackWrapper>
          </GridWrapper>
        </form>
      </GridPartialScreen>

      {searching && (
        <GridPartialScreen
          mt={5}
          px={20}
          display={'flex'}
          justifyContent={'center'}
        >
          <CircularProgressWrapper />
        </GridPartialScreen>
      )}
      {!searching && !showResults && showAppointmentList &&
        <GridPartialScreen mt={5} px={10}>
          <Dates></Dates>
        </GridPartialScreen>
      }
      {!searching && showResults && (
        <GridPartialScreen mt={5} px={20}>
          <DoctorResults
            results={results}
            pagination={pagination}
            handleChangePage={handleChangePage}
            addressData={addressData}
          />
        </GridPartialScreen>
      )}
    </GridFullScreen>
  )
}

export default Appointment
