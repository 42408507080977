import { styled } from '@mui/material'
import TypographyWrapper from '../wrapper/TypographyWrapper'

export const LabelInput = styled(TypographyWrapper)(() => ({
  textAlign: 'left',
  color: ' #000',
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.6875rem',
  marginLeft: '2rem'
}))
