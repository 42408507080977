import { AxiosError } from 'axios'
import { http } from '../Http'
import PendingTasksDataSource from '../dataSource/PendingTasksDataSource'
import { IApiPendingTasks, IPendingTasks } from '../../dtos'

export default class PendingTasksMapper implements PendingTasksDataSource {
  async getPendingTasks(user_id: string): Promise<IApiPendingTasks<IPendingTasks | string>> {
    try {
      let data = await http.get<IApiPendingTasks<IPendingTasks>>(`/send_data/pending_tasks_patient/${user_id}`)
      return data.data
    } catch (error: unknown) {
      const data: IApiPendingTasks<string> = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        }
      }
      return data
    }
  }
}
