import { GridWrapper } from '../wrapper'
import { ButtonSelectList } from '../../utils/RegisterMedicalFormStyles'
import { Answer as PostAnswer } from '../../infrastructure/dtos/SaveQualifications'
import { Answer } from '../../infrastructure/dtos/QualificationQuestions'

type AmountRangeProps = {
  amountRanges: Answer[]
  selectedAmountRange: PostAnswer
  onAmountRangeChange: (id: string) => void
}

const SelectButtonList = ({
  amountRanges,
  onAmountRangeChange,
  selectedAmountRange
}: AmountRangeProps) => {
  const val = (item: string): string => {
    return 'b' + item.split(' ').join('')
  }

  return (
    <>
      <GridWrapper direction="row" alignItems="center" justifyContent="center">
        {amountRanges?.map((item: Answer, index) => {
          return (
            <ButtonSelectList
              id={val(item.text)}
              key={index}
              sx={{
                backgroundColor:
                  selectedAmountRange.answer === item.id
                    ? '#6191fe'
                    : '#faf9fd',
                color:
                  selectedAmountRange.answer === item.id ? '#fff' : '#7f7f7f',
                width: '130px',
                padding: '0',
                ':hover': {
                  color: '#fff'
                }
              }}
              variant="contained"
              role="button"
              value={selectedAmountRange.answer}
              onClick={() => onAmountRangeChange(item.id)}
            >
              {item.text}
            </ButtonSelectList>
          )
        })}
      </GridWrapper>
    </>
  )
}

export default SelectButtonList
