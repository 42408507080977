import { GridProps } from '@mui/material'
import { GridWrapper } from '../wrapper'

export function GridItem(props: GridProps): JSX.Element {
  return <GridWrapper
    item
    textAlign={'center'}
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
    {...props}
  />
}