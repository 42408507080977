import { ReactElement } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { GridWrapper } from '../../../../../../../components'
import {
  AvatarStyled,
  ChevronLeftIconStyled,
  ChevronRightIconStyled,
  GridArrowLeftIcon,
  GridArrowRightIcon,
  ShowDoctorButtomnStyled,
  TypographyStyled,
  SmallAvatar
} from '../../styled'
import { USDollar } from '../../../../../../../utils/currency'
import { BadgeDoctorFriends, BadgeRatedDoctor } from '../../components/Badges'
import { PromotionChip } from '../../components/PromotionChip';
import { IParamsGetNextAppointmentHour } from '../../../../../../../infrastructure/dtos/Calendar'

export interface IRecommendedDoctorsProps {
  doctors: IDoctorProps[]
  getDoctorInfo: (officeId: string, doctor: IParamsGetNextAppointmentHour) =>  void
}

export interface IDoctorProps {
  id: string
  name_doctor: string
  photo_doctor: string
  advertised: boolean
  suggested: boolean
  rating: number
  price: number
  next_appointment_hour: string
  officeId: string
  specialty: string
  related_friends?: {
    name_friend?: string
    photo_friend?: string
    another_friends?: number
  }
}

export function CarouselFunction ({doctors, getDoctorInfo}: IRecommendedDoctorsProps): ReactElement<Carousel> {
  const onclickHandler = (clickHandler: () => void) => {
    if (doctors.length > 3) clickHandler()
  }

  return <Carousel
    showIndicators={false}
    showThumbs={false}
    centerMode={true}
    showStatus={false}
    centerSlidePercentage={33}
    width={"100%"}
    renderArrowPrev={(clickHandler) => (
      <GridArrowLeftIcon onClick={() => onclickHandler(clickHandler)}>
        <ChevronLeftIconStyled />
      </GridArrowLeftIcon>
    )}
    renderArrowNext={(clickHandler) => (
      <GridArrowRightIcon onClick={() => onclickHandler(clickHandler)}>
        <ChevronRightIconStyled />
      </GridArrowRightIcon>
    )}
    >
    {doctors.length === 0 ? [] : doctors.map((doctor: IDoctorProps) => (
      <GridWrapper key={doctor?.id}>
        <BadgeRatedDoctor
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          doctor={doctor}
        >
          <BadgeDoctorFriends
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <GridWrapper
                display="flex"
                color="#878787"
                alignItems="center"
              >
                <SmallAvatar
                  alt={doctor?.related_friends?.name_friend}
                  src={doctor?.related_friends?.photo_friend}
                />{" "}
                + 5
              </GridWrapper>
            }
          >
            <AvatarStyled src={doctor?.photo_doctor} />
          </BadgeDoctorFriends>
        </BadgeRatedDoctor>
        {(doctor?.suggested || doctor?.advertised) && (
          <GridWrapper item marginTop={"-25px"}>
            <PromotionChip doctor={doctor} />
          </GridWrapper>
        )}
        <GridWrapper
          display={"flex"}
          justifyContent={"center"}
          justifyItems={"center"}
          flexDirection={"column"}
        >
          <TypographyStyled data-testid='suggested-doctor-name'>
            Dr. {doctor?.name_doctor}
          </TypographyStyled>
          {doctor?.next_appointment_hour && <TypographyStyled color={"primary"}>
            {doctor?.next_appointment_hour + "h"}
          </TypographyStyled>}
          <TypographyStyled>
            {USDollar.format(doctor?.price)}
          </TypographyStyled>
          {!doctor?.next_appointment_hour && <TypographyStyled>
            <br/>
          </TypographyStyled>}
          <GridWrapper
            display={"grid"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <ShowDoctorButtomnStyled
              id={doctor?.id.toString()}
              role="button"
              variant="outlined"
              size="medium"
              data-testid="see-button"
              onClick={() => getDoctorInfo(doctor?.officeId, {
                user_id: doctor?.id,
                office_id: doctor?.officeId,
                specialty: doctor?.specialty
              })}
            >
              Ver
            </ShowDoctorButtomnStyled>
          </GridWrapper>
        </GridWrapper>
      </GridWrapper>
    ))}
  </Carousel>}