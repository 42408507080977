import React, { FunctionComponent } from "react";
import { StackWrapper } from "../wrapper";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { LinkTab } from "../StyledComponents/LinkTab";
import { ButtonBack } from "../StyledComponents/ButtonBack";

interface IComponentProps {
    id: string,
    text: string,
    onClick?: () => void
}
export const BackButton: FunctionComponent<IComponentProps> = (
    { text, id, onClick }
) => {
    return (
        <StackWrapper
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="left"
            sx={{
                width: '100%',
                padding: 1,
                textAlign: 'left',
                marginTop: "5%"
            }}
        >
            <ButtonBack
                onClick={onClick}
                variant="contained"
            >
                <ChevronLeftIcon />
            </ButtonBack>
            <LinkTab
                onClick={onClick}
                color="inherit"
                id={id}
            >
                {text}
            </LinkTab>
        </StackWrapper>
    )
};