
import {
  IAppointmentInfoResponse,
  IAppointmentStatus,
  IConfirmAppointmentProps,
  INextAppointmentResponse,
  IPastAppointmentResponse
} from "../../../infrastructure/dtos/Appointments"
import {
  IAppointmentService,
  confirmAppointmentService,
  nextAppointmentsService,
  IGetAppointmentInfoService, 
  getAppointmentInfoService,
  pastAppointmentsService
} from "../Persistencia/AppointmentsService"

export interface IConfirmAppointmentUtility {
  status: number
  data: IAppointmentStatus | string
}

export interface INextAppointmentsUtility {
  status: number
  data: INextAppointmentResponse | string
}


export interface IGetAppointmentUtility<T> {
  status: number,
  data: string | T
}

export interface IPastAppointmentsUtility {
  status: number
  data: IPastAppointmentResponse | string
}

export async function confirmAppointmentUtility(
  params: IConfirmAppointmentProps
): Promise<IConfirmAppointmentUtility> {
  try {
    const { data, status }: IAppointmentService<IAppointmentStatus | string>
      = await confirmAppointmentService(params)
    return {
      status: data.statusCode === 200 && status ? status : 0,
      data: data.body
    }
  } catch (error) {
    return {
      status: 0,
      data: error as string
    }
  }
}

export async function getAppointmentInfoUtility(
  appointmentId: string
): Promise<IGetAppointmentUtility<IAppointmentInfoResponse>> {
  try {
    const { data, status }: IGetAppointmentInfoService =
      await getAppointmentInfoService(appointmentId)
    return {
      data: data.body,
      status: data.statusCode === 200 && status === 1 ? status : 0
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error)?.message
    }
  }
}

export async function nextAppointmentsUtility(
  userId: string
): Promise<INextAppointmentsUtility> {
  try {
    const { data, status }: IAppointmentService<INextAppointmentResponse | string>
      = await nextAppointmentsService(userId)
    return {
      status: data.statusCode === 200 && status ? status : 0,
      data: data.body
    }
  } catch (error) {
    return {
      status: 0,
      data: error as string
    }
  }
}

export async function pastAppointmentsUtility(
  userId: string
): Promise<IPastAppointmentsUtility> {
  try {
    const { data, status }: IAppointmentService<IPastAppointmentResponse | string>
      = await pastAppointmentsService(userId)
    return {
      status: data.statusCode === 200 && status ? status : 0,
      data: data.body
    }
  } catch (error) {
    return {
      status: 0,
      data: error as string
    }
  }
}

