import { useEffect, useState } from 'react'
import { ILocationDoctorProps } from '../../types'
import Marker from '../Marker'
import { Badge } from '@mui/material'
import { AvatarMap, DrawerMap } from './styles'
import { ISearchDoctor } from '../../../../../infrastructure/dtos/DoctorInfo'
import { GridWrapper } from '../../../../../components'
import DoctorDetailsRightPanel from '../../../DoctorDetailsRightPanel'
import { IParamsGetNextAppointmentHour } from '../../../../../infrastructure/dtos/Calendar'
import { IAddressPatient } from '../../../../Register/types'

export interface IDataLocation {
  officeId?: string
  userId?: string
  specialty?: string
  name: string
  position: {
    lat: number
    lng: number
  }
  address: string
  price: string
  rating: string
  type: string
  photo?: string
}

export default function LocationDoctor({
  map,
  results,
  selectedPatientAddress
}: ILocationDoctorProps) {
  const [showDoctorDetails, setShowDoctorDetails] = useState<boolean>(false)
  const [selectedOffice, setSelectedOffice] = useState<
    IParamsGetNextAppointmentHour | undefined
  >(undefined)
  const [data, setData] = useState<IDataLocation[]>([])

  useEffect(() => {
    const xLocation = results?.reduce(
      (acc: IDataLocation[], result: ISearchDoctor) => {
        result?.medical_office?.map((item) => {
          acc.push({
            officeId: item.office_id,
            userId: result.user_id,
            specialty: result.specialty_doctor,
            name: result.name_doctor,
            position: {
              lat: Number(item.coordinates_medical_office.latitude),
              lng: Number(item.coordinates_medical_office.longitude)
            },
            address: item.name_medical_office,
            photo: result.photo_doctor,
            price: '100$',
            rating: result.rating?.toString() as string,
            type: 'paid'
          })
          return item
        })
        return acc
      },
      []
    )

    setData(xLocation as IDataLocation[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleDrawer = (
    open: boolean,
    location: IDataLocation | undefined
  ): void => {
    setShowDoctorDetails(open)
    setSelectedOffice({
      office_id: location?.officeId as string,
      user_id: location?.userId as string,
      specialty: location?.specialty
    })
  }

  return (
    <>
      {Boolean(selectedPatientAddress) && (
        <Marker
          key={'patientPosition'}
          map={map}
          position={{
            lat: Number(selectedPatientAddress?.coordinates.latitude),
            lng: Number(selectedPatientAddress?.coordinates.longitude)
          }}
          testId="patient-locate"
          onClick={() => {}}
          children={
            <GridWrapper
              sx={{
                width: 20,
                height: 20,
                backgroundColor: 'primary.main',
                borderRadius: 50
              }}
            />
          }
        />
      )}
      {data?.map((location, key) => {
        return (
          <Marker
            key={key}
            map={map}
            position={location.position}
            onClick={() => toggleDrawer(true, location)}
            testId="doctor-locate"
          >
            <AvatarMap
              src={location.photo}
              data-testid="doctor-img-clickable"
            ></AvatarMap>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              badgeContent={location.rating}
              color="secondary"
              sx={{
                '& .MuiBadge-badge': {
                  right: -25,
                  top: -40,
                  borderRadius: '50%',
                  width: 20,
                  height: 20,
                  backgroundColor: '#F79D25',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                }
              }}
            />
          </Marker>
        )
      })}
      <DrawerMap
        anchor={'right'}
        open={showDoctorDetails}
        onClose={() => toggleDrawer(false, undefined)}
        hideBackdrop
      >
        <DoctorDetailsRightPanel
          toggleDrawer={toggleDrawer}
          doctor={selectedOffice as IParamsGetNextAppointmentHour}
          selectedPatientAddress={selectedPatientAddress as IAddressPatient}
        />
      </DrawerMap>
    </>
  )
}
