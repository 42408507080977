import React, { useContext, useEffect } from 'react';
import './App.css';
import { AccountContext, AuthContextType } from './contexts/AccountContext';
import { AppRouter } from './AppRouter';
import { Loading } from './components/Loading/Loading';
import { useLocation } from 'react-router-dom';

function App() {
  const { status, handleAlert } = useContext(AccountContext) as AuthContextType
  const { state } = useLocation()
  useEffect(() => {
    if (state) {
      handleAlert(true, state.message, state.success ? 'success' : 'info')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, state])
  
  return (
    <>
      { status === 'checking'
        ? <div style={{ width: '100vw', height: '100vh' }}>
          <Loading />
        </div>
        : <AppRouter />
      }
    </>
  );
}

export default App;
