import { Button, ButtonProps } from "@mui/material";
import React from "react";



const ButtonWrapper: React.FC<ButtonProps> = ({ ...props }) => (
  <Button {...props} />
);

export default ButtonWrapper;
