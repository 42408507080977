import { AxiosError } from 'axios'
import {
  IApiResponsePhoneVerification,
  IPhoneVerification,
  IResendCodeParams,
  IVerifyCodeParams,
  IUpdatePatientPhoneParams
} from '../../dtos/PhoneVerification'
import { http } from '../Http'
import PhoneVerificationDataSource from '../dataSource/PhoneVerificationDataSource'

export default class PhoneVerificationMapper
  implements PhoneVerificationDataSource
{
  async resendCode({
    userId,
    type
  }: IResendCodeParams): Promise<
    IApiResponsePhoneVerification<IPhoneVerification>
  > {
    try {
      let data = await http.get<
        IApiResponsePhoneVerification<IPhoneVerification>
      >(
        `/send_data/resend_code_verification_phone?type=${type}&user_id=${userId}`
      )
      return data.data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).message as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }

  async sendCode(
    userId: string
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    try {
      let data = await http.post<
        IApiResponsePhoneVerification<IPhoneVerification>
      >('/patient/send_phone_verification_code', {
        body: {
          user_id: userId
        }
      })
      return data.data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).message as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }

  async verifyPhoneValidation(
    userId: string
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    try {
      let data = await http.get<
        IApiResponsePhoneVerification<IPhoneVerification>
      >(`/send_data/patient_phone_verification_status/${userId}`)
      return data.data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).message as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }

  async verifyCode({
    userId,
    code
  }: IVerifyCodeParams): Promise<
    IApiResponsePhoneVerification<IPhoneVerification>
  > {
    try {
      let data = await http.post<
        IApiResponsePhoneVerification<IPhoneVerification>
      >('/patient/send_code_to_verify', {
        body: {
          user_id: userId,
          code: code
        }
      })
      return data.data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).message as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }

  async updatePatientPhone({
    userId,
    phoneNumber
  }: IUpdatePatientPhoneParams): Promise<
    IApiResponsePhoneVerification<string>
  > {
    try {
      let data = await http.post<IApiResponsePhoneVerification<string>>(
        '/save_data/patient_phone',
        {
          body: {
            user_id: userId,
            number_phone_patient: phoneNumber
          }
        }
      )
      return data.data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).message as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }
}
