import { GoogleMap, Marker } from '@react-google-maps/api'
import { useCallback } from 'react'
export interface IMapPageFormProps {}
const containerStyle = {
  width: '90%',
  height: '40vh'
}
interface ICoords {
  lat?: number
  lng?: number
}

interface IPropsMap {
  map: google.maps.Map | null
  markerPosition: google.maps.LatLng | null
  zoom: number
  address: string
  mapCenter: google.maps.LatLng | google.maps.LatLngLiteral | undefined
  setMap: React.Dispatch<React.SetStateAction<google.maps.Map | null>>
  setMarkerPosition: React.Dispatch<
    React.SetStateAction<google.maps.LatLng | null>
  >
  setZoom: React.Dispatch<React.SetStateAction<number>>
  setAddress: React.Dispatch<React.SetStateAction<string>>
  setMapCenter: React.Dispatch<React.SetStateAction<any>>
  setSelected: React.Dispatch<React.SetStateAction<any>>
  isLoaded: boolean
}
export const MapsGoogle: React.FC<IPropsMap> = (props: IPropsMap) => {
  const {
    address,
    isLoaded,
    map,
    mapCenter,
    setSelected,
    markerPosition,
    setAddress,
    setMap,
    setMapCenter,
    setMarkerPosition,
    setZoom,
    zoom
  } = props

  const handleMapDrag = (): void => {
    if (map) {
      const newCenter: ICoords = {
        lat: map.getCenter()?.lat(),
        lng: map.getCenter()?.lng()
      }
      setMapCenter(newCenter)
      setZoom(map.getZoom()!)
    }
  }

  const onLoad = (map: google.maps.Map): void => {
    setMap(map)
  }

  const onClick = (e: google.maps.MapMouseEvent): void => {
    // avoid directly mutating state
    setMarkerPosition(e.latLng!)
    getAddressFromLatLng(e.latLng!)
    setSelected(true)
  }

  const getAddressFromLatLng = useCallback(
    async (latLng: google.maps.LatLng): Promise<void> => {
      try {
        const geocoder = new google.maps.Geocoder()
        await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
          geocoder.geocode({ location: latLng }, function (results, status) {
            if (status === 'OK') {
              setAddress(results == null ? '' : results[0].formatted_address)
            } else {
              reject(new Error("Couldnt't find the location " + address))
            }
          })
        })
      } catch (error) {
        console.log('Error getting address from latlng', error)
      }
    },
    [setAddress]
  )

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={zoom}
        onClick={onClick}
        onLoad={onLoad}
        onDragEnd={handleMapDrag}
        onZoomChanged={handleMapDrag}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </>
  ) : (
    <div>Loading Map...</div>
  )
}

export default Map
