import { AxiosError } from 'axios'

import {
  IApiAppointment,
  IAppointmentInfoResponse,
  IAppointmentStatus,
  IConfirmAppointmentProps,
  INextAppointmentResponse,
  IPastAppointmentResponse
} from '../../dtos/Appointments'

import { http } from '../Http'
import AppointmentsDataSource from '../dataSource/AppointmentsDataSource'

export default class AppointmentsMapper implements AppointmentsDataSource {
  async confirmAppointment({
    appointment_id,
    action,
    reschedule_id
  }: IConfirmAppointmentProps): Promise<
    IApiAppointment<IAppointmentStatus | string>
  > {
    try {
      const { data } = await http.post<IApiAppointment<IAppointmentStatus>>(
        `/save_data/appointment_status/${appointment_id}`,
        { action, reschedule_id }
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).message as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }

  async getAppointmentInfo(
    appointmentId: string
  ): Promise<IApiAppointment<IAppointmentInfoResponse | string>> {
    try {
      const { data } = await http.get<
        IApiAppointment<IAppointmentInfoResponse>
      >(`send_data/appointment_info/${appointmentId}`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: (error as AxiosError)?.response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Credentials': false
        }
      }
    }
  }

  async nextAppointments(
    userId: string
  ): Promise<IApiAppointment<INextAppointmentResponse | string>> {
    try {
      const { data } = await http.get<
        IApiAppointment<INextAppointmentResponse>
      >(`/send_data/next_patient_appointments/${userId}`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).message as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }

  async pastAppointments(
    userId: string
  ): Promise<IApiAppointment<IPastAppointmentResponse | string>> {
    try {
      const { data } = await http.get<
        IApiAppointment<IPastAppointmentResponse>
      >(`/send_data/past_patient_appointments/${userId}`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).message,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }
}
