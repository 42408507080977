import { Star, TrendingUp } from "@mui/icons-material";
import { IDoctorProps } from '../../components/Carousel'
import { ChipWrapper } from '../../../../../../../components'

interface IPromotionChipProps {
  doctor: IDoctorProps,
}

export function PromotionChip({
  doctor,
}: IPromotionChipProps): JSX.Element {
  return (
    <ChipWrapper
      sx={{
        borderRadius: 2,
        backgroundColor: doctor.suggested
          ? "secondary.main"
          : "#9FBCFF",
        color: "background.paper",
        fontWeight: "bold",
      }}
      size="small"
      icon={
        doctor.suggested ? (
          <Star color="inherit" />
        ) : (
          <TrendingUp color="inherit" />
        )
      }
      label={doctor.suggested ? "Recomendado" : "Anuncio"}
    />
  );
}
