import React, { useState } from 'react'
import { GridFullScreen } from '../../components/GridFullScreen/GridFullScreen'
import { GridHalfScreen } from '../../components/StyledComponents/GridHalfScreen'
import RegisterMedicalForm from './RegisterAuth/RegisterPatientForm'
import { Tabs } from '@mui/material'
import imagen from '../../assets/img/image.png'
import { LoginPageForm } from './LoginAuth/LoginPageForm'
import { GridWrapper } from '../../components/wrapper'
import { AntTab } from './styles/auth'

const AuthPage = () => {
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      <GridFullScreen id="ScreenLogin">
        <GridHalfScreen>
          <img src={imagen} width="100%" height="100%" alt="" />
        </GridHalfScreen>
        <GridHalfScreen>
          <GridWrapper container justifyContent="center">
            <Tabs value={value} onChange={handleChange}>
              <AntTab label="Inicia sesión" />
              <AntTab label="Regístrate" />
            </Tabs>
            {value === 0 && <LoginPageForm />}
            {value === 1 && <RegisterMedicalForm />}
          </GridWrapper>
        </GridHalfScreen>
      </GridFullScreen>
    </>
  )
}

export default AuthPage
