import { PatientInfoRepository } from "../../domain/repository/PatientRepository";
import { IApiResponsePatientInfo } from "../dtos/Patient";
import PatientInfoDataSource from "../http/dataSource/PatientDataSource";

export class PatientInfoRepositoryImpl implements PatientInfoRepository {
  dataSource: PatientInfoDataSource;

  constructor(_dataSource: PatientInfoDataSource) {
    this.dataSource = _dataSource;
  }

  async getPatientInfo(
    idPatient: string
  ): Promise<IApiResponsePatientInfo> {
    return this.dataSource.getPatientInfo(idPatient);
  }
}
