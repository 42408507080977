import React from 'react'
import NextDatesList from './components/NextDatesList/NextDatesList'
import { PastAppointmentsList } from './components/PastMedicalAppointmentsList/PastAppointmentsList'

function Dates() {
  return (
    <>
      <NextDatesList fromHome={false} />
      <PastAppointmentsList />
    </>
  )
}

export default Dates
