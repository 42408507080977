import { styled } from '@mui/material'
import { BoxWrapper } from '../../components/wrapper'

export const HomeBox = styled(BoxWrapper)(() => ({
  backgroundColor: 'white',
  marginRight: '30%',
  borderRadius: '30px',
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
  minWidth: '250px',
  maxWidth: '300px',
  height: '500px',
  marginTop: '24px'
}))

export const SmallHomeBox = styled(BoxWrapper)(() => ({
  backgroundColor: 'white',
  marginRight: '30%',
  borderRadius: '30px',
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
  minWidth: '250px',
  maxWidth: '300px',
  height: '170px',
  marginTop: '15%'
}))

export const CommunityHomeBox = styled(BoxWrapper)(() => ({
  backgroundColor: 'white',
  marginRight: '30%',
  borderRadius: '30px',
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
  minWidth: '300px',
  maxWidth: '400px',
  minHeight: '300px',
  maxHeight: '400px',
  marginTop: '10%'
}))
