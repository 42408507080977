import { ButtonWrapper } from "../wrapper";
import { styled } from "@mui/material/styles";

export const ButtonBack = styled(ButtonWrapper)(() => ({
    borderRadius: '4rem',
    backgroundColor: '#faf9fd',
    color: '#3850b2',
    textTransform: 'none',
    width: '0rem',
    height: '3rem',
    marginLeft: '20%'
}))