import {
  IServiceSpecialties,
  IUtilitySpecialties
} from '../../../infrastructure/dtos'
import { SpecialtiesService } from '../Persistencia/SpecialtiesService'

export async function GetSpecialtiesUtility(): Promise<IUtilitySpecialties> {
  const dataReturnError: IUtilitySpecialties = {
    data: [],
    status: 0
  }
  try {
    const valueData: IServiceSpecialties = await SpecialtiesService()
    if (
      typeof valueData?.data !== 'string' &&
      valueData?.data.statusCode === 200 &&
      valueData?.status === 1
    ) {
      return {
        data: valueData?.data.body.specialties,
        status: valueData?.status
      }
    } else {
      return dataReturnError
    }
  } catch (error) {
    return dataReturnError
  }
}
