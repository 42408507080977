import { useContext, useEffect, useState } from 'react'
import { MedicalOffice } from '../../../../infrastructure/dtos/Offices'
import { IServiceResponse } from '../../../../services/Contracts/Persistencia/InterfaceServiceResponse'
import { OfficesService } from '../../../../services/Contracts/Persistencia/OfficesService'
import { GridWrapper, MenuItemWrapper } from '../../../../components'
import { SelectOffice, TypographyWrapperLabel } from './OfficeListStyle'
import { SelectChangeEvent } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MapIcon from '@mui/icons-material/Map'
import { PatientAppointmentContext } from '../../../../contexts/PatientAppointmentContext'

interface OfficeListProps {
  handleChangeOffice: (event: SelectChangeEvent<unknown>) => void
  office: string
  idDoctor: string
}

export const OfficeList = ({
  handleChangeOffice,
  office,
  idDoctor
}: OfficeListProps): JSX.Element => {
  const [list, setList] = useState<MedicalOffice[]>([])
  const [selected, setSelected] = useState<MedicalOffice>()
  const { PatientAppointment, saveScheduleSelected } = useContext(
    PatientAppointmentContext
  )

  useEffect(() => {
    const fetchOffices = async (): Promise<void> => {
      try {
        const valores: IServiceResponse = await OfficesService(
          idDoctor as string
        )
        if (valores?.data?.body) {
          setList(valores?.data?.body.medical_offices)
        }
      } catch (error) {
        console.log('Error fetching offices:', error)
      }
    }
    fetchOffices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    saveScheduleSelected({
      ...PatientAppointment,
      office: selected as MedicalOffice
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const onClickMap = () => {
    const url = `https://www.google.com/maps?q=${selected?.coordinates_medical_office.latitude},${selected?.coordinates_medical_office.longitude}`
    window.open(url, '_blank')
  }

  return (
    <>
      <TypographyWrapperLabel>
        Selecciona el lugar de atención
      </TypographyWrapperLabel>
      <GridWrapper container={true}>
        <GridWrapper item sm={11}>
          <SelectOffice
            name="SelectOffice"
            labelId="selectInputLabel"
            data-testid="select-office"
            id="SelectOffice"
            value={office}
            onChange={handleChangeOffice}
            renderValue={(selected: unknown) => {
              const selectedOffice = list.find(
                (item) => item.office_id === selected
              )
              setSelected(selectedOffice)
              return selectedOffice
                ? selectedOffice.reference_medical_office
                : ''
            }}
            defaultValue={''}
          >
            {list?.length > 0 &&
              list?.map((item: MedicalOffice, index) => (
                <MenuItemWrapper
                  key={index}
                  defaultValue={office}
                  value={item.office_id}
                >
                  {item.reference_medical_office}
                </MenuItemWrapper>
              ))}
          </SelectOffice>
        </GridWrapper>
        <GridWrapper item sm={1}>
          {selected && (
            <IconButton
              color="primary"
              aria-label="Search Location"
              onClick={() => {
                onClickMap()
              }}
            >
              <MapIcon />
            </IconButton>
          )}
        </GridWrapper>
      </GridWrapper>
    </>
  )
}
