import { styled } from '@mui/material'
import { GridWrapper, TypographyWrapper } from '../../../components/wrapper'

export const SubTitle = styled(TypographyWrapper)(() => ({
  textAlign: 'left',
  color: ' #000',
  fontSize: '12px',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.3rem'
}))

export const ListTitle = styled(TypographyWrapper)(() => ({
  color: 'grey',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  margin: '15px 0'
}))

export const ListContent = styled(TypographyWrapper)(() => ({
  color: '#000',
  fontWeight: 'bold',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  margin: '10px 0',
  width: '200px',
  marginLeft: 'auto',
  textAlign: 'right'
}))

export const GridList = styled(GridWrapper)(() => ({
  display: 'flex'
}))

export const TypoDetailsTitle = styled(TypographyWrapper)(() => ({
  fontSize: 10,
  textAlign: 'center'
}))

export const TypoDetailsInfo = styled(TypographyWrapper)((props) => ({
  fontSize: 15,
  color: props.theme.palette.primary.dark,
  fontWeight: 'bold',
  textAlign: 'center'
}))
