import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './contexts/AccountContext'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { createTheme, ThemeProvider } from '@mui/material'
import { PatientAppointmentProvider } from './contexts/PatientAppointmentContext'

// Change global locale date in spanish
import dayjs from 'dayjs'
import es from 'dayjs/locale/es'
import { SearchOfficesProvider } from './contexts/SeachOfficesContext'
import App from './App'

dayjs.locale(es)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const PatientAppointmentState = ({ children }: any) => {
  return <PatientAppointmentProvider>{children}</PatientAppointmentProvider>
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#6191FE',
      dark: '#3850B2'
    },
    secondary: {
      main: '#F79D25'
    },
    background: {
      default: '#FAFAFA',
      paper: '#FAF9FD'
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: '13px'
        },
        h5: {
          fontSize: '14px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standard: {
          alignItems: 'center',
          minWidth: '400px',
          height: '60px',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: 'normal',
          borderRadius: '19px'
        },
        standardSuccess: {
          backgroundColor: '#DEF4D3',
          color: '#75C44C'
        }
      }
    }
  }
})

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <PatientAppointmentState>
            <SearchOfficesProvider>
              <App />
            </SearchOfficesProvider>
          </PatientAppointmentState>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
