import { styled } from '@mui/material/styles'
import {
  AvatarWrapper,
  ButtonWrapper,
  CheckboxWrapper,
  FormControlLabelWrapper,
  LinkWrapper,
  TextFieldWrapper,
  TypographyWrapper
} from '../components'
import FormControlLabelRadioButtonsrapper from '../components/wrapper/FormControlLabelRadioButtonsWrapper'
import GridWrapper from '../components/wrapper/GridWrapper'
import DialogTitleWrapper from '../components/wrapper/DialogTitleWrapper'

export const ButtonLogin = styled(ButtonWrapper)((props) => ({
  borderRadius: '2.625rem',
  backgroundColor: props.theme.palette.primary.dark,
  fontSize: '1.125rem',
  height: '4.0625rem',
  textTransform: 'none'
}))

export const ButtonCorreo = styled(ButtonWrapper)((props) => ({
  width: '95%',
  borderRadius: '2.625rem',
  backgroundColor: props.theme.palette.primary.main,
  fontSize: '0.75rem',
  height: '45px',
  textTransform: 'none'
}))

export const TextFielRegister = styled(TextFieldWrapper)(() => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    borderRadius: '3.4375rem',
    backgroundColor: '#fff',
    fontSize: '1.125rem',
    color: '#7f7f7f',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '3.4375rem'
  },
  '& fieldset': { borderColor: 'white' },
  '&::placeholder': {
    marginLeft: '1.875rem'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: 'white'
    }
  }
}))

export const TextFielRegisterArea = styled(TextFieldWrapper)(() => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    borderRadius: '3.4375rem',
    backgroundColor: '#fff',
    fontSize: '1.125rem',
    color: '#7f7f7f',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '7.4375rem'
  },
  '& fieldset': { borderColor: 'white' },
  '&::placeholder': {
    marginLeft: '1.875rem'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: 'white'
    }
  }
}))

export const CheckboxRegister = styled(CheckboxWrapper)(() => ({
  borderRadius: '0.5rem'
}))

export const LinkWrapperAuth = styled(LinkWrapper)((props) => ({
  color: props.theme.palette.primary.dark,
  fontSize: '1.125rem',
  cursor: 'pointer'
}))

export const FormControlLabelWrapperAuth = styled(FormControlLabelWrapper)(
  () => ({
    color: ' #7f7f7f',
    fontSize: '0.875rem',
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left'
  })
)

export const TypographyWrapperAuth = styled(TypographyWrapper)((props) => ({
  color: props.theme.palette.primary.dark,
  fontSize: '20px',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center'
}))

export const TypographyWrapperAuthTitulo = styled(TypographyWrapper)(() => ({
  color: ' #000',
  fontSize: '1.75rem',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal'
}))

export const TypographyWrapperAuthLabel = styled(TypographyWrapper)(() => ({
  textAlign: 'left',
  color: ' #000',
  fontSize: '1.125rem',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.6875rem',
  marginLeft: '2rem'
}))

export const AvatarProfileWrapper = styled(AvatarWrapper)(() => ({
  width: '120px',
  height: '120px'
}))

export const TypographyWrapperValidationTrue = styled(TypographyWrapper)(
  () => ({
    color: ' #75c44c',
    fontSize: '1rem',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center'
  })
)
export const TypographyWrapperValidationFalse = styled(TypographyWrapper)(
  () => ({
    color: ' #7d7d7d',
    fontSize: '1rem',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  })
)

export const TypographyWrapperValidationRed = styled(TypographyWrapper)(() => ({
  color: ' #d32f2f',
  fontSize: '12px',
  textAlign: 'left'
}))

export const RadioButtonsSelet = styled(FormControlLabelRadioButtonsrapper)(
  (props) => ({
    '&.MuiFormLabel-root': {
      width: '248px',
      borderRadius: '42px',
      backgroundColor: props.theme.palette.primary.main,
      fontSize: '11px',
      height: '45px'
    }
  })
)

export const ButtonSelect = styled(ButtonWrapper)((props) => ({
  borderRadius: '42px',
  backgroundColor: '#faf9fd',
  fontSize: '13px',
  color: props.theme.palette.primary.dark,
  textTransform: 'none',
  width: '200px',
  height: '70px',
  margin: '10px'
}))

export const ReturnButton = styled(ButtonWrapper)((props) => ({
  width: '66px',
  height: '66px',
  color: props.theme.palette.primary.dark,
  margin: '19px 17px 45px 19px',
  padding: '16px 15px 15px 16px',
  borderRadius: '33px',
  boxShadow: '2px 2px 8px 4px rgba(0, 0, 0, 0.05)',
  backgroundColor: '#fff'
}))

export const ButtonSelectList = styled(ButtonWrapper)((props) => ({
  borderRadius: '42px',
  backgroundColor: '#faf9fd',
  fontSize: '13px',
  color: props.theme.palette.primary.dark,
  textTransform: 'none',
  width: '180px',
  height: '60px',
  margin: '10px'
}))



export const TypographyTituloBanner = styled(TypographyWrapper)(() => ({
  mb: 1,
  color: 'white',
  fontSize: '62px'
}))

export const GridBanner = styled(GridWrapper)((props) => ({
  width: '100%',
  backgroundColor: props.theme.palette.primary.main,
  padding: '60px 835px 70px 836px',
  textAlign: 'center'
}))

export const GridBody = styled(GridWrapper)((props) => ({
  backgroundColor: props.theme.palette.background.default
}))

export const GridBodyContaniner = styled(GridWrapper)((props) => ({
  width: '57.6875rem',
  backgroundColor: props.theme.palette.background.default,
  padding: 3,
  borderRadius: 2
}))

export const DialogTitleStyle = styled(DialogTitleWrapper)(() => ({
  cursor: 'move'
}))

export const LoginContentsStyle = styled(GridWrapper)(() => ({
  display: 'flex',
  justifyContent: 'center'
}))

export const RedirectLoginContentStyle = styled(GridWrapper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '0.625rem'
}))

export const TypographyLabelContentsStyle = styled(GridWrapper)(() => ({
  display: 'flex',
  justifyContent: 'center'
}))

export const TypographyLabel = styled(TypographyWrapper)(() => ({
  color: ' #000',
  fontSize: '1.25rem',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal'
}))
