import {
  ISaveRegisterPatient,
  IApiResponseRegisterPatient
} from '../../dtos/PatientRegister'
import { http } from '../Http'
import PatientRegisterDataSource from '../dataSource/PatientRegisterDataSource'

export default class PatientRegisterMapper
  implements PatientRegisterDataSource
{
  async postRegisterPatient(
    data: ISaveRegisterPatient
  ): Promise<IApiResponseRegisterPatient> {
    let result = await http
      .post(`save_data/register_patient`, { body: data })
      .then((response) => {
        const { data: resultResponse } = response
        return resultResponse
      })
      .catch((error) => {
        const resultResponse = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': ''
          }
        }
        return resultResponse
      })
    return result
  }
}
