import React, { FunctionComponent } from "react";
import { GridGeneric } from "../StyledComponents/GridGeneric";
import { LinkTab } from "../StyledComponents/LinkTab";

interface ITabLinkProps {
    id: string,
    color: string,
    onClick?: () => void,
    text: string
}

export const TabLink: FunctionComponent<ITabLinkProps> = (
    { onClick, id, color, text }
) => {

    return (
        <GridGeneric item xs={6} >
            <LinkTab
                onClick={onClick}
                color={color}
                id={id}
            >
                {text}
            </LinkTab>
        </GridGeneric>
    )
};