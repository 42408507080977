import React, { FunctionComponent } from "react";
import { RadioButtonUncheckedOutlinedIcon } from "../icons";
import { TypographyWrapperValidationFalse, TypographyWrapperValidationTrue } from "./RadioButtonValidationStyle";

interface IComponentProps {
    text: string,
    validation: boolean,
    id: string
}

export const RadioButtonValidation: FunctionComponent<IComponentProps> = (
    { text, validation, id }
) => {


    return (
        <>
            {
                validation ? (
                    <TypographyWrapperValidationTrue id={id + "Active"}>
                        <RadioButtonUncheckedOutlinedIcon />{text}
                    </TypographyWrapperValidationTrue >
                ) : (
                    <TypographyWrapperValidationFalse id={id + "Desactive"}>
                        <RadioButtonUncheckedOutlinedIcon /> {text}
                    </TypographyWrapperValidationFalse>
                )}
        </>
    )
};