import {
  IApiResponseRegisterPatient,
  ISaveRegisterPatient,
} from "../../infrastructure/dtos/PatientRegister";
import { PatientRegisterRepository } from "../repository/PatientRegisterRepository";

export interface PostRegisterPatientUseCase {
  invoke: (
    data: ISaveRegisterPatient
  ) => Promise<IApiResponseRegisterPatient>;
}

export class PostRegisterPatient implements PostRegisterPatientUseCase {
  private objectUseCase: PatientRegisterRepository;
  constructor(_objectUseCase: PatientRegisterRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(
    data: ISaveRegisterPatient
  ): Promise<IApiResponseRegisterPatient> {
    return this.objectUseCase.postRegisterPatient(data);
  }
}
