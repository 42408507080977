import { GridWrapper, TypographyWrapper } from '../../../../components'
import { GridHours, HoursButton } from './AvailableHoursStyles'
import { IAvailableHours } from '../../../../infrastructure/dtos/Calendar'

interface IAvailableHoursProps {
  availableHours: IAvailableHours
  selectedHour: string
  isOptionDisabled?(hour: string): boolean,
  handleSelectedHour: (event: React.MouseEvent<HTMLElement>) => void
}

export const AvailableHours: React.FC<IAvailableHoursProps> = ({
  availableHours,
  handleSelectedHour,
  selectedHour,
  isOptionDisabled = () => undefined,
}): JSX.Element => {
  return (
    <GridHours
      container
      sx={{ width: '65%', position: 'relative', top: '130px' }}
    >
      <GridWrapper columns={2} maxHeight="300px">
        {availableHours?.available_hours?.length === 0 && (
          <TypographyWrapper>
            Sin horas disponibles para este dia
          </TypographyWrapper>
        )}

        <TypographyWrapper
          sx={{ textAlign: 'center', marginBottom: '30px', fontSize: '20px' }}
        >
          Selecciona la hora para tu cita
        </TypographyWrapper>
        <GridWrapper columns={2} maxHeight="300px" sx={{ overflowY: 'scroll' }}>
          {availableHours?.available_hours?.length > 0 &&
            availableHours?.available_hours.map((hour: string, index: number) => (
              <HoursButton
                data-testid="button-available-hours"
                key={`${hour}-${index}`}
                type="button"
                value={hour}
                onClick={(e) => handleSelectedHour(e)}
                sx={{
                  backgroundColor:
                    selectedHour === hour ? '#31CACD' : '#DCE7FF',
                  color: selectedHour === hour ? '#FFF' : 'primary',
                  ':hover': {
                    backgroundColor: selectedHour === hour ? '#4CD6D8' : ''
                  }
                }}
                disabled={isOptionDisabled(hour)}
              >
                {hour}
              </HoursButton>
            ))
          }
        </GridWrapper>
      </GridWrapper>
    </GridHours>
  )
}
