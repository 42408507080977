import { GetSpecialties } from "../../../domain";
import { PostSpecialties } from "../../../domain/useCase/PostSpecialties";
import { SpecialtiesRepositoryImpl } from "../../../infrastructure/RepositoryImpl/SpecialtiesRepositoryImpl";
import { IServiceSpecialties } from "../../../infrastructure/dtos";
import SpecialtiesMapper from "../../../infrastructure/http/mappers/SpecialtiesMapper";

export async function SpecialtiesService(): Promise<IServiceSpecialties> {
  try {
    const UseCaseRegistro = new GetSpecialties(
      new SpecialtiesRepositoryImpl(new SpecialtiesMapper())
    );
    return {
      status: 1,
      data: await UseCaseRegistro.invoke(),
    };
  } catch (error) {
    return {
      status: 0,
      data: "Error Service",
    };
  }
}

export async function PostSpecialtiesService(
  specialties: string
): Promise<IServiceSpecialties> {
  try {
    const UseCaseApi = new PostSpecialties(
      new SpecialtiesRepositoryImpl(new SpecialtiesMapper())
    );
    return {
      status: 1,
      data: await UseCaseApi.invoke(specialties),
    };
  } catch (error) {
    return {
      status: 0,
      data: "Error Service",
    };
  }
}
