import { styled } from '@mui/material'
import { TypographyWrapper } from '../wrapper'

export const Title = styled(TypographyWrapper)(() => ({
  color: ' #000',
  fontSize: '18px',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '24px',
  marginTop: '24px'
}))
