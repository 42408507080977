import { Wrapper, Status } from '@googlemaps/react-wrapper'
import Maps from '../Maps'
import { MedicalOffice } from '../../../../../infrastructure/dtos/Offices'

interface IPanelMap {
  offices: MedicalOffice[]
  selectedMarker: (officeId: string) => void
}

function PanelMap({ offices, selectedMarker }: IPanelMap) {
  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <h2>Loading</h2>
      case Status.FAILURE:
        return <h2>Error</h2>
      case Status.SUCCESS:
        return (
          <Maps
            offices={offices}
            selectedMarker={(officeId: string) => selectedMarker(officeId)}
          />
        )
    }
  }

  return (
    <>
      <Wrapper
        apiKey="AIzaSyA6dlQ5VQlycFtui2MCzPoMbnPji7sLiJw"
        version="beta"
        libraries={['marker']}
        id="container_maps_wrapper"
        render={render}
      ></Wrapper>
    </>
  )
}

export default PanelMap
