import { useContext, useEffect, useState } from 'react'
import { IPhoneVerification } from '../../../../../infrastructure/dtos/PhoneVerification'
import {
  VerifyCodeUtility,
  resendCodeUtility,
  sendCodeUtility,
  updatePatientPhoneUtility
} from '../../../../../services/Contracts/Utility/PhoneVerificationUtility'
import {
  AccountContext,
  AuthContextType
} from '../../../../../contexts/AccountContext'
import dayjs from 'dayjs'
import { PostNewDateUtility } from '../../../../../services/Contracts/Utility/NewDateUtility'
import { PatientAppointmentContext } from '../../../../../contexts/PatientAppointmentContext'
import { useParams } from 'react-router-dom'

interface IUseModalConfirmPhoneNumberHook {
  isEditingPhoneNumber: boolean
  enableSendCodeButton: boolean
  enabledResendCodeButton: boolean
  enableConfirmButton: boolean
  isSavingPhone: boolean
  openModal: boolean
  phoneNumber: string
  codeValue: string
  countdownTimer: string
  message: string
  handleIsEditingPhoneNumber(editing: boolean): void
  onChangePhoneNumber(value: string): void
  onChangeCodeValue(value: string): void
  handleCloseModal(): void
  handleOpenModal(): void
  fetchResendCode(): Promise<void>
  fetchSendCode(): Promise<void>
  fetchVerifyCode(): Promise<void>
  phoneVerification: IPhoneVerification | undefined
  messageModal: string
  openModalAlert: boolean
}

export interface IConfirmPhoneNumber {
  phoneNumber: string
  codeValue: string
}

export function useModalConfirmPhoneNumber(
  initialPhoneVerification?: IPhoneVerification
): IUseModalConfirmPhoneNumberHook {
  const { patient, savePatientData } = useContext(
    AccountContext
  ) as AuthContextType

  const { PatientAppointment } = useContext(PatientAppointmentContext)

  const { id } = useParams<string>()

  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [oldPhoneNumber, setOldPhoneNumber] = useState<string>('')
  const [codeValue, setCodeValue] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const [isEditingPhoneNumber, setIsEditingPhoneNumber] =
    useState<boolean>(false)
  const [enableSendCodeButton, setEnableSendCodeButton] =
    useState<boolean>(true)
  const [enabledResendCodeButton, setEnabledResendCodeButton] =
    useState<boolean>(false)
  const [enableConfirmButton, setEnableConfirmButton] = useState<boolean>(false)
  const [isSavingPhone, setIsSavingPhone] = useState<boolean>(false)

  const [openModal, setOpenModal] = useState<boolean>(false)

  const [phoneVerification, setPhoneVerification] =
    useState<IPhoneVerification>()

  const [countdownTimer, setCountdownTimer] = useState<string>('')

  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false)
  const [messageModal, setMessageModal] = useState<string>('')

  const handleIsEditingPhoneNumber = (editing: boolean): void => {
    setIsEditingPhoneNumber(editing)
    if (!editing) fetchUpdatePhoneNumber()
    else setOldPhoneNumber(phoneNumber)
  }
  const onChangePhoneNumber = (value: string): void => setPhoneNumber(value)
  const onChangeCodeValue = (value: string): void => setCodeValue(value)
  const handleCloseModal = (): void => setOpenModal(false)
  const handleOpenModal = (): void => setOpenModal(true)

  const fetchUpdatePhoneNumber = async (): Promise<void> => {
    setIsSavingPhone(true)
    try {
      const { data, status } = await updatePatientPhoneUtility({
        phoneNumber,
        userId: patient?.userId as string
      })
      if (status) savePatientData({ ...patient, mobile: phoneNumber })
      else setPhoneNumber(oldPhoneNumber)
      setMessage(data)
      setOldPhoneNumber('')
    } catch {
      setPhoneNumber(oldPhoneNumber)
      setOldPhoneNumber('')
    }
    setIsSavingPhone(false)
  }

  const fetchResendCode = async (): Promise<void> => {
    const { data, status } = await resendCodeUtility({
      type: 'patient',
      userId: patient?.userId as string
    })
    if (status) {
      setPhoneVerification(data as IPhoneVerification)
    }
  }

  const fetchVerifyCode = async (): Promise<void> => {
    const { data, status } = await VerifyCodeUtility({
      userId: patient?.userId as string,
      code: codeValue
    })

    if (status) {
      setPhoneVerification(data as IPhoneVerification)
    }
  }

  const fetchSendCode = async (): Promise<void> => {
    setEnableSendCodeButton(false)
    const { data, status } = await sendCodeUtility(patient?.userId as string)
    if (status) {
      setPhoneVerification(data as IPhoneVerification)
    }
  }

  const countdown = (expired: string): (() => void) => {
    const date1: dayjs.Dayjs = dayjs(new Date())
    const date2: dayjs.Dayjs = dayjs(expired)
    let min: number = date2.diff(date1, 'minutes')
    let sec: number = date2.diff(date1, 'seconds') - min * 60
    if (
      (min <= 0 && sec <= 0) ||
      phoneVerification?.send_status === false ||
      phoneVerification?.resend_status === false
    ) {
      // set message
      return () => {}
    }
    const interval: NodeJS.Timer = setInterval(() => {
      sec--
      if (sec === -1) {
        sec = 59
        min--
      }
      setEnabledResendCodeButton(min < 4)
      setCountdownTimer(
        `${min}:${sec.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })}`
      )
      if (min === 0 && sec === 0) {
        setEnableSendCodeButton(false)
        setEnabledResendCodeButton(false)
        setEnableSendCodeButton(true)
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }

  useEffect(() => {
    if (patient?.mobile) onChangePhoneNumber(patient?.mobile)
    if (initialPhoneVerification) setPhoneVerification(initialPhoneVerification)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (phoneVerification) {
      if (phoneVerification?.verify_status === true) {
        PostNewDateUtility({
          appointment_date: PatientAppointment.date_appointment,
          comment: PatientAppointment.comment,
          email: patient.email as string,
          hour_from: PatientAppointment.hour_appointment,
          hour_to: dayjs(
            `${PatientAppointment.date_appointment}T${PatientAppointment.hour_appointment}`
          )
            .add(PatientAppointment.consultation_time_minutes, 'minutes')
            .format('HH:mm'),
          office_id: PatientAppointment?.office_id,
          phone: patient.mobile,
          user_id: id as string,
          reference: PatientAppointment.reference,
          patient_id: patient.userId,
          patient_id_number: patient.idNumber,
          type: 'patient'
        }).then((result) => {
          if (result.status === 1) {
            setOpenModalAlert(true)
            setMessageModal(result.data)
          }
        })
      }
      if (phoneVerification?.created_at && phoneVerification?.created_up) {
        countdown(phoneVerification?.created_up)
      }
      setMessage(phoneVerification?.message || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneVerification])

  useEffect(() => {
    setTimeout(() => {
      setMessage('')
    }, 6000)
  }, [message])

  return {
    isEditingPhoneNumber,
    enableSendCodeButton,
    enabledResendCodeButton,
    enableConfirmButton,
    isSavingPhone,
    openModal,
    phoneNumber,
    codeValue,
    countdownTimer,
    message,
    phoneVerification,
    messageModal,
    openModalAlert,
    handleIsEditingPhoneNumber,
    onChangePhoneNumber,
    onChangeCodeValue,
    handleCloseModal,
    handleOpenModal,
    fetchResendCode,
    fetchSendCode,
    fetchVerifyCode
  }
}
