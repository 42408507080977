import { ButtonR, LabelText } from './styles'
import { GridWrapper } from '../../../../components'
import { IDoctorInfo } from '../../../../infrastructure/dtos/DoctorInfo'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import PhotoDoctor from '../PhotoDoctor'

interface DoctorInfoProps {
  info: IDoctorInfo
}

export default function DataDoctor({ info }: DoctorInfoProps): JSX.Element {
  return (
    <GridWrapper sx={{ marginTop: "1.5rem" }}>
      <GridWrapper container={true} justifyContent="center" alignItems="center">
        <GridWrapper item>
          <LabelInput textAlign="center" >Conoce más sobre el Dr. {info.name_doctor} {info.last_name_doctor}</LabelInput>
        </GridWrapper>
      </GridWrapper>
      <GridWrapper container={true}>
        <GridWrapper item sm={4} sx={{ height: '100%', padding: "1.5rem", marginTop: "1rem" }}>
          <PhotoDoctor
            photo={info.photo_doctor}
            rating='5'
          />
        </GridWrapper>
        <GridWrapper item sm={8} sx={{ height: '100%', padding: "1rem" }}>
          <LabelText >Dr. {info.name_doctor} {info.last_name_doctor}</LabelText>
          {info.speciality_doctor?.length > 0 ? (
            info.speciality_doctor?.map((item: any, index: number) => (
              <LabelText key={index}>{item}</LabelText>
            ))
          ) : (
            <LabelText >Sin Especialidades</LabelText>
          )
          }
          <br />
          <LabelText >Años de experiencia: {info.years_experience_doctor}</LabelText>
          <LabelText >Estudios: {info.university_doctor}</LabelText>
          <LabelText >Atenciones: S/N</LabelText>
          <ButtonR
            type="button"
            onClick={() => { console.log("Click") }}
            data-testid="modal-button"
          >
            Refiérelo a tus amigos
          </ButtonR>
        </GridWrapper>
      </GridWrapper>
    </GridWrapper>
  )
}
