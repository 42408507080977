import { CardInfo, CustomAvatar, TypographyInfo } from './styles'
import dayjs from 'dayjs'
import { GridWrapper } from '../../../../components'
import CardContentWrapper from '../../../../components/wrapper/CardContentWrapper'
import TypographyWrapper from '../../../../components/wrapper/TypographyWrapper'
import { IDoctorInfo } from '../../../../infrastructure/dtos/DoctorInfo'
import { IPatientAppointmentState } from '../../../../contexts/PatientAppointmentContext'
import {
  AccessAlamr,
  CalendarToday,
  LocationOnOutlinedIcon
} from '../../../../components/icons'
import { SxProps } from '@mui/material'

interface DoctorInfoProps {
  message: string
  info: IDoctorInfo
  patientAppointment?: IPatientAppointmentState | null
  sx?: SxProps
}

export default function DoctorInfo({
  message,
  info,
  patientAppointment = null,
  sx
}: DoctorInfoProps): JSX.Element {
  return (
    <GridWrapper>
      <CardInfo>
        <CustomAvatar
          src={info?.photo_doctor}
          alt={`${info?.name_doctor} ${info?.last_name_doctor}`}
        />
        <GridWrapper style={{ marginLeft: 20 }}>
          <CardContentWrapper>
            <TypographyInfo color="primary.dark" gutterBottom sx={sx}>
              {message}
              {info?.name_doctor} {info?.last_name_doctor}
            </TypographyInfo>
            {patientAppointment && (
              <GridWrapper marginLeft={1}>
                <TypographyWrapper
                  display={'flex'}
                  alignItems={'center'}
                  color={'primary.dark'}
                  fontSize={'.9rem'}
                >
                  <CalendarToday />
                  <span style={{ marginLeft: '5px' }}>
                    {dayjs(patientAppointment.date_appointment).format(
                      'dddd D '
                    ) +
                      ' de ' +
                      dayjs(patientAppointment.date_appointment).format(
                        ' MMMM '
                      ) +
                      ' - ' +
                      patientAppointment.consultation_time_minutes +
                      ' min'}
                  </span>
                </TypographyWrapper>
                <TypographyWrapper
                  display={'flex'}
                  alignItems={'center'}
                  color={'primary.dark'}
                  fontSize={'.9rem'}
                >
                  <AccessAlamr />
                  <span style={{ marginLeft: '5px' }}>
                    {patientAppointment.hour_appointment +
                      'h - ' +
                      dayjs(
                        `${patientAppointment.date_appointment}T${patientAppointment.hour_appointment}`
                      )
                        .add(
                          patientAppointment.consultation_time_minutes,
                          'minutes'
                        )
                        .format('HH:mm') +
                      'h'}
                  </span>
                </TypographyWrapper>
                <TypographyWrapper
                  display={'flex'}
                  alignItems={'center'}
                  color={'primary.dark'}
                  fontSize={'.9rem'}
                >
                  <LocationOnOutlinedIcon />
                  <span style={{ marginLeft: '5px' }}>
                    {patientAppointment.office?.reference_medical_office}
                  </span>
                </TypographyWrapper>
              </GridWrapper>
            )}
          </CardContentWrapper>
        </GridWrapper>
      </CardInfo>
    </GridWrapper>
  )
}
