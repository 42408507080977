
import { styled } from '@mui/material/styles'
import { GridWrapper, TypographyWrapper } from '../../../components'

export const GridFormRegister = styled(GridWrapper)(() => ({
    width: '100%',
    alignItems: 'center',
    marginTop: "4rem"
    //justifyContent: 'space-around',
}))
export const TypographyWrapperValidationPhoneFalse = styled(TypographyWrapper)(
    () => ({
        color: 'red',
        fontSize: '0.9rem',
        fontFamily: '"Helvetica"',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: "left",
        marginLeft: "14px",
        marginTop: "1px",
    })
)