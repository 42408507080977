import { Auth } from "aws-amplify";
import axios from 'axios';

const http = axios.create({

    baseURL:process.env.REACT_APP_API 

});

http.interceptors.request.use( function (config: any) {
  

  return new Promise((resolve, reject) => {
   Auth.currentSession()
     .then(async (session) => {
       let idTokenExpire = session.getIdToken().getExpiration();
       let refreshToken = session.getRefreshToken();
       let currentTimeSeconds = Math.round(+new Date() / 1000);
      
       if (idTokenExpire < currentTimeSeconds) {
        
         Auth.currentAuthenticatedUser()
           .then((res) => {
             res.refreshSession(refreshToken, async (err:any, data:any) => {
               if (err) {
                 Auth.signOut()
               } else {
                 config.headers.Authorization = data.getAccessToken().getJwtToken();
                 resolve(config);
               }
             });
           });
       } else {
        config.headers.Authorization = session.getAccessToken().getJwtToken();
        resolve(config);
       }
     })
     .catch(() => {
       // No logged-in user: don't set auth header
       resolve(config);
     });
 });

});

export { http };