import { styled } from '@mui/material/styles'
import { BoxWrapper, ButtonWrapper, IconButtonWrapper, TypographyWrapper } from '../../../../../components'

export const BoxModal = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  minHeight: '500px',
  bgcolor: theme.palette.background.paper,
  background: '#fff',
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)',
  padding: '40px 60px'
}))

export const TitleTypographyModal = styled(TypographyWrapper)((props) => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '17px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: props.theme.palette.primary.dark
}))

export const EditPhoneButton = styled(ButtonWrapper)(() => ({
  border: 0,
  flexDirection: 'column',
  lineHeight: '2.4',
  textTransform: 'capitalize',
  borderRadius: '2.625rem',
  paddingBottom: 0,
  '& .MuiButton-startIcon': {
    margin: 0
  }
}))

export const SubtitleTypographyModal = styled(TypographyWrapper)((props) => ({
  opacity: 1,
  color: 'rgba(0,0,0,1)',
  fontFamily: "Helvetica",
  fontSize: '14px',
  fontWeight: 600,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
}))

export const ResendCodeButton = styled(ButtonWrapper)(() => ({
  textTransform: 'initial'
}))

export const CloseButton = styled(IconButtonWrapper)((props) => ({
  position: 'absolute',
  right: 50,
  top: 30,
  color: props.theme.palette.primary.dark
}))
