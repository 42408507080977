import React, { ReactElement, useContext } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import { AccountContext, AuthContextType } from './contexts/AccountContext'
import VerificationView from './views/Auth/VerificationCode/emailVerificationByCode'
import { AuthLayout } from './layouts'
import { ScheduleAppointment } from './views/ScheduleAppointment'
import { PatientRegister } from './views/Register/'
import { Status } from './views/Auth/Status'
import AuthPage from './views/Auth'
import Appointment from './views/Appointment'
import AppLayout from './layouts/AppLayout'
import Home from './views/Home'
import BookingDate from './views/pages/BookingDate'
import PrescriptionResume from './views/pages/PrescriptionResume'
import { ConfirmAppointment } from './views/ConfirmAppointment/ConfirmAppointment'

export const AppRouter = (): ReactElement | null => {
  const { status } = useContext(AccountContext) as AuthContextType

  const commonRoutes: RouteObject[] = [
    {
      path: 'medical-consultation',
      element: <AuthLayout />,
      children: [
        {
          path: ':idDoctor',
          element: <ScheduleAppointment />
        }
      ]
    },
    {
      path: 'confirm-appointment',
      element: <AuthLayout />,
      children: [
        {
          path: ':appointmentId/:rescheduleId',
          element: <ConfirmAppointment />
        }
      ]
    }
  ]

  const AuthRoutes: RouteObject[] = [
    {
      path: 'register',
      element: <AuthLayout />,
      children: [{ path: '', element: <PatientRegister /> }]
    },
    {
      path: 'appointment',
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <Appointment />
        },
        {
          path: ':booking/:doctorId/:appointmentId?',
          element: <BookingDate />
        },
        {
          path: '*',
          element: <Navigate to="/appointment" replace />
        }
      ]
    },
    {
      path: 'prescription',
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <Navigate to="/" replace />
        },
        {
          path: ':id',
          element: <PrescriptionResume />
        }
      ]
    },
    {
      path: '/',
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <Home />
        },
        {
          path: 'dates',
          element: <Appointment />
        }
      ]
    },
    { path: 'status', element: <Status /> },
    ...commonRoutes,
    {
      path: '/*',
      element: <Navigate to="/" replace />
    }
  ]

  const publicRoutes: RouteObject[] = [
    {
      path: 'verification',
      element: <AuthLayout />,
      children: [
        {
          index: true,
          element:
            status !== 'authenticated' ? (
              <VerificationView />
            ) : (
              <Navigate to="/" replace />
            )
        }
      ]
    },
    {
      path: 'auth/*',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <AuthPage />
        }
      ]
    },
    ...commonRoutes,
    {
      path: '/*',
      element: <Navigate to="/auth/login" replace />
    }
  ]

  return useRoutes(
    status === 'authenticated' ? AuthRoutes : publicRoutes
  )

}
