import React, { useEffect, useRef, useState } from 'react'
import { MedicalOffice } from '../../../../../infrastructure/dtos/Offices'
import LocationPanelMap from '../LocationPanelMap'

interface MapConfig {
  center: google.maps.LatLngLiteral
  zoom: number
}

interface IMaps {
  offices: MedicalOffice[]
  selectedMarker: (officeId: string) => void
}

export default function Maps({ offices, selectedMarker }: IMaps) {
  const [map, setMap] = useState<google.maps.Map | undefined>()
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      const mapConfig: MapConfig = {
        center: {
          lat: -1.831239,
          lng: -78.183406
        },
        zoom: 6
      }

      const mapOptions: google.maps.MapOptions = {
        ...mapConfig,
        mapTypeControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        streetViewControl: false,
        fullscreenControl: false,
        mapId: 'hjerwhjkhlk'
      }

      const googleMap = new google.maps.Map(ref.current, mapOptions)

      setMap(googleMap)
    }
  }, [])
  return (
    <>
      <div
        ref={ref}
        id="map"
        style={{ width: '100%', height: '250px' }}
        data-testid="container-maps-div"
      />
      {map && (
        <LocationPanelMap
          map={map}
          offices={offices}
          selectedMarker={(officeId: string) => selectedMarker(officeId)}
        />
      )}
    </>
  )
}
