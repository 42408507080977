import { AxiosError } from 'axios'
import { SaveDataFollow, IApiResponseSaveDataFollow } from '../../dtos/Follow'
import { http } from '../Http'
import FollowDataSource from '../dataSource/FollowDataSource'

export default class FollowMapper implements FollowDataSource {
  async saveFollow(
    params: SaveDataFollow
  ): Promise<IApiResponseSaveDataFollow> {
    try {
      let { data } = await http.post<IApiResponseSaveDataFollow>(
        `save_data/follow`,
        { body: params }
      )
      return data
    } catch (error: unknown) {
      const data: IApiResponseSaveDataFollow = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).message as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
      return data
    }
  }
}
