import { GetAvailableHoursInADay, GetCalendarEvent, GetNextAppointmentHour, PostCalendarEvent } from "../../../domain/useCase/Calendar";
import { CalendarRepositoryImpl } from "../../../infrastructure/RepositoryImpl/CalendarRepositoryImpl";
import { IAvailableHours, ICalendarEvent, IEvent, INextAppointmentHour, IParamsGetCalendar, IParamsGetNextAppointmentHour, IResponseCalendar } from "../../../infrastructure/dtos/Calendar";
import CalendarMapper from "../../../infrastructure/http/mappers/CalendarMapper";


export async function PostAddCalendarEvent(event: ICalendarEvent): Promise<IResponseCalendar<IEvent>> {
    try {
        const UseCaseApi: PostCalendarEvent = new PostCalendarEvent(
            new CalendarRepositoryImpl(new CalendarMapper())
        );
        const { body } = await UseCaseApi.invoke(event)
        return { 
            status: 1,
            body: body as string
        }; 
    } catch (Error: any) {
        return {
            status: 0,
            body: Error?.message
        };
    }
}

export async function GetCalendarEvents(params: IParamsGetCalendar): Promise<IResponseCalendar<IEvent>> {
    try {
        const UseCaseApi: GetCalendarEvent = new GetCalendarEvent(
            new CalendarRepositoryImpl(new CalendarMapper())
        );
        const { body } = await UseCaseApi.invoke(params)
        return { 
            status: 1,
            body
        }; 
    } catch (Error: any) {
        return {
            status: 0,
            body: Error?.message
        };
    }
}

export async function getAvailableHoursInADay(params: IParamsGetCalendar): Promise<IResponseCalendar<IAvailableHours>> {
    try {
        const UseCaseApi: GetAvailableHoursInADay = new GetAvailableHoursInADay(
            new CalendarRepositoryImpl(new CalendarMapper())
        );
        const { body } = await UseCaseApi.invoke(params)
        return { 
            status: 1,
            body
        }; 
    } catch (Error: any) {
        return {
            status: 0,
            body: Error?.message
        };
    }
}

export async function getNextAppointmentHour(params: IParamsGetNextAppointmentHour): Promise<IResponseCalendar<INextAppointmentHour>> {
    try {
        const UseCaseApi: GetNextAppointmentHour = new GetNextAppointmentHour(
            new CalendarRepositoryImpl(new CalendarMapper())
        );
        const { body } = await UseCaseApi.invoke(params)
        return { 
            status: 1,
            body
        }; 
    } catch (Error: any) {
        return {
            status: 0,
            body: Error?.message
        };
    }
}