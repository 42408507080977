import { IApiResponseSaveNewDate, SaveNewDate } from '../../dtos/NewDate'
import { http } from '../Http'
import NewDateDataSource from '../dataSource/NewDateDataSource'

export default class NewDateMapper implements NewDateDataSource {
  async postNewDate(
    dataNewDate: SaveNewDate
  ): Promise<IApiResponseSaveNewDate> {
    let data = await http
      .post<IApiResponseSaveNewDate>(`save_data/save_appointment`, {
        body: dataNewDate
      })
      .then((response) => {
        const { data } = response
        return data
      })
      .catch((error) => {
        const data = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': ''
          }
        }
        return data
      })
    return data
  }
}
