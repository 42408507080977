import { FormControlLabel, FormControlLabelProps } from "@mui/material";
import React from "react";

const FormControlLabelRadioButtonsrapper: React.FC<FormControlLabelProps> = ({ ...props }) => (
 
  <FormControlLabel 
  {...props} />
);

export default FormControlLabelRadioButtonsrapper;


