import { useEffect, useState, useContext } from "react";
import LoaderWrapper from "../../../components/wrapper/LoaderWrapper";
import { Auth0Error, Auth0DecodedHash, Auth0ParseHashError, Auth0UserProfile } from 'auth0-js'
import { Location, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import { UserPool } from "../../../utils";
// import { ButtonVerifiedToLogin } from './emailVerificationStyles';
// import { CognitoUser } from "amazon-cognito-identity-js";
import { auth } from '../../../utils/auth0'
import {
  AccountContext,
  AuthContextType
} from '../../../contexts/AccountContext'
import Cookies from 'universal-cookie'
import axios from 'axios'

const VerificationView: React.FunctionComponent= (
  props
) => {
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState<boolean | null>(null);
  const [isError, setIsError] = useState<boolean | null>(null);
  const { notAuthenticate, CheckToken } = useContext(AccountContext) as AuthContextType

  useEffect(() => {
    if(searchParams.get('message')) {

      if(searchParams.get('success')) setVerified(true)
      if(!searchParams.get('success')) setIsError(true)
      loginAux(location.hash.substring(1))

    } else {
      if(location.hash) {
        processHash(location)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginAux = async (email: string) => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_API}/send_data_temporary/user_medical/${email}`)

      if (result.data?.body?.user_email) {
        auth.login({
          username: result.data?.body?.user_email,
          password: result.data?.body?.user_password,
          realm: process.env.REACT_APP_AUTH0_REALM,
          redirectUri: process.env.REACT_APP_AUTH_LOGIN_REDIRECT_URI,
          responseType: 'token',
        }, (err: Auth0Error | null, result: any) => {
          if (err) console.log(err);
          return console.log(result);
        })
      }
    } catch(error: any) {
      alert(error)
    }
  }

  const processHash = ({ hash, pathname }: Location) => {
    auth.parseHash({
        hash,
    }, (error: Auth0ParseHashError | null, result: Auth0DecodedHash | null) => {
        if(error) {
            console.log("there is something wrong");
            console.log(error);
            return;
        }
        if(result) {
            const { accessToken } = result;

            if(accessToken) {
              const cookies = new Cookies();
              cookies.set('access_token', accessToken, {path: '/'})
                        
                auth.client.userInfo(
                    accessToken,
                    (error: Auth0Error | null, response: Auth0UserProfile) => {
                        if(error) {
                            console.log("Something went wrong");
                            console.log(error);
                            notAuthenticate('there is something wrong');
                            return;
                        }

                        console.log("User Login Successful")
                        setVerified(true)
                        localStorage.setItem('userData', JSON.stringify({
                          userId: response.sub?.split('|')[1],
                          email: response.email
                        }));
                        
                        CheckToken(pathname, accessToken, response);
                    }
                )

            }
        }
    })
  }

  // const verificationCode = (username: any, code: any) => {
  //   return new Promise((resolve, reject) => {
  //     const cognitoUser = new CognitoUser({
  //       Username: username,
  //       Pool: UserPool
  //     })


  
  //     cognitoUser.confirmRegistration(code, true, (err, result) => {
  //       if (err) {
  //         console.log(err)
  //         setIsError(true);
  //         reject(err)
  //         return
  //       }
  //       resolve(result)
  //     })
  //   })
  // }
  if(verified === null && isError === null) {
    return <LoaderWrapper />
  }

  return (
    <>
      { !isError && verified && "HA SIDO VERIFICADO CON ÉXITO" }
      { isError && "NO SE HA PODIDO VERIFICAR TU CUENTA" }
      { !verified && "NO SE HA PODIDO VERIFICAR TU CUENTA" }

    </>
  );
};
export default VerificationView;
