import { Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import { AccountContext, AuthContextType } from "../../contexts/AccountContext";
import {
  ButtonLogin,
  TypographyWrapperAuth,
} from "../../utils/RegisterMedicalFormStyles";

export interface IStatusProps {}

export const Status: React.FunctionComponent<IStatusProps> = (props) => {
  const { user, logout } = useContext(AccountContext) as AuthContextType;

  useEffect(() => {
    if (user !== null) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Grid
      item
      xs={3}
      sx={{
        width: { sm: 450 },
        backgroundColor: "white",
        padding: 3,
        borderRadius: 2,
      }}
    >
      <TypographyWrapperAuth>
        {JSON.stringify(user?.user.getUsername(), null, 1)}
      </TypographyWrapperAuth>

      <ButtonLogin onClick={logout} variant="contained" fullWidth>
        Cerrar Sesión
      </ButtonLogin>
    </Grid>
  );
};
