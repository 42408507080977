import { BuildingCustomIcon, HomeCustomIcon, OtherHomeCustomIcon } from "../../../../components";

interface AddressIconsListProps {
    addressName: string;
}

export function AddressIconsList ({ addressName }: AddressIconsListProps): JSX.Element {
    return addressName === 'Casa' ? <HomeCustomIcon /> :
    addressName === 'Oficina' ? <BuildingCustomIcon/> :
    <OtherHomeCustomIcon/>
}