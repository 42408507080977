import { ISaveRegisterPatient } from "../../../infrastructure/dtos/PatientRegister";
import { IService } from "../Persistencia/InterfaceServiceResponse";
import { PostRegisterPatientService } from "../Persistencia/RegisterPatientService";

export async function PostPatientRegisterUtility(
  data: ISaveRegisterPatient
): Promise<IService> {
  try {
    const valores = await PostRegisterPatientService(data);
    if (valores.status !== 1 || valores.data.statusCode !== 200) {
      return {
        data: "Ups! Hubo complicaciones intente mas tarde",
        status: 0,
      };
    }

    return {
      data: valores.data.body,
      status: valores.status,
    };
  } catch (error) {
    return {
      data: "Ups! Hubo complicaciones intente mas tarde",
      status: 0,
    };
  }
}
