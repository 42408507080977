import { ReactNode, useEffect } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  GridWrapper,
  IconButtonWrapper,
  ListItemWrapper,
  ListWrapper,
  MenuItemWrapper,
  MenuWrapper,
  TypographyWrapper
} from '../../../../../components'
import { OutLineButton } from '../../../../../components/StyledComponents/OutLineButton'
import { Title } from '../../../../../components/StyledComponents/Title'
import {
  AvatarPastAppointment,
  PrimaryDarkButton
} from './PastAppointmentsListStyle'
import { usePastAppointmentsList } from './PastAppointmentsListHook'
import { IPastAppointment } from '../../../../../infrastructure/dtos/Appointments'
import { useShowRecipesListModal } from './components/ShowRecipeList/useShowRecipesListModal'
import { ShowRecipesListModal } from './components/ShowRecipeList/ShowRecipesList'
import Recipe from './components/Recipe/Recipe'
import { LoadingRecipesListModal } from './components/LoadingRecipes/LoadingRecipe'
import { IRecipePrescription } from '../../../../../infrastructure/dtos/Prescription'

interface IGridBody {
  value: string | ReactNode
  width: string
}

interface IGridHeader {
  title: string
  width: string
}

const options: string[] = ['Pedido', 'Factura']

export function PastAppointmentsList(): JSX.Element {
  const {
    anchorEl,
    message,
    pastAppointments,
    handleClick,
    handleClose,
  } = usePastAppointmentsList()

  const {
    handleOpenRecipesListModal,
    openRecipesListModal,
    openLoadingRecipesModal,
    handleOpenRecipe,
    openRecipe,
    appointmentDetails,
    prescriptionIndex,
    fetchAppointmentRecipes,
    handleOpenLoadingRecipesModal,
    handleSetPrescriptionIndex
  } = useShowRecipesListModal()

  const chooseRecipeModal = async (item: IPastAppointment): Promise<void> => {
    handleOpenLoadingRecipesModal(true)
    await fetchAppointmentRecipes(item.appointment_id)
  }

  const onSetPrescriptionIndex = (index: number) => {
    handleSetPrescriptionIndex(index)
    handleOpenRecipesListModal(false)
    handleOpenRecipe(true)
  }

  useEffect(() => {
    handleOpenLoadingRecipesModal(false)
    if (appointmentDetails) {
      if ((appointmentDetails)?.prescription.length === 1) {
        handleSetPrescriptionIndex(0)
        handleOpenRecipe(true)
      }
      else
        handleOpenRecipesListModal(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDetails])

  const gridHeader: IGridHeader[] = [
    { title: '', width: '3%' },
    { title: 'Fecha', width: '10%' },
    { title: 'Hora', width: '10%' },
    { title: 'Doctor', width: '11%' },
    { title: 'Lugar', width: '11%' },
    { title: 'Especialidad', width: '10%' },
    { title: '', width: '38%' }
  ]

  const gridBody = (item: IPastAppointment, itemIndex: number): IGridBody[] => [
    {
      value: item.appointment_date.split('-').reverse().join('/'),
      width: '10%'
    },
    { value: item.hour_from.split(':').join('h'), width: '10%' },
    { value: `${item.name_doctor} ${item.last_name_doctor}`, width: '11%' },
    { value: item.reference_medical_office, width: '11%' },
    { value: item.speciality_doctor, width: '10%' },
    {
      value: (
        <PrimaryDarkButton type="submit" variant="contained" fullWidth>
          Agendar
        </PrimaryDarkButton>
      ),
      width: '13%'
    },
    {
      value: (
        <OutLineButton
          variant="outlined"
          disabled={item.assistance === 'NOT_ASSISTED'}
          onClick={() => chooseRecipeModal(item)}
        >
          Ver receta
        </OutLineButton>
      ),
      width: '13%'
    },
    {
      value: (
        <OutLineButton
          variant="outlined"
          disabled={item.assistance === 'NOT_ASSISTED'}
        >
          Calificar
        </OutLineButton>
      ),
      width: '13%'
    },
    {
      value: (
        <GridWrapper>
          <IconButtonWrapper
            aria-label="more"
            id="long-button"
            data-testid="long-button"
            aria-controls={item.showMenu ? 'long-menu' : undefined}
            aria-expanded={item.showMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(event) => handleClick(event, itemIndex)}
            disabled={item.assistance === 'NOT_ASSISTED'}
          >
            <MoreHorizIcon
              fontSize="large"
              sx={{
                color:
                  item.assistance === 'NOT_ASSISTED'
                    ? 'lightgray'
                    : 'primary.dark'
              }}
            />
          </IconButtonWrapper>
          <MenuWrapper
            id="long-menu"
            data-testid="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={anchorEl}
            open={Boolean(item.showMenu)}
            onClose={() => handleClose(itemIndex)}
            sx={{ borderRadius: '20%' }}
          >
            {options.map((option: string, index: number) => (
              <MenuItemWrapper
                key={`${option}-${index}`}
                onClick={() => handleClose(itemIndex)}
              >
                {option}
              </MenuItemWrapper>
            ))}
          </MenuWrapper>
        </GridWrapper>
      ),
      width: '5%'
    }
  ]

  return (
    <GridWrapper px={'4rem'} pt={2}>
      <Title sx={{ marginBottom: 0 }} data-testid="title-past-appointments">
        Citas pasadas
      </Title>
      {!Boolean(message) ? (
        <ListWrapper>
          <ListItemWrapper
            style={{
              borderRadius: '40px',
              margin: '10px 0'
            }}
            data-testid="past-dates"
          >
            {gridHeader.map((item: IGridHeader, index: number) => (
              <GridWrapper
                item
                textAlign={'center'}
                width={item.width}
                key={`GridHeader-${index}`}
              >
                {item.title}
              </GridWrapper>
            ))}
          </ListItemWrapper>
          {pastAppointments.map(
            (appointment: IPastAppointment, index: number) => (
              <ListItemWrapper
                key={`pastAppointment-${appointment.appointment_id}-${index}`}
                style={{
                  height: '80px',
                  background: '#FFF',
                  borderRadius: '40px',
                  margin: '10px 0'
                }}
                data-testid="past-dates-item-list"
              >
                <AvatarPastAppointment src={appointment.photo_doctor} />
                {gridBody(appointment, index).map(
                  (i: IGridBody, idx: number) => (
                    <GridWrapper
                      key={`GridBody-${appointment.appointment_id}-${idx}`}
                      item
                      textAlign={'center'}
                      width={i.width}
                      paddingX={0.5}
                    >
                      {idx >= 5 ? (
                        i.value
                      ) : (
                        <TypographyWrapper
                          color={
                            appointment.assistance === 'NOT_ASSISTED'
                              ? 'lightgray'
                              : 'inherit'
                          }
                        >
                          {i.value}
                        </TypographyWrapper>
                      )}
                    </GridWrapper>
                  )
                )}
              </ListItemWrapper>
            )
          )}
        </ListWrapper>
      ) : (
        <TypographyWrapper marginTop={4} marginLeft={2}>
          {message}
        </TypographyWrapper>
      )}

      {openLoadingRecipesModal && (
        <LoadingRecipesListModal
          handleOpenLoadingRecipesModal={handleOpenLoadingRecipesModal}
          open={openLoadingRecipesModal}
        />
      )}

      {openRecipesListModal && (
        <ShowRecipesListModal
          appointmentDetails={appointmentDetails}
          handleOpenRecipesListModal={handleOpenRecipesListModal}
          open={openRecipesListModal}
          handleSetPrescriptionIndex={onSetPrescriptionIndex}
        />
      )}
      {openRecipe && (
        <Recipe
          appointmentDetails={appointmentDetails as IRecipePrescription}
          handleOpenRecipe={handleOpenRecipe}
          open={openRecipe}
          prescriptionIndex={prescriptionIndex as number}
        />
      )}
    </GridWrapper>
  )
}
