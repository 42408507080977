import {
  AvatarWrapper,
  GridWrapper,
  TypographyWrapper
} from '../../../../components'

interface Props {
  name?: string
  imageUrl?: string
}

export const ProfilePicture = ({ name, imageUrl }: Props): JSX.Element => (
  <GridWrapper style={{ display: 'flex', alignItems: 'center' }}>
    <AvatarWrapper src={imageUrl} alt={name} />
    <TypographyWrapper sx={{ marginLeft: '1rem' }}>
      {name}
    </TypographyWrapper>
  </GridWrapper>
)
