import React, { useContext, useEffect, useState } from 'react'
import {
  GridContent,
  GridLayoutRight
} from '../../ScheduleAppointment/steps/SelectSchedule/SelectScheduleStyle'
import { GridHalfScreen } from '../../../components/StyledComponents/GridHalfScreen'
import DoctorInfo from '../../ScheduleAppointment/components/DoctorInfo'
import { useNavigate, useParams } from 'react-router-dom'
import { useDataDoctor } from '../../ScheduleAppointment/Hooks/useDataDoctor'
import { GridWrapper, LoaderWrapper } from '../../../components/wrapper'
import { OfficeList } from '../../ScheduleAppointment/components/OfficesList/OfficesList'
import { SelectChangeEvent } from '@mui/material'
import {
  PatientAppointmentContext,
  PatientAppointmentInitState
} from '../../../contexts/PatientAppointmentContext'
import dayjs from 'dayjs'
import CalendarInfo from '../../ScheduleAppointment/components/CalendarInfo'
import { ICalendarData } from '../../../infrastructure/dtos/CalendarInfo'
import { GetDaysAvailabilityInAMonth } from '../../../services/Contracts/Persistencia/CalendarInfoService'
import { AvailableHours } from '../../ScheduleAppointment/components/AvailableHours/AvailableHours'
import {
  IAvailableHours,
  IResponseCalendar
} from '../../../infrastructure/dtos/Calendar'
import { getAvailableHoursInADay } from '../../../services/Contracts/Persistencia/Calendar'
import { GridBookingForm, ProfileBox } from './styles'
import { AvatarProfile } from '../../../components/StyledComponents/AvatarProfile'
import { ModalConfirmPhoneNumber } from './components/ModalConfirmPhoneNumber'
import { useModalConfirmPhoneNumber } from './components/ModalConfirmPhoneNumber/hook'
import BreadcrumbsBooking from './components/BreadcrumbsBooking'
import {
  AccountContext,
  AuthContextType
} from '../../../contexts/AccountContext'
import {
  ICodeUtility,
  verifyPhoneValidationUtility
} from '../../../services/Contracts/Utility/PhoneVerificationUtility'
import { IPhoneVerification } from '../../../infrastructure/dtos/PhoneVerification'
import BookigForm from './components/BookingForm'
import { PostNewDateUtility } from '../../../services/Contracts/Utility/NewDateUtility'
import {
  SearchInitState,
  SearchOfficesContext
} from '../../../contexts/SeachOfficesContext'
import { Advert } from '../../../components/Notification/Advert/Advert'
import {
  IAppointmentInfo,
  IAppointmentInfoResponse
} from '../../../infrastructure/dtos/Appointments'
import { saveReschedulingAppointmentUtility } from '../../../services/Contracts/Utility/ReschedulingAppointmentUtility'
import { getAppointmentInfoUtility } from '../../../services/Contracts/Utility/AppointmentsUtility'

function BookingDate(): JSX.Element {
  const { PatientAppointment, saveScheduleSelected } = useContext(
    PatientAppointmentContext
  )
  const { patient, handleAlert } = useContext(AccountContext) as AuthContextType
  const { savelastSearch } = useContext(SearchOfficesContext)

  const [phoneVerification, setPhoneVerification] = useState<
    IPhoneVerification | undefined
  >(undefined)
  const { openModal, handleCloseModal, handleOpenModal } =
    useModalConfirmPhoneNumber(phoneVerification as IPhoneVerification)

  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const { doctorId, appointmentId } = useParams<string>()
  const { dataDoctor } = useDataDoctor(doctorId as string)
  const [selectedOffice, setSelectedOffice] = useState<string>(
    PatientAppointment?.office_id
  )
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
    PatientAppointment?.date_appointment
      ? dayjs(PatientAppointment?.date_appointment)
      : null
  )

  const navigate = useNavigate()

  const [selectedHour, setSelectedHour] = useState<string>(
    PatientAppointment?.hour_appointment
  )

  const handleCloseAlert = () => {
    navigate('/')
    saveScheduleSelected(PatientAppointmentInitState)
    savelastSearch(SearchInitState)
  }

  const setSaveScheduleSelected = () => {
    saveScheduleSelected({
      ...PatientAppointment,
      office_id: selectedOffice,
      date_appointment: selectedDate?.format('YYYY-MM-DD') as string,
      hour_appointment: selectedHour,
      consultation_time_minutes: parseInt(
        consultationTimeMinutes.split(' ')[0]
      ),
      comment: comments,
      reference: reference
    })
  }

  const [consultationTimeMinutes, setConsultationTimeMinutes] =
    useState<string>('')

  const [loadCalendar, setLoadCalendar] = useState<boolean>(false)
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [daysAvailability, setDaysAvailability] = useState<ICalendarData>()
  const [showAvailableHours, setShowAvailableHours] = useState<boolean>(false)
  const [loadAvailableHours, setLoadAvailableHours] = useState<boolean>(false)
  const [availableHours, setAvailableHours] = useState<IAvailableHours>()
  const [disabledButton, setDisabledButton] = useState<boolean>(true)

  const [appointmentToReschedule, setAppointmentToReschedule] =
    useState<IAppointmentInfo>()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const [messageModal, setMessageModal] = useState<string>('')

  const [comments, setComments] = useState<string>('')
  const [reference, setReference] = useState<string>('')

  const fetchDaysAvailabilityInAMonth = async () => {
    const {
      data: { body }
    }: { data: { body: ICalendarData } } = await GetDaysAvailabilityInAMonth(
      doctorId as string,
      selectedOffice
    )
    return body
  }

  const fetchAppointmentInfo = async (appointmentId: string) => {
    const { data, status } = await getAppointmentInfoUtility(appointmentId)
    if (status) {
      setAppointmentToReschedule((data as IAppointmentInfoResponse).appointment)
    }
  }

  useEffect(() => {
    if (selectedOffice) {
      setShowAvailableHours(false)
      setLoadCalendar(true)
      fetchDaysAvailabilityInAMonth().then((result) => {
        setDaysAvailability(result)
        setLoadCalendar(false)
        setShowCalendar(true)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffice])

  useEffect(() => {
    if (!appointmentId && selectedHour) {
      setDisabledButton(false)
    } else if (
      (appointmentId &&
        selectedHour &&
        selectedOffice === appointmentToReschedule?.office_id &&
        selectedDate?.format('YYYY-MM-DD') ===
          appointmentToReschedule?.appointment_date &&
        selectedHour === appointmentToReschedule?.hour_from) ||
      !selectedHour
    ) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHour])

  useEffect(() => {
    if (selectedDate) {
      setShowAvailableHours(true)
      fetchAvailableHoursInADay()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  const handleChangeOffice = (event: SelectChangeEvent<unknown>): void => {
    setSelectedOffice((event.target as HTMLSelectElement)?.value)
    setSelectedDate(null)
    setSelectedHour('')
    setDisabledButton(true)
  }

  const handleChangeDate = (value: dayjs.Dayjs | null): void => {
    setSelectedDate(value)
    setSelectedHour('')
    setDisabledButton(true)
  }

  const handleSelectedHour = (event: React.MouseEvent<HTMLElement>): void =>
    setSelectedHour((event.target as HTMLInputElement).value)

  const fetchAvailableHoursInADay = async (): Promise<void> => {
    try {
      setLoadAvailableHours(true)
      const data: IResponseCalendar<IAvailableHours> =
        await getAvailableHoursInADay({
          user_id: doctorId as string,
          date_search: selectedDate?.format('YYYY-MM-DD') as string,
          office_id: selectedOffice
        })
      const hours: string[] = (data.body as IAvailableHours).available_hours
      if (
        appointmentId &&
        selectedOffice === appointmentToReschedule?.office_id &&
        selectedDate?.format('YYYY-MM-DD') ===
          appointmentToReschedule?.appointment_date
      ) {
        const itemIndex = hours.findIndex((h) =>
          dayjs(`${selectedDate?.format('YYYY-MM-DD')}T${h}`).isAfter(
            dayjs(
              `${selectedDate?.format('YYYY-MM-DD')}T${
                appointmentToReschedule?.hour_from
              }`
            )
          )
        )
        if (itemIndex >= 0) {
          hours.splice(itemIndex, 0, appointmentToReschedule?.hour_from)
        } else {
          hours.push(appointmentToReschedule?.hour_from)
        }
      }
      const availableHours: IAvailableHours = data.body as IAvailableHours
      setAvailableHours({
        ...(data.body as IAvailableHours),
        available_hours: hours
      })
      setConsultationTimeMinutes(availableHours.consultation_time_minutes)
      setShowAvailableHours(true)
      setLoadAvailableHours(false)
    } catch (error) {
      console.error('Error in fetchAvailableHoursInADay:', error)
      setShowAvailableHours(true)
      setLoadAvailableHours(false)
    }
  }

  const onSubmit = async (e: { preventDefault(): void }): Promise<void> => {
    e.preventDefault()
    setSaveScheduleSelected()
    try {
      const validatePhone: ICodeUtility<IPhoneVerification> =
        await verifyPhoneValidationUtility(patient?.userId as string)
      if (
        Number(
          (validatePhone.data as IPhoneVerification).verification_status
        ) === 0
      ) {
        setPhoneVerification(validatePhone.data as IPhoneVerification)
      } else {
        setIsSubmitted(true)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    if (phoneVerification) {
      handleOpenModal()
    }

    if (isSubmitted) {
      if (appointmentId) {
        saveReschedulingAppointmentUtility({
          appointment_id: appointmentToReschedule?.appointment_id as string,
          appointment_date: PatientAppointment.date_appointment,
          hour_from: PatientAppointment.hour_appointment,
          office_id: PatientAppointment.office_id,
          modified_by: 'patient'
        })
          .then((result) => {
            if (result.status === 1) {
              handleAlert(true, result.data, 'success')
              navigate('/dates')
            }
          })
          .catch((error) => {
            console.error('Error in saveReschedulingAppointmentUtility:', error)
            handleAlert(true, error, 'error')
          })
          .finally(() => setIsSubmitted(false))
      } else {
        PostNewDateUtility({
          appointment_date: PatientAppointment.date_appointment,
          comment: PatientAppointment.comment,
          email: patient.email as string,
          hour_from: PatientAppointment.hour_appointment,
          hour_to: dayjs(
            `${PatientAppointment.date_appointment}T${PatientAppointment.hour_appointment}`
          )
            .add(PatientAppointment.consultation_time_minutes, 'minutes')
            .format('HH:mm'),
          office_id: PatientAppointment?.office_id,
          phone: patient.mobile,
          user_id: doctorId as string,
          reference: PatientAppointment.reference,
          patient_id: patient.userId,
          patient_id_number: patient.idNumber,
          type: 'patient'
        })
          .then((result) => {
            if (result.status === 1) {
              setOpenAlert(true)
              setMessageModal(result.data)
            }
          })
          .catch((error) => {
            console.error('Error guardando nuevo evento:', error)
          })
          .finally(() => setIsSubmitted(false))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    phoneVerification,
    PatientAppointment,
    isSubmitted,
    saveScheduleSelected,
    savelastSearch
  ])
  useEffect(() => {
    if (appointmentId) fetchAppointmentInfo(appointmentId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (appointmentToReschedule) {
      setSelectedOffice(appointmentToReschedule?.office_id)
      setComments(appointmentToReschedule?.comment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentToReschedule])

  useEffect(() => {
    if (appointmentId && selectedOffice === appointmentToReschedule?.office_id)
      setSelectedDate(dayjs(appointmentToReschedule?.appointment_date))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysAvailability])

  useEffect(() => {
    if (
      appointmentId &&
      selectedOffice === appointmentToReschedule?.office_id &&
      selectedDate?.format('YYYY-MM-DD') ===
        appointmentToReschedule?.appointment_date
    )
      setSelectedHour(appointmentToReschedule?.hour_from)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableHours])

  const isOptionDisabled = (hour: string): boolean =>
    Boolean(appointmentId) &&
    selectedOffice === appointmentToReschedule?.office_id &&
    selectedDate?.format('YYYY-MM-DD') ===
      appointmentToReschedule?.appointment_date &&
    hour === appointmentToReschedule?.hour_from

  return (
    <>
      <GridContent container>
        <GridWrapper
          item
          lg={3}
          style={{
            marginTop: '2%'
          }}
        >
          <ProfileBox>
            <AvatarProfile sx={{ width: '100px', height: '100px' }} />
          </ProfileBox>
        </GridWrapper>
        <GridHalfScreen
          item
          sm={4}
          style={{
            marginTop: '2%'
          }}
        >
          <BreadcrumbsBooking isReschedule={Boolean(appointmentId)} />
          <DoctorInfo
            message="Selecciona el dia y hora para tu cita con Dr. "
            info={dataDoctor}
            sx={{ width: '300px', fontSize: '1.18rem' }}
          />

          <GridWrapper
            sx={{ margin: '30px 0', display: 'grid', width: '450px' }}
          >
            <OfficeList
              handleChangeOffice={handleChangeOffice}
              office={selectedOffice}
              idDoctor={doctorId as string}
            />
          </GridWrapper>

          {loadCalendar && (
            <GridWrapper item display={'flex'} justifyContent={'center'}>
              <LoaderWrapper></LoaderWrapper>
            </GridWrapper>
          )}
          {!loadCalendar && showCalendar && (
            <CalendarInfo
              onChangeDate={handleChangeDate}
              selectedDate={selectedDate}
              daysAvailability={daysAvailability as ICalendarData}
            />
          )}
        </GridHalfScreen>

        <GridLayoutRight item sm={4}>
          {loadAvailableHours && (
            <GridWrapper item display={'flex'} justifyContent={'center'}>
              <LoaderWrapper></LoaderWrapper>
            </GridWrapper>
          )}
          {!loadAvailableHours && showAvailableHours && (
            <>
              <AvailableHours
                availableHours={availableHours as IAvailableHours}
                handleSelectedHour={handleSelectedHour}
                selectedHour={selectedHour}
                isOptionDisabled={isOptionDisabled}
              />
            </>
          )}
        </GridLayoutRight>
        <GridBookingForm
          item
          md={12}
          sx={{ position: 'relative', right: '9rem', marginTop: '2rem' }}
        >
          {!loadAvailableHours && showAvailableHours && (
            <BookigForm
              disabledButton={disabledButton}
              comments={comments}
              reference={reference}
              disabledForm={Boolean(appointmentId)}
              onSubmit={onSubmit}
              setComments={setComments}
              setReference={setReference}
            />
          )}
        </GridBookingForm>
      </GridContent>
      {openModal && (
        <ModalConfirmPhoneNumber
          handleClose={handleCloseModal}
          open={openModal}
          initialPhoneVerification={phoneVerification}
        />
      )}
      <Advert
        message={messageModal}
        onClose={handleCloseAlert}
        open={openAlert}
        title="Aviso"
      />
    </>
  )
}

export default BookingDate
