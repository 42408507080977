import { styled } from '@mui/material/styles'
import { GridWrapper } from '../../../../components'

export const GridContent = styled(GridWrapper)(() => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#F7F8FD',
  display: 'flex',
  justifyContent: 'center'
}))

export const GridLayoutLeft = styled(GridWrapper)(() => ({
  height: '100%',
  display: 'grid',
  alignContent: 'flex-start',
  justifyContent: 'space-around'
}))

export const GridLayoutRight = styled(GridWrapper)(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const GridLayoutBottom = styled(GridWrapper)(() => ({
  width: '100%',
  height: '111px',
  backgroundColor: '#F7F8FD',
  display: 'flex',
  justifyContent: 'center'
}))
