import { QualificationQuestionsRepositoryImpl } from './../../../infrastructure/RepositoryImpl/QualificationQuestionsRepositoryImpl'
import { IServiceYearsExperience } from './InterfaceServiceResponse'
import QualificationQuestionsMapper from '../../../infrastructure/http/mappers/QualificationQuestionsMappers'
import QualificationQuestions from '../../../domain/useCase/QualificationQuestions'

export async function QualificationQuestionsService(): Promise<IServiceYearsExperience> {
  try {
    const UseCaseQualificationQuestions = new QualificationQuestions(
      new QualificationQuestionsRepositoryImpl(
        new QualificationQuestionsMapper()
      )
    )
    return {
      status: 1,
      data: await UseCaseQualificationQuestions.invoke()
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error
    }
  }
}
