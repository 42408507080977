import { AxiosError } from 'axios'

import { http } from '../Http'
import ReschedulingAppointmentDataSource from '../dataSource/ReschedulingAppointmentDataSource'
import { ReschedulingAppointment, ResponseReschedulingAppointment } from '../../dtos/ReschedulingAppointment'

export default class ReschedulingAppointmentMapper
  implements ReschedulingAppointmentDataSource
{
  async saveReschedulingAppointment(
    dataReschedulingAppointment: ReschedulingAppointment
  ): Promise<ResponseReschedulingAppointment> {
    try {
      let { data } = await http.post<ResponseReschedulingAppointment>(
        `save_data/rescheduling_appointment`, { body: dataReschedulingAppointment }
      )
      return data
    } catch (error: unknown) {
      const data: ResponseReschedulingAppointment = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
      return data
    }
  }
}
