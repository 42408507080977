import { styled } from '@mui/material'
import ButtonWrapper from '../wrapper/ButtonWrapper'

export const WhiteButton = styled(ButtonWrapper)((props) => ({
  margin: '0 5px',
  textTransform: 'capitalize',
  borderRadius: '50px',
  backgroundColor: '#ffffff',
  color: props.theme.palette.primary.main,
  padding: '14px 24px',
  border: '1px solid #ffffff',
  '&:hover': {
    backgroundColor: '#efefef'
  }
}))
