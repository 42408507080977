import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GoogleMap from './Components/GoogleMap'
import { ISearchDoctor } from "../../../infrastructure/dtos/DoctorInfo";
import { IAddressPatient } from "../../Register/types";

interface DoctorResultsGoogleMapProps {
  results: ISearchDoctor[]
  selectedAddress: IAddressPatient | undefined
}


export default function DoctorResultsGoogleMap({results, selectedAddress}: DoctorResultsGoogleMapProps) {
  //Interface for MapView
  //apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""}

  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <h2>Loading</h2>;
      case Status.FAILURE:
        return <h2>Error</h2>;
      case Status.SUCCESS:
        return <GoogleMap results={results} selectedAddress={selectedAddress}/>;
    }
  };

  return (
    <>
      <Wrapper
        apiKey="AIzaSyA6dlQ5VQlycFtui2MCzPoMbnPji7sLiJw"
        version="beta"
        libraries={["marker"]}
        id="container_maps_wrapper"
        render={render}
      ></Wrapper>
    </>
  );
}