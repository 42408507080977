import { GridWrapper } from '../../../../components'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import PhotoDoctor from '../PhotoDoctor'

interface ILeadingDoctorsProps {
  doctors: string[]
}

export default function LeadingDoctors({ doctors }: ILeadingDoctorsProps): JSX.Element {
  return (
    <GridWrapper sx={{ marginTop: "1.5rem" }}>
      <GridWrapper container={true} justifyContent="center" alignItems="center">
        <GridWrapper item>
          <LabelInput textAlign="center" >Conoce los médicos destacados</LabelInput>
        </GridWrapper>
      </GridWrapper>
      <GridWrapper container={true}>
        <GridWrapper item sm={4} sx={{ height: '100%', padding: "1.5rem", marginTop: "1rem" }}>
          <PhotoDoctor
            photo={""}
            rating='5'
          />
        </GridWrapper>
      </GridWrapper>
    </GridWrapper>
  )
}
