import { DialogContentText, DialogContentTextProps } from "@mui/material";
import React from "react";



const DialogContentTextWrapper: React.FC<DialogContentTextProps> = ({ ...props }) => (
  <DialogContentText {...props} />
);

export default DialogContentTextWrapper;
