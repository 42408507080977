import GridWrapper from '../../../../components/wrapper/GridWrapper'
import { CardInfo } from '../../../../components/StyledComponents/CardInfo'
import { CustomAvatar } from '../../../../components/StyledComponents/CustomAvatar'
import { StyledCardHeader } from './styles'
import { ListItemWrapper, ListWrapper } from '../../../../components'
import { ISearchDoctor } from '../../../../infrastructure/dtos/DoctorInfo'
import CardContentWrapper from '../../../../components/wrapper/CardContentWrapper'

interface ICardDoctorProps {
  doctor: ISearchDoctor
}

function CardDoctor({ doctor }: ICardDoctorProps): JSX.Element {
  return (
    <>
      <CardInfo id="card-info">
        <GridWrapper container spacing={2}>
          <GridWrapper item>
            <CustomAvatar sx={{ margin: '10% 0' }} src={doctor.photo_doctor} alt={doctor.name_doctor}/>
          </GridWrapper>
          <GridWrapper item xs={12} sm container>
            <GridWrapper item container direction="column" spacing={2}>
              <GridWrapper item>
                <StyledCardHeader
                  title={`Dr. ${doctor.name_doctor}`}
                  subheader={doctor.specialty_doctor}
                />
                <CardContentWrapper>
                  <ListWrapper sx={{ fontSize: '12px', padding: '0px' }}>
                    <ListItemWrapper>Direccion: </ListItemWrapper>
                    <ListItemWrapper>Proxima disponibilidad:</ListItemWrapper>
                    <ListItemWrapper>Atenciones:</ListItemWrapper>
                  </ListWrapper>
                </CardContentWrapper>
              </GridWrapper>
            </GridWrapper>
          </GridWrapper>
        </GridWrapper>
      </CardInfo>
    </>
  )
}

export default CardDoctor
