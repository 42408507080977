import { styled } from '@mui/material'
import GridWrapper from '../../../components/wrapper/GridWrapper'
import { BoxWrapper } from '../../../components'

export const GridBookingForm = styled(GridWrapper)(() => ({
  width: '100%',
  height: '111px',
  backgroundColor: '#F7F8FD',
  display: 'flex',
  justifyContent: 'right'
}))

export const ProfileBox = styled(BoxWrapper)(() => ({
  backgroundColor: 'white',
  marginRight: '30%',
  borderRadius: '30px',
  display: 'flex',
  justifyContent: 'center',
  padding: '20px'
}))
