import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { GridWrapper } from '../../components'
import CircularProgressWrapper from '../../components/wrapper/LoaderWrapper'
import { useEffect, useState } from 'react'
import {
  IAppointmentStatus,
  IConfirmAppointmentProps
} from '../../infrastructure/dtos/Appointments'
import { confirmAppointmentUtility } from '../../services/Contracts/Utility/AppointmentsUtility'

export function ConfirmAppointment(): JSX.Element {
  const { appointmentId, rescheduleId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [params] = useState<IConfirmAppointmentProps>({
    action: searchParams.get('action') as string,
    appointment_id: appointmentId as string,
    reschedule_id: rescheduleId as string
  })

  const fetchConfirmAppointment = async (): Promise<void> => {
    if (params.action === 'reschedule') {
      navigate('/')
      return
    }
    try {
      const { data } = await confirmAppointmentUtility(params)
      navigate('/', { state: data as IAppointmentStatus })
    } catch (error) {
      console.error('error in fetchConfirmAppointment:', error)
    }
  }

  useEffect(() => {
    if (params.action && params.appointment_id) fetchConfirmAppointment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <GridWrapper textAlign={'center'} marginTop={4}>
      <CircularProgressWrapper data-testid="loading-spinner" />
    </GridWrapper>
  )
}
