import {
  ISearchDoctorParams,
  ISearchDoctorResponse,
  ISuggestedDoctorsParams,
  ISuggestedDoctors,
  IApiResponseDoctorInfo
} from "../../../infrastructure/dtos/DoctorInfo";
import {
  IDoctorService,
  SearchDoctorService,
  GetSuggestedDoctorsService,
  ISuggestedDoctorsService
} from "../Persistencia/DoctorInfoService";

export interface IDoctorUtility<T> {
  status: number;
  data: string | T;
}

export async function SearchDoctorUtility(
  params: ISearchDoctorParams
): Promise<IDoctorUtility<ISearchDoctorResponse>> {
  try {
    const { data, status }: IDoctorService = await SearchDoctorService(params)
    if (
      typeof data !== "string" &&
      data.statusCode === 200 &&
      status === 1
    ) {
      return {
        data: data.body as ISearchDoctorResponse,
        status,
      };
    }
    return {
      data: data as string,
      status,
    };
  } catch (error: any) {
    return {
      status: 0,
      data: error?.message,
    };
  }
}


export async function getSuggestedDoctorsUtility(
  params: ISuggestedDoctorsParams
): Promise<IDoctorUtility<ISuggestedDoctors[]>> {
  try {
    const { data, status }: ISuggestedDoctorsService = await GetSuggestedDoctorsService(params)
    if (
      typeof data !== "string" &&
      data.statusCode === 200 &&
      status === 1
    ) {
      return {
        data: data.body as ISuggestedDoctors[],
        status,
      };
    }
    return {
      data: (data as IApiResponseDoctorInfo).body as string,
      status,
    };
  } catch (error: any) {
    return {
      status: 0,
      data: error?.message,
    };
  }
}
