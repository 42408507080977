import { useContext, useState } from 'react'
import { SearchDoctorUtility } from '../../../services/Contracts/Utility/DoctorInfoUtility'
import {
  ISearchDoctor,
  ISearchDoctorResponse
} from '../../../infrastructure/dtos/DoctorInfo'
import { IAddressPatient } from '../../Register/types'
import { SelectChangeEvent } from '@mui/material'
import { SearchOfficesContext } from '../../../contexts/SeachOfficesContext'

export interface IPagination {
  page: number
  limit: number
  totalPages: number
}

interface IUseSearchDoctorHook<T> {
  showResults: boolean
  searching: boolean
  submitted?: boolean
  results: ISearchDoctor[]
  pagination: IPagination
  doctorName: string
  specialty: string
  address: T | null
  addressData?: IAddressPatient
  showAppointmentList: boolean
  handleSubmit(event: { preventDefault: () => void }): void
  handleChangePage(event: React.ChangeEvent<unknown>, value: number): void
  handleSearchDoctor(): Promise<void>
  handleSubmitted(value: boolean): void
  onChangeSpecialties(value: string): void
  onChangeDoctorName(value: string): void
  onChangeAddress(event: SelectChangeEvent<unknown>): void
  onChangeAddressData(value: IAddressPatient | undefined): void
  handleShowAppointmentList(value: boolean): void
  handleShowResults(value: boolean): void
}

export function useSearchDoctor<T>(): IUseSearchDoctorHook<T> {
  const { savelastSearch, searchOffices } = useContext(SearchOfficesContext)
  const [showResults, setShowResults] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [showAppointmentList, setShowAppointmentList] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean | undefined>(undefined)
  const [results, setResults] = useState<ISearchDoctor[]>([])
  const [pagination, setPagination] = useState<IPagination>({
    page: 0,
    limit: 10,
    totalPages: 10
  })
  const [specialty, setSpecialty] = useState<string>(searchOffices.speciality)
  const [address, setAddress] = useState<T | null>(
    searchOffices.address as unknown as null
  )
  const [addressData, setAddressData] = useState<IAddressPatient | undefined>(
    searchOffices.addressData
  )
  const [doctorName, setDoctorName] = useState<string>(
    searchOffices.doctor_name
  )

  const onChangeSpecialties = (value: string): void => setSpecialty(value)
  const onChangeDoctorName = (value: string): void => setDoctorName(value)
  const onChangeAddress = (event: SelectChangeEvent<unknown>): void => {
    setAddress(event.target.value as T)
  }
  const onChangeAddressData = (value: IAddressPatient | undefined): void =>
    setAddressData(value)

  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    setPagination({
      ...pagination,
      page: 1
    })
    savelastSearch({
      doctor_name: doctorName,
      address: address as string,
      speciality: specialty,
      addressData: addressData
    })
    handleSubmitted(true)
  }

  const handleSearchDoctor = async (): Promise<void> => {
    setSearching(true)
    const { data } = await SearchDoctorUtility({
      page: pagination.page,
      limit: pagination.limit,
      specialty,
      doctorName
    })
    setResults((data as ISearchDoctorResponse).data)
    setPagination({
      ...pagination,
      totalPages: (data as ISearchDoctorResponse).pageTotal
    })
    setSearching(false)
    handleShowResults(true)
    handleSubmitted(false)
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ): void => setPagination({ ...pagination, page: value })

  const handleSubmitted = (value: boolean): void => setSubmitted(value)
  const handleShowAppointmentList = (value: boolean): void => setShowAppointmentList(value)
  const handleShowResults = (value: boolean): void => setShowResults(value)

  return {
    showResults,
    searching,
    submitted,
    results,
    pagination,
    specialty,
    doctorName,
    address,
    addressData,
    showAppointmentList,
    handleShowAppointmentList,
    handleShowResults,
    handleSubmit,
    handleChangePage,
    handleSearchDoctor,
    handleSubmitted,
    onChangeSpecialties,
    onChangeDoctorName,
    onChangeAddress,
    onChangeAddressData
  }
}
