// Componente numero 9
import { InputText } from '../FormInputText'
import { InputField } from '../StyledComponents/InputField'
import { LabelInput } from '../StyledComponents/LabelInput'
import { IconButtonWrapper, InputAdornmentWrapper } from '../wrapper'
import { VisibilityIcon, VisibilityOffIcon } from '../icons'

export interface InputIcon extends InputText {
  onClick: () => void
  showPassword: boolean
}

const FormInputIconText = ({
  id,
  type,
  placeholder,
  fullWidth,
  name,
  value,
  onChange,
  onClick,
  showPassword,
  text,
  helperText,
  error
}: InputIcon): JSX.Element => {
  return (
    <>
      <LabelInput>{text}</LabelInput>

      <InputField
        id={id}
        type={type}
        placeholder={placeholder}
        fullWidth={fullWidth}
        name={name}
        value={value}
        onChange={onChange}
        helperText={helperText}
        error={error}
        InputProps={{
          endAdornment: (
            <InputAdornmentWrapper position="end">
              <IconButtonWrapper onClick={onClick}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButtonWrapper>
            </InputAdornmentWrapper>
          )
        }}
      />
    </>
  )
}

export default FormInputIconText
