import { BackdropWrapper, ModalWrapper } from '../../../../../../../components'
import CircularProgressWrapper from '../../../../../../../components/wrapper/LoaderWrapper'
import {
  GridModal,
  BoxPatientRecipeModal,
  FadePatientRecipeModal,
} from './LoadingRecipeStyle'

interface PatientRecipesModalProps {
  handleOpenLoadingRecipesModal: (value: boolean) => void
  open: boolean
}

export function LoadingRecipesListModal({
  handleOpenLoadingRecipesModal,
  open,
}: PatientRecipesModalProps): JSX.Element {
  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-loading-recipes-list"
      open={open}
      onClose={() => handleOpenLoadingRecipesModal(false)}
      closeAfterTransition
      slots={{ backdrop: BackdropWrapper }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <FadePatientRecipeModal in={open}>
        <BoxPatientRecipeModal>
          <GridModal display={'grid'} sx={{ placeContent: 'center' }}>
            <CircularProgressWrapper />
          </GridModal>
        </BoxPatientRecipeModal>
      </FadePatientRecipeModal>
    </ModalWrapper>
  )
}
