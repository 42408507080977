import { DoctorInfoRepository } from '../../domain/repository/DoctorInfoRepository'
import { IApiResponseDoctorInfo, ISearchDoctorParams, ISuggestedDoctorsParams } from '../dtos/DoctorInfo'
import DoctorInfoDataSource from '../http/dataSource/DoctorInfoDataSource'

export class DoctorInfoRepositoryImpl implements DoctorInfoRepository {
  dataSource: DoctorInfoDataSource

  constructor(_datasource: DoctorInfoDataSource) {
    this.dataSource = _datasource
  }

  async getDoctorInfo(idDoctor: string): Promise<IApiResponseDoctorInfo> {
    return this.dataSource.getDoctorInfo(idDoctor)
  }

  async searchDoctor(params: ISearchDoctorParams): Promise<IApiResponseDoctorInfo> {
    return this.dataSource.searchDoctor(params)
  }

  getSuggestedDoctors(params: ISuggestedDoctorsParams): Promise<IApiResponseDoctorInfo> {
    return this.dataSource.getSuggestedDoctors(params)
  }
}
