import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Alert } from '@mui/material'
import { Severity } from '../../contexts/AuthReducer'

interface ICustomSnackbarProps {
  openAlert: boolean
  message: string
  severity?: Severity
  handleAlert(open: boolean, message: string, severity?: Severity): void
}
export default function CustomSnackbar({
  openAlert,
  message,
  severity = 'info',
  handleAlert
}: ICustomSnackbarProps) {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return
    }
    handleAlert(false, '')
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <Snackbar
      open={openAlert}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      data-testid="snackbar-message"
      sx={{ top: { xs: 130 }, left: { xs: 130 } }}
    >
      <Alert action={action} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}
