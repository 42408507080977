import { Rating, RatingProps } from '@mui/material'
import React from 'react'

const RatingWrapper: React.ForwardRefExoticComponent<
  Omit<RatingProps, 'ref'> & React.RefAttributes<any>
> = React.forwardRef<any, RatingProps>((props, ref) => (
  <Rating {...props} ref={ref} />
))

export default RatingWrapper
