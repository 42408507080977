import { useEffect, useState } from 'react'
//import { useGeolocation } from "@uidotdev/usehooks";

interface ICoordinates {
  latitud: number
  longitud: number
}
interface ICoordinatesType {
  coordinates: ICoordinates
  geolocationPosition: () => void
}

export const Geolocation = (): ICoordinatesType => {
  //tener cargado las coordenadas
  //const state = useGeolocation();
  const [coordinates, setCoordinates] = useState<ICoordinates>({
    latitud: -2.1528576,
    longitud: -79.9244288
  })
  //traer datos desde la api para retornarlas al constructor
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  }
  function success(pos: GeolocationPosition): void {
    var crd = pos.coords
    setCoordinates({
      latitud: crd.latitude,
      longitud: crd.longitude
    })
  }
  function errors(err: any): void {
    console.warn(`ERROR(${err.code}): ${err.message}`)
  }
  const geolocationPosition = (): void => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options)
          } else if (result.state === 'prompt') {
            //If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options)
          } else if (result.state === 'denied') {
            //If denied then you have to show instructions to enable location
          }
        })
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }
  useEffect(() => {
    geolocationPosition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    coordinates,
    geolocationPosition
  }
}
