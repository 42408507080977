import { GetCalendarInfo } from '../../../domain/useCase/GetCalendarInfo'
import { CalendarInfoRepositoryImpl } from '../../../infrastructure/RepositoryImpl/CalendarInfoRepositoryImpl'
import CalendarInfoMapper from '../../../infrastructure/http/mappers/CalendarInfoMapper'
import { IServiceYearsExperience } from './InterfaceServiceResponse'

export async function GetDaysAvailabilityInAMonth(
  idDoctor: string,
  idOffice: string
): Promise<IServiceYearsExperience> {
  try {
    const UseCaseCalendar = new GetCalendarInfo(
      new CalendarInfoRepositoryImpl(new CalendarInfoMapper())
    )
    return {
      status: 1,
      data: await UseCaseCalendar.invoke(idDoctor, idOffice)
    }
  } catch (error) {
    return {
      status: 0,
      data: error
    }
  }
}
