import { GetPatientInfo } from "../../../domain/useCase/GetPatientInfo";
import { PatientInfoRepositoryImpl } from "../../../infrastructure/RepositoryImpl/PatientRepositoryImpl";
import { IServicePatientInfo } from "../../../infrastructure/dtos/Patient";
import PatientInfoMapper from "../../../infrastructure/http/mappers/PatientMapper";

export async function GetPatientInfoService(
  idPatient: string
): Promise<IServicePatientInfo> {
  try {
    const UseCasePatient = new GetPatientInfo(
      new PatientInfoRepositoryImpl(new PatientInfoMapper())
    );
    return {
      status: 1,
      data: await UseCasePatient.invoke(idPatient),
    };
  } catch (error) {
    console.log("🚀 ~ file: PatientService.ts:18 ~ error:", error);
    return {
      status: 0,
      data: "Error Service",
    };
  }
}
