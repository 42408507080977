import { IResponseGetCalendarEvent } from "../../dtos/CalendarInfo";
import { http } from "../Http";
import CalendarInfoDataSource from "../dataSource/CalendarInfoDataSource";

export default class CalendarInfoMapper implements CalendarInfoDataSource {
  async getCalendarInfo(
    idDoctor: string,
    idOffice: String
  ): Promise<IResponseGetCalendarEvent> {
    let data = await http
      .get(`/send_data/schedule_availability_month/${idOffice}/${idDoctor}/1`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        const data = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            "Access-Control-Allow-Headers": "",
            "Access-Control-Allow-Origin": "",
            "Access-Control-Allow-Methods": "",
            "Access-Control-Allow-Credentials": "",
          },
        };
        return data;
      });
    return data;
  }
}
