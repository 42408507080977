import { PostRegisterPatient } from "../../../domain/useCase/PostRegisterPatient";
import { PatientRegisterRepositoryImpl } from "../../../infrastructure/RepositoryImpl/PatientRegisterRepositoryImpl";
import {
  ISaveRegisterPatient,
  IServiceRegisterMedical,
} from "../../../infrastructure/dtos/PatientRegister";
import PatientRegisterMapper from "../../../infrastructure/http/mappers/PatientRegisterMapper";

export async function PostRegisterPatientService(
  data: ISaveRegisterPatient
): Promise<IServiceRegisterMedical> {
  try {
    const UseCaseApi = new PostRegisterPatient(
      new PatientRegisterRepositoryImpl(new PatientRegisterMapper())
    );
    return {
      status: 1,
      data: await UseCaseApi.invoke(data),
    };
  } catch (error) {
    return {
      status: 0,
      data: {
        statusCode: 503,
        body: (error as Error).message,
        headers: "",
      },
    };
  }
}
