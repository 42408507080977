import React, { useContext, useState } from 'react'
import {
  BoxWrapper,
  GridWrapper,
  InputAdornmentWrapper
} from '../../components'
import { Pendings } from './Pendings/PendingsComponent'
import { AvatarProfile } from '../../components/StyledComponents/AvatarProfile'
import { CommunityHomeBox, HomeBox, SmallHomeBox } from './styles'
import { Title } from '../../components/StyledComponents/Title'
import FormTextInput from '../../components/FormInputText'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { LabelInput } from '../../components/StyledComponents/LabelInput'
import { AccountContext, AuthContextType } from '../../contexts/AccountContext'
import NextDatesList from '../pages/Dates/components/NextDatesList/NextDatesList'

const Home = (): JSX.Element => {
  const [search, setSearch] = useState<string>('')
  const { patient } = useContext(AccountContext) as AuthContextType
  return (
    <BoxWrapper flexGrow={1} paddingY={'20px'} paddingX={'32px'}>
      <GridWrapper container spacing={2} columns={16}>
        <GridWrapper item xs={4}>
          <HomeBox>
            <AvatarProfile
              data-testid="avatar-profile"
              sx={{ width: '80px', height: '80px' }}
            />
          </HomeBox>
          <SmallHomeBox data-testid="small-home-box" />
        </GridWrapper>
        <GridWrapper item xs={12}>
          <Title sx={{ textAlign: 'center' }}>
            Hola {patient?.name}, busca por médico o especialidad y agenda tu cita
          </Title>
          <BoxWrapper sx={{ flexGrow: 1 }}>
            <GridWrapper container spacing={2}>
              <GridWrapper item xs={12}>
                <FormTextInput
                  placeholder="Pediatra"
                  id="home-search"
                  type="text"
                  fullWidth={true}
                  name="home-search"
                  onChange={(event) => setSearch(event.target.value)}
                  value={search}
                  inputProps={{
                    adornment: (
                      <InputAdornmentWrapper position="start">
                        <SearchOutlinedIcon />
                      </InputAdornmentWrapper>
                    )
                  }}
                />
              </GridWrapper>
              <GridWrapper item xs={12}>
                <LabelInput>Citas</LabelInput>
                <NextDatesList fromHome={true} />
              </GridWrapper>
              <GridWrapper item xs={5}>
                <LabelInput>Comunidad</LabelInput>
                <CommunityHomeBox />
              </GridWrapper>
              <GridWrapper item xs={7}>
                <LabelInput>Medicos sugeridos para ti</LabelInput>
              </GridWrapper>
              <GridWrapper item xs={5}>
                <CommunityHomeBox />
              </GridWrapper>
              <GridWrapper item xs={7}>
                <LabelInput>Tus pendientes</LabelInput>
                <Pendings />
              </GridWrapper>
            </GridWrapper>
          </BoxWrapper>
        </GridWrapper>
      </GridWrapper>
    </BoxWrapper>
  )
}

export default Home
