import { styled } from '@mui/material'
import SelectWrapper from '../wrapper/SelectWrapper'

export const SelectInput = styled(SelectWrapper)((props) => ({
  minWidth: '170px',
  borderRadius: '42px',
  backgroundColor: '#fff',
  height: '3.4375rem',
  width: '400px',
  '& fieldset': { border: 'none' },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '5px'
    },
    '& img': {
      marginRight: '7px',
      width: '1.4rem',
      height: '1.4rem',
    }
  },
  '& .MuiSelect-iconOutlined': {
    borderRadius: '50%',
    width: '30px',
    backgroundColor: 'white',
  }
}))
