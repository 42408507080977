// Componente numero 3

import { PrimaryButton } from '../StyledComponents/PrimaryButton'
import { SxProps } from '@mui/material'

interface Props {
  id: string
  variant: 'text' | 'outlined' | 'contained' | undefined
  fullWidth: boolean
  type: 'button' | 'reset' | 'submit' | undefined
  text: string
  disabled?: boolean
  dataTestid?: string
  onClick?: () => void
  sx?: SxProps
  size?: 'small' | 'medium' | 'large'
}

const SubmitButton = ({
  variant,
  fullWidth,
  type,
  text,
  disabled,
  dataTestid,
  onClick,
  sx,
  size = 'medium'
}: Props) => {
  return (
    <PrimaryButton
      variant={variant}
      fullWidth={fullWidth}
      type={type}
      disabled={disabled}
      data-testid={dataTestid}
      onClick={onClick}
      sx={sx}
      size={size}
    >
      {text}
    </PrimaryButton>
  )
}

export default SubmitButton
