import Box from "@mui/material/Box";
import { DividerWrapper, GridWrapper } from "../../../components/wrapper";
import PhotoDoctor from "../../ScheduleAppointment/components/PhotoDoctor";
import { LabelText } from "../../ScheduleAppointment/components/DataDoctor/styles";
import {
  GridList,
  ListContent,
  ListTitle,
  SubTitle,
  TypoDetailsInfo,
  TypoDetailsTitle,
} from "./styles";
import { Divider, IconButton } from "@mui/material";
import { IDataLocation } from "../DoctorResultsGoogleMap/Components/LocationDoctor";
import { IParamsGetNextAppointmentHour } from "../../../infrastructure/dtos/Calendar";
import CircularProgressWrapper from "../../../components/wrapper/LoaderWrapper";
import ClearIcon from "@mui/icons-material/Clear";
import SubmitButton from "../../../components/SubmitButton";
import { Title } from "../../../components/StyledComponents/Title";
import PanelMap from "./components/PanelMap";
import { MedicalOffice } from "../../../infrastructure/dtos/Offices";
import { RecommendedDoctors } from "./components/RecommendedDoctors";
import { USDollar } from "../../../utils/currency";
import { IAddressPatient } from "../../Register/types";
import { LabelInputError } from "../../../components/StyledComponents/LabelInputError";
import { useDoctorDetailsRightPanel } from "./Hook";

export interface DoctorDetailsRightPanelProps {
  toggleDrawer(open: boolean, location: IDataLocation | undefined): void;
  doctor?: IParamsGetNextAppointmentHour;
  selectedPatientAddress?: IAddressPatient;
}

export default function DoctorDetailsRightPanel({
  toggleDrawer,
  doctor,
  selectedPatientAddress,
}: DoctorDetailsRightPanelProps): JSX.Element {
  const { doctorInfo, loading, suggestedDoctors, navigate, selectedMarker } =
    useDoctorDetailsRightPanel({
      toggleDrawer,
      doctor,
      selectedPatientAddress,
    });

  return (
    <Box
      sx={{ width: 500, padding: "0 20px" }}
      role="presentation"
      data-testid="doctor-right-panel"
    >
      {!loading ? (
        <GridWrapper container={true}>
          <GridWrapper item sm={12} sx={{ marginTop: "14px" }}>
            <IconButton onClick={() => toggleDrawer(false, undefined)}>
              <ClearIcon sx={{ color: "#6191FE" }} />
            </IconButton>
          </GridWrapper>
          <GridWrapper
            item
            sm={4}
            sx={{ height: "100%", padding: "1rem", marginTop: "0.2srem" }}
          >
            <PhotoDoctor
              photo={doctorInfo?.medical?.photo_doctor as string}
              rating={doctorInfo?.medical?.rating?.toString() as string}
            />
          </GridWrapper>
          <GridWrapper
            item
            sm={7}
            sx={{ height: "100%", padding: "1rem", marginTop: "0.2rem" }}
          >
            <LabelText data-testid='doctor-name'>
              Dr.{" "}
              {`${doctorInfo?.medical?.name_doctor} ${doctorInfo?.medical?.last_name_doctor}`}
            </LabelText>
            <SubTitle>
              {doctorInfo?.medical?.speciality_doctor?.toString()}
            </SubTitle>
            <GridWrapper
              item
              sm={12}
              sx={{ height: "100%", marginTop: "1rem" }}
            >
              <SubTitle>
                <b>Años de experiencia:</b>{" "}
                {doctorInfo?.medical?.years_experience_doctor}
              </SubTitle>
              <SubTitle>
                <b>Estudios:</b> {doctorInfo?.medical?.university_doctor}
              </SubTitle>
              <SubTitle>
                <b>Dirección seleccionada:</b>{" "}
                {doctorInfo?.office_selected?.reference_medical_office}
              </SubTitle>
              <SubTitle>
                <b>Atenciones:</b> {doctorInfo?.medical?.people_attended}
              </SubTitle>
            </GridWrapper>
          </GridWrapper>
          <GridWrapper
            item
            sm={12}
            sx={{ height: "100%", padding: "0.4rem 1.5rem 1rem 1.5rem" }}
          >
            <PanelMap
              offices={doctorInfo?.data_office as MedicalOffice[]}
              selectedMarker={(officeId: string) => selectedMarker(officeId)}
            />
          </GridWrapper>
          <GridWrapper
            item
            sm={12}
            sx={{ height: "100%", padding: "0.2rem 1.5rem" }}
          >
            <GridWrapper
              container
              sx={{ backgroundColor: "#F7F8FD", padding: 1, borderRadius: 3 }}
              width={"100%"}
              justifyContent={"center"}
            >
              <GridWrapper
                item
                padding={1}
                width={"22%"}
                display={"flex"}
                flexDirection={"column"}
              >
                <TypoDetailsTitle>Distancia</TypoDetailsTitle>
                <GridWrapper
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"100%"}
                >
                  <TypoDetailsInfo data-testid="test-distance">
                    {doctorInfo?.distance || "-"}
                  </TypoDetailsInfo>
                </GridWrapper>
              </GridWrapper>
              <DividerWrapper
                orientation="vertical"
                variant="middle"
                flexItem
              />
              <GridWrapper
                item
                padding={1}
                width={"35%"}
                display={"flex"}
                flexDirection={"column"}
              >
                <TypoDetailsTitle>Precio de la consulta</TypoDetailsTitle>
                <GridWrapper
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"100%"}
                >
                  <TypoDetailsInfo>
                    {USDollar.format(
                      Number(doctorInfo?.office_selected?.consultation_value) ||
                        0
                    )}
                  </TypoDetailsInfo>
                </GridWrapper>
              </GridWrapper>
              <DividerWrapper
                orientation="vertical"
                variant="middle"
                flexItem
              />
              <GridWrapper
                item
                padding={1}
                width={"40%"}
                display={"flex"}
                flexDirection={"column"}
                data-testid="next-date-text"
              >
                <TypoDetailsTitle>Próxima cita disponible</TypoDetailsTitle>
                <GridWrapper
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"100%"}
                >
                  <TypoDetailsInfo>{doctorInfo?.result}</TypoDetailsInfo>
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>
            {!selectedPatientAddress && (
              <LabelInputError
                sx={{ color: "primary.dark", fontWeight: "bold" }}
                textAlign={"center"}
                data-testid="no-address-selected-message"
              >
                Ingresa una dirección en la búsqueda para conocer la distancia
                del consultorio
              </LabelInputError>
            )}
          </GridWrapper>
          <GridWrapper
            item
            sm={12}
            sx={{
              height: "100%",
              padding: "0.2rem 1.5rem",
              paddingBottom: "1.5rem",
            }}
          >
            {doctorInfo?.nearby_office && (
              <GridList item sx={{ display: "block" }}>
                <ListTitle
                  variant="h5"
                  sx={{ position: "relative", top: "30px" }}
                >
                  Ubicación más cercana
                </ListTitle>
                <ListContent variant="h6">
                  {doctorInfo?.nearby_office}
                </ListContent>
                <Divider />
              </GridList>
            )}
            <GridList item sx={{ display: "block" }}>
              <ListTitle
                variant="h5"
                sx={{ position: "relative", top: "30px" }}
              >
                {selectedPatientAddress
                  ? "Otras ubicaciones"
                  : "Ubicación de consultorios"}
              </ListTitle>
              {doctorInfo?.others_office?.length ? (
                doctorInfo?.others_office?.map(
                  (item: string, index: number) => (
                    <ListContent key={index} variant="h6">
                      {item}
                    </ListContent>
                  )
                )
              ) : (
                <ListContent variant="h6">Sin Consultorios</ListContent>
              )}
            </GridList>
            <Divider />
          </GridWrapper>
          <GridWrapper container width="100%" display={"grid"}>
            <GridWrapper
              item
              width="80%"
              display={"flex"}
              justifySelf={"center"}
            >
              <SubmitButton
                id={"avoid-date"}
                variant={"contained"}
                type={"button"}
                text={"Separar cita"}
                fullWidth
                sx={{ fontSize: "13px" }}
                onClick={() => {
                  navigate(
                    `/appointment/booking/${doctorInfo?.medical?.user_id}`
                  );
                }}
              />
            </GridWrapper>
            {typeof suggestedDoctors !== "string" &&
              suggestedDoctors?.length > 0 && (
                <GridWrapper
                  item
                  marginTop={2}
                  marginBottom={4}
                  data-testid="suggested-doctors-title"
                >
                  <Title sx={{ fontSize: "16px" }} paddingX={"1.5rem"}>
                    Médicos con disponibilidad cercana
                  </Title>
                  <RecommendedDoctors doctors={suggestedDoctors} getDoctorInfo={selectedMarker}/>
                </GridWrapper>
              )}
          </GridWrapper>
        </GridWrapper>
      ) : (
        <GridWrapper
          item
          display={"Flex"}
          justifyContent={"center"}
          paddingTop={5}
        >
          <CircularProgressWrapper />
        </GridWrapper>
      )}
    </Box>
  );
}
