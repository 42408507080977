import CloseIcon from '@mui/icons-material/Close'
import {
  BackdropWrapper,
  GridWrapper,
  ModalWrapper
} from '../../../../../../components'
import {
  BoxCancelModal,
  CloseButton,
  FadeCancelModal,
  TypographyCancelModal
} from './CancelAppointmentStyle'
import { SecondaryMediumButton } from '../../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { confirmAppointmentUtility } from '../../../../../../services/Contracts/Utility/AppointmentsUtility'
import { useContext, useState } from 'react'
import {
  AccountContext,
  AuthContextType
} from '../../../../../../contexts/AccountContext'
import SubmitButton from '../../../../../../components/SubmitButton'

interface IAppointment {
  appointment_id: string
  name_doctor: string
  last_name_doctor: string
  doctor_id?: string
  user_id?: string
  patient_id?: string
  reschedule_id: string
}

interface ConfirmAppointmentsProps {
  handleClose: () => void
  open: boolean
  appointment?: IAppointment
  fetchAppointments: (userId: string) => Promise<void>
}

export const CancelAppointment = ({
  handleClose,
  open,
  appointment,
  fetchAppointments
}: ConfirmAppointmentsProps): JSX.Element => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const jsonButton = {
    textFalse: 'No, deseo mantenerla',
    textTrue: 'Sí, deseo cancelarla'
  }
  const [textTrue] = useState(jsonButton.textTrue)
  const [textFalse] = useState(jsonButton.textFalse)

  const handleConfirm = async (): Promise<void> => {
    const response = await confirmAppointmentUtility({
      action: 'cancelled',
      appointment_id: appointment?.appointment_id ?? '',
      reschedule_id: appointment?.reschedule_id ?? ''
    })
    let message: string = ''
    if (typeof response.data !== 'string') {
      message = response.data.message
    }
    handleClose()
    await fetchAppointments(appointment?.patient_id as string)
    handleAlert(true, message, 'success')
  }

  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-cancel-appointment"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: BackdropWrapper }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <FadeCancelModal in={open}>
        <BoxCancelModal>
          <GridWrapper padding={5}>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            <GridWrapper padding={2}>
              <TypographyCancelModal
                id="transition-modal-title"
                variant="h6"
                data-testid="modal-cancel-appointment-title"
              >
                ¿Seguro deseas cancelar la cita?
              </TypographyCancelModal>
              <GridWrapper
                container
                display={'flex'}
                justifyContent={'center'}
                gap={'23px'}
                mt={'50px'}
              >
                <SecondaryMediumButton
                  fullWidth
                  id="cancelAppointmentButton"
                  text={textTrue}
                  type="button"
                  variant="contained"
                  xs={5}
                  onClick={handleConfirm}
                />
                <GridWrapper item xs={5}>
                  <SubmitButton
                    id="keppAppointmentButton"
                    data-testid="keppAppointmentButton"
                    text={textFalse}
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={handleClose}
                  />
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>
          </GridWrapper>
        </BoxCancelModal>
      </FadeCancelModal>
    </ModalWrapper>
  )
}
