import { IApiResponseSpecialties } from "../../infrastructure/dtos/Specialties";
import { SpecialtiesRepository } from "../repository/SpecialtiesRepository";


export interface GetSpecialtiesUseCase {
    invoke: () => Promise<IApiResponseSpecialties>
}


export class GetSpecialties implements GetSpecialtiesUseCase {
    private objectUseCase: SpecialtiesRepository
    constructor(_objectUseCase: SpecialtiesRepository) {
        this.objectUseCase = _objectUseCase;
    }

    async invoke() {
        return this.objectUseCase.getSpecialties()
    }
}
