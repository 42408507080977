import { IPendingTasks } from "../../../infrastructure/dtos"
import { IGetPendingTasksService, getPendingTasksService } from "../Persistencia/PendingTasksService"

export interface IGetPendingTasksUtility {
  status: number
  data: IPendingTasks | string
}

export async function getPendingTasksUtility(
  user_id: string
): Promise<IGetPendingTasksUtility> {
  try {
    const { data, status }: IGetPendingTasksService = await getPendingTasksService(user_id)
    return {
      status: data.statusCode === 200 && status ? status : 0,
      data: data.body
    }
  } catch (error) {
    return {
      status: 0,
      data: error as string
    }
  }
}