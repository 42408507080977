import { useState } from "react"
import { getAppointmentRecipesUtility } from "../../../../../../../services/Contracts/Utility/PrescriptionsUtility"
import { IPastAppointment } from "../../../../../../../infrastructure/dtos/Appointments"
import { IRecipePrescription } from "../../../../../../../infrastructure/dtos/Prescription"

interface IUseShowRecipesListModal {
  handleOpenRecipesListModal(value: boolean): void
  setSelectedAppointment: React.Dispatch<React.SetStateAction<IPastAppointment | undefined>>
  openRecipesListModal: boolean
  loadingRecipesList: boolean
  appointmentDetails?: IRecipePrescription
  openRecipe: boolean
  openLoadingRecipesModal: boolean
  prescriptionIndex?: number
  handleOpenRecipe(value: boolean): void
  handleOpenLoadingRecipesModal: (value: boolean) => void
  fetchAppointmentRecipes: (appointmentId: string) => Promise<void>
  handleSetPrescriptionIndex: (index: number) => void
}

export function useShowRecipesListModal(): IUseShowRecipesListModal {
  const [openRecipesListModal, setOpenRecipesListModal] =
    useState<boolean>(false)
  const [openLoadingRecipesModal, setOpenLoadingRecipesModal] =
    useState<boolean>(false)
  const [openRecipe, setOpenRecipe] = useState<boolean>(false)
  const [prescriptionIndex, setPrescriptionIndex] = useState<number>()
  const [loadingRecipesList, setLoadingRecipesList] = useState<boolean>(false)
  const [selectedAppointment, setSelectedAppointment] = useState<IPastAppointment>()
  const [appointmentDetails, setAppointmentDetails] = useState<IRecipePrescription>()

  const fetchAppointmentRecipes = async (appointmentId: string): Promise<void> => {
    try {
      setLoadingRecipesList(true)
      const { data, status } = await getAppointmentRecipesUtility(appointmentId)
      if (status) {
        setAppointmentDetails(data as IRecipePrescription)
      }
      setLoadingRecipesList(false)
    } catch (error: unknown) {
      setLoadingRecipesList(false)
      console.error('Error in fetchAppointmentRecipes:', error)
    }
  }

  const handleOpenRecipe = (value: boolean): void => setOpenRecipe(value)
  const handleOpenLoadingRecipesModal = (value: boolean): void =>
    setOpenLoadingRecipesModal(value)
  const handleOpenRecipesListModal = (value: boolean): void =>
    setOpenRecipesListModal(value)
  
  const handleSetPrescriptionIndex = (index: number) => setPrescriptionIndex(index)

  return {
    handleOpenRecipesListModal,
    setSelectedAppointment,
    handleOpenRecipe,
    handleOpenLoadingRecipesModal,
    fetchAppointmentRecipes,
    handleSetPrescriptionIndex,
    prescriptionIndex,
    openRecipesListModal,
    loadingRecipesList,
    appointmentDetails,
    openRecipe,
    openLoadingRecipesModal,
  }
}
