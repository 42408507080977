import { styled } from '@mui/material'
import ButtonWrapper from '../wrapper/ButtonWrapper'

export const OutLineButton = styled(ButtonWrapper)((props) => ({
  margin: '0 5px',
  textTransform: 'capitalize',
  borderRadius: '50px',
  padding: '14px 24px',
  height: '43px',
  width: '95%',
}))
