import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField
} from '@mui/material'
import { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
interface IComponent {
  id: string
  label: string
  list: string[]
  error?: boolean
  message?: string
  value: string
  setValue: (valueData: string) => void
}

const AutocompleteComponent = ({
  id,
  label,
  error,
  list,
  message,
  setValue,
  value
}: IComponent) => {
  const [dataSelect, setDataSelect] = useState<string | null>(value)
  return (
    <FormControl error={error}>
      <Autocomplete
        forcePopupIcon={false}
        sx={{
          border: 'none',
          width: '400px',
          borderRadius: '55px',
          backgroundColor: '#fff',
          fontSize: '20px',
          fontFamily: 'Helvetica',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }}
        value={dataSelect || null}
        onChange={(event: any, newValue: string | null) => {
          setDataSelect(newValue)
        }}
        inputValue={value}
        onInputChange={(event, newInputValue) => {
          setValue(newInputValue)
        }}
        id={id}
        options={list}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          )
        }}
      />
      {error && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  )
}

export default AutocompleteComponent
