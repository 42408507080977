import { FormEvent, useEffect, useState } from 'react'

import GridWrapper from '../../../components/wrapper/GridWrapper'
import StackWrapper from '../../../components/wrapper/StackWrapper'
import {
  LoginContentsStyle,
  TypographyLabel,
  TypographyLabelContentsStyle
} from '../../../utils/RegisterMedicalFormStyles'
import { validateEmail } from '../../../utils/validateEmail'
import FormTextInput from '../../../components/FormInputText'
import FormInputIconText from '../../../components/FormInputIconText'
import SubmitButton from '../../../components/SubmitButton'
import { SecondaryMediumButton } from '../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { Advert } from '../../../components/Notification/Advert/Advert'
import { RadioButtonValidation } from '../../../components/RadioButtonValidation/RadioButtonValidation'
import { Title } from '../../../components/StyledComponents/Title'
import { CheckboxSquare } from '../../../components/CheckboxSquare/CheckboxSquare'
import { Auth0Error } from 'auth0-js'
import { auth } from '../../../utils/auth0'
import axios from 'axios'

export const RegisterPatientForm = (): JSX.Element => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [agreement, setAgreement] = useState<boolean>(false)

  const [validLength, setValidLength] = useState<boolean>(false)
  const [hasNumber, setHasNumber] = useState<boolean>(false)
  const [upperCase, setUpperCase] = useState<boolean>(false)
  const [lowerCase, setLowerCase] = useState<boolean>(false)
  const [match, setMatch] = useState<boolean>(false)
  const [requiredLength] = useState<number>(8)

  const [blur, setBlur] = useState<boolean[]>([false, false])

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(false)

  const [message, setMessage] = useState<string>('')

  const handleClose = (): void => {
    setOpen(false)
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API}/save_data_temporary/user_medical`,
        {
          body: {
            user_email: email,
            user_password: password
          }
        }
      )

      if (result.data?.body === 'Usuario creado satisfactoriamente.') {
        auth.signup(
          {
            email,
            password,
            connection: process.env.REACT_APP_AUTH0_REALM
          },
          (err: Auth0Error | null) => {
            if (err) return alert('Something went wrong: ' + err)
            setMessage(
              'Correo electrónico enviado, Revise su bandeja de entrada para continuar con el proceso de registro.'
            )
            setOpen(true)
          }
        )
      } else {
        alert('Algo salió mal. Intente de nuevo más tarde')
      }
    } catch (error: any) {
      alert(error)
      console.log(error)
    }
  }

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...blur]
    newValidationFocus[index] = true
    setBlur(newValidationFocus)
  }

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show)
  }
  const handleClickShowConfirmdPassword = () => {
    setShowConfirmPassword((show) => !show)
  }

  useEffect(() => {
    setValidLength(password.length >= requiredLength ? true : false)
    setUpperCase(password.toLowerCase() !== password)
    setLowerCase(password.toUpperCase() !== password)
    setHasNumber(/\d/.test(password))
    setMatch(password === confirmPassword)
  }, [password, confirmPassword, requiredLength])

  const handleFederatedSignInGoogle = async (): Promise<void> => {
    auth.authorize(
      {
        responseType: 'token',
        redirectUri: process.env.REACT_APP_AUTH_LOGIN_REDIRECT_URI,
        connection: 'google-oauth2'
      },
      (err: Auth0Error | null) => {
        if (err) {
          setMessage('No se puede Conectar con Google.')
          setOpen(true)
        } else {
          setMessage('Registro exitoso')
          setOpen(true)
        }
      }
    )
  }
  const handleFederatedSignInFacebook = async (): Promise<void> => {
    auth.authorize(
      {
        responseType: 'token',
        redirectUri: process.env.REACT_APP_AUTH_LOGIN_REDIRECT_URI,
        connection: 'facebook'
      },
      (err: Auth0Error | null) => {
        if (err) {
          setMessage('No se puede Conectar con Facebook.')
          setOpen(true)
        } else {
          setMessage('Registro exitoso')
          setOpen(true)
        }
      }
    )
  }

  return (
    <>
      <GridWrapper item>
        <form onSubmit={onSubmit} data-testid="register-form">
          <LoginContentsStyle item xs={12}>
            <Title>Bienvenido, regístrate aquí</Title>
          </LoginContentsStyle>
          <GridWrapper item xs={12} sx={{ mt: 2 }}>
            <FormTextInput
              id="email"
              type="email"
              placeholder="Joedoe@gmail.com"
              fullWidth
              name="email"
              value={email}
              text="Ingresa tu correo electrónico para empezar"
              onChange={(event) => setEmail(event.target.value)}
              textError="Por favor, introduce una dirección de correo electrónico
                  válida"
              validate={!validateEmail(email) && email.length > 0}
              error={email.length === 0 && blur[0]}
              onBlur={() => onValidationFocus(0)}
              helperText={
                email.length === 0 && blur[0] ? 'Campo requerido.' : ''
              }
            />
          </GridWrapper>
          <GridWrapper container spacing={2} sx={{ mt: 2 }}>
            <GridWrapper item xs={12} sx={{ mt: 2 }}>
              <FormInputIconText
                id="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="xxxxxxx"
                fullWidth
                text="Crea una contraseña"
                name="password"
                value={password}
                onChange={(event: any) => setPassword(event.target.value)}
                onClick={handleClickShowPassword}
                showPassword={showPassword}
                error={
                  (password.length === 0 && blur[1]) ||
                  (password.length > 0 &&
                    !(validLength && upperCase && lowerCase && hasNumber))
                }
                onBlur={() => onValidationFocus(1)}
                helperText={
                  password.length === 0 && blur[1] ? 'Campo requerido.' : ''
                }
              />
            </GridWrapper>
            <GridWrapper item xs={12} sx={{ mt: 2 }}>
              <FormInputIconText
                error={!match && confirmPassword.length > 0}
                helperText={
                  !match && confirmPassword.length > 0
                    ? 'Las contraseñas no coinciden.'
                    : ''
                }
                placeholder="xxxxxxx"
                id="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                fullWidth
                name="confirmPassword"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                onClick={handleClickShowConfirmdPassword}
                showPassword={showConfirmPassword}
                text="Confirmar contraseña"
              />
            </GridWrapper>
          </GridWrapper>

          {password.length > 0 &&
          !(validLength && upperCase && lowerCase && hasNumber) ? (
            <GridWrapper item xs={12} sx={{ mt: 2 }}>
              <StackWrapper
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <RadioButtonValidation
                  id="lengthText"
                  text="8 o más caracteres"
                  validation={validLength}
                />
                <RadioButtonValidation
                  id="UppercaseLowercaseText"
                  text="Letras mayúsculas y
                  minúsculas"
                  validation={upperCase && lowerCase}
                />
                <RadioButtonValidation
                  id="numberText"
                  text="Al menos un número"
                  validation={hasNumber}
                />
              </StackWrapper>
            </GridWrapper>
          ) : (
            <br></br>
          )}

          <GridWrapper container>
            <CheckboxSquare
              text="Estoy de acuerdo con los términos y condiciones"
              id="checkboxSesion"
              value={agreement}
              onChange={setAgreement}
            />
          </GridWrapper>

          <GridWrapper container sx={{ mb: 2, mt: 1 }}>
            <GridWrapper item xs={12}>
              <SubmitButton
                id="buton"
                dataTestid="ok-button"
                type="submit"
                variant="contained"
                text="Empezar"
                fullWidth
                disabled={
                  !(
                    match &&
                    validLength &&
                    hasNumber &&
                    upperCase &&
                    lowerCase &&
                    agreement &&
                    validateEmail(email)
                  )
                }
              />
            </GridWrapper>
          </GridWrapper>
          <TypographyLabelContentsStyle>
            <TypographyLabel>o</TypographyLabel>
          </TypographyLabelContentsStyle>

          <GridWrapper container spacing={2}>
            <SecondaryMediumButton
              id="ButtonRegisterGmail"
              text="Regístrate con Gmail"
              type="button"
              role="button"
              onClick={handleFederatedSignInGoogle}
            />
            <SecondaryMediumButton
              id="ButtonRegisterFacebook"
              text="Regístrate con Facebook"
              type="button"
              role="button"
              onClick={handleFederatedSignInFacebook}
            />
          </GridWrapper>
        </form>
      </GridWrapper>
      <Advert
        message={message}
        onClose={handleClose}
        open={open}
        title="Aviso"
      />
    </>
  )
}
export default RegisterPatientForm
