import { IApiResponsePatientInfo } from "../../dtos/Patient";
import { http } from "../Http";
import PatientInfoDataSource from "../dataSource/PatientDataSource";

export default class PatientInfoMapper implements PatientInfoDataSource {
  async getPatientInfo(
    idPatient: string
  ): Promise<IApiResponsePatientInfo> {
    let data = await http
      .get<IApiResponsePatientInfo>(`/send_data/data_patient/${idPatient}`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        const data: IApiResponsePatientInfo = {
          statusCode: error.response.status,
          body: error.response.body,
          headers: {
            "Access-Control-Allow-Headers": "",
            "Access-Control-Allow-Origin": "",
            "Access-Control-Allow-Methods": "",
          },
        };
        return data;
      });
    return data;
  }
}
