import { Edit, Save, Close as CloseIcon } from '@mui/icons-material'
import { GridWrapper, ModalWrapper } from '../../../../../components'
import FormTextInput from '../../../../../components/FormInputText'
import { SecondaryMediumButton } from '../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { LabelInput } from '../../../../../components/StyledComponents/LabelInput'
import { LabelInputError } from '../../../../../components/StyledComponents/LabelInputError'
import {
  BoxModal,
  CloseButton,
  EditPhoneButton,
  ResendCodeButton,
  SubtitleTypographyModal,
  TitleTypographyModal
} from './styles'
import SubmitButton from '../../../../../components/SubmitButton'
import { useModalConfirmPhoneNumber } from './hook'
import { IPhoneVerification } from '../../../../../infrastructure/dtos/PhoneVerification'
import { validateInput } from '../../../../../utils/validateInput'
import { useContext, useState } from 'react'
import { validatePhoneNumber } from '../../../../../utils/validatePhoneNumber'
import { TypographyWrapperValidationPhoneFalse } from '../../../../Register/PatientForm/PatientFormStyle'
import { Advert } from '../../../../../components/Notification/Advert/Advert'
import {
  PatientAppointmentContext,
  PatientAppointmentInitState
} from '../../../../../contexts/PatientAppointmentContext'
import {
  SearchInitState,
  SearchOfficesContext
} from '../../../../../contexts/SeachOfficesContext'
import { useNavigate } from 'react-router-dom'

interface IModalConfirmPhoneNumberProps {
  handleClose: () => void
  open: boolean
  initialPhoneVerification?: IPhoneVerification
}
export function ModalConfirmPhoneNumber({
  handleClose,
  open,
  initialPhoneVerification
}: IModalConfirmPhoneNumberProps) {
  const { saveScheduleSelected } = useContext(PatientAppointmentContext)
  const { savelastSearch } = useContext(SearchOfficesContext)
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([false])

  const {
    isEditingPhoneNumber,
    enableSendCodeButton,
    enabledResendCodeButton,
    isSavingPhone,
    codeValue,
    phoneNumber,
    countdownTimer,
    message,
    phoneVerification,
    handleIsEditingPhoneNumber,
    onChangePhoneNumber,
    onChangeCodeValue,
    fetchResendCode,
    fetchSendCode,
    fetchVerifyCode,
    messageModal,
    openModalAlert
  } = useModalConfirmPhoneNumber(initialPhoneVerification)

  const navigate = useNavigate()

  const handleCloseAlert = () => {
    navigate('/')
    saveScheduleSelected(PatientAppointmentInitState)
    savelastSearch(SearchInitState)
  }

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]
    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  const validateMobile: boolean =
    !validateInput(phoneNumber) &&
    phoneNumber.length === 0 &&
    ValidationFocus[2]

  return (
    <>
      <ModalWrapper onClose={handleClose} open={open}>
        <BoxModal>
          <GridWrapper container display={'flex'} justifyContent={'center'}>
            <TitleTypographyModal id="transition-modal-title" variant="h6">
              Confirma tus datos para agendar tu cita
            </TitleTypographyModal>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
          </GridWrapper>

          <GridWrapper
            container
            data-testid="phone-number"
            marginTop={5}
            display={'flex'}
            justifyContent={'space-around'}
            alignItems={'flex-end'}
          >
            <GridWrapper item xs={5}>
              <LabelInput>Número de teléfono</LabelInput>
              <FormTextInput
                placeholder="09......"
                id="phone-number"
                type="text"
                fullWidth={true}
                value={phoneNumber}
                name="phone-number"
                onChange={(event) => onChangePhoneNumber(event.target.value)}
                onBlur={() => onValidationFocus(0)}
                disabled={!isEditingPhoneNumber}
                inputRef={(element: HTMLInputElement) =>
                  isEditingPhoneNumber ? element?.focus() : element?.blur()
                }
                validate={validateMobile}
                error={validateMobile}
                textError={validateMobile ? 'Campo requerido.' : ''}
              />
              {!validatePhoneNumber(phoneNumber) && phoneNumber.length > 0 && (
                <TypographyWrapperValidationPhoneFalse>
                  Ingresa un teléfono celular válido
                </TypographyWrapperValidationPhoneFalse>
              )}
            </GridWrapper>
            <GridWrapper item xs={4} paddingBottom={0.5}>
              <SecondaryMediumButton
                text="Enviar código"
                id="send-code"
                type="button"
                fullWidth={true}
                xs={12}
                disabled={isEditingPhoneNumber || !enableSendCodeButton}
                onClick={fetchSendCode}
              />
            </GridWrapper>
            <GridWrapper item xs={2}>
              <EditPhoneButton
                type="button"
                variant="outlined"
                startIcon={
                  isEditingPhoneNumber || isSavingPhone ? <Save /> : <Edit />
                }
                disabled={isSavingPhone}
                onClick={() =>
                  handleIsEditingPhoneNumber(!isEditingPhoneNumber)
                }
              >
                {isEditingPhoneNumber || isSavingPhone
                  ? 'Guardar'
                  : 'Modificar'}
              </EditPhoneButton>
            </GridWrapper>
          </GridWrapper>

          <GridWrapper
            container
            data-testid="code-section"
            marginTop={5}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <GridWrapper item>
              <SubtitleTypographyModal>
                Ingresa código de verificación enviado a tu número de teléfono
              </SubtitleTypographyModal>
            </GridWrapper>
            <GridWrapper item xs={6} marginTop={2}>
              <FormTextInput
                placeholder="Ingresa tu código aquí"
                id="code-sended"
                type="text"
                fullWidth={true}
                value={codeValue}
                name="code-sended"
                onChange={(event) => onChangeCodeValue(event.target.value)}
              />
            </GridWrapper>
            <GridWrapper item marginTop={3}>
              <ResendCodeButton
                variant="text"
                onClick={fetchResendCode}
                disabled={!enabledResendCodeButton}
              >
                ¿No recibiste el código? Reenvíalo
              </ResendCodeButton>
            </GridWrapper>
            <GridWrapper item marginTop={0.5}>
              <LabelInputError sx={{ color: 'GrayText' }}>
                El código de verificación expira en: <b>{countdownTimer}</b>
              </LabelInputError>
            </GridWrapper>
          </GridWrapper>
          <GridWrapper
            container
            marginTop={5}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {message && (
              <GridWrapper item marginBottom={0.5}>
                <LabelInputError
                  sx={{ color: 'primary.dark' }}
                  textAlign={'center'}
                >
                  {message}
                </LabelInputError>
              </GridWrapper>
            )}
            <SubmitButton
              id={'confirm-phone-number'}
              dataTestid={'confirm-phone-number'}
              variant={'contained'}
              fullWidth={true}
              type={'submit'}
              text={'Confirmar'}
              disabled={
                !codeValue ||
                phoneVerification?.remaining_intents_validation === 0
              }
              onClick={fetchVerifyCode}
            />
          </GridWrapper>
        </BoxModal>
      </ModalWrapper>
      <Advert
        message={messageModal}
        onClose={handleCloseAlert}
        open={openModalAlert}
        title="Aviso"
      />
    </>
  )
}
