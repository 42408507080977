import { createContext, useState } from 'react'
import { MedicalOffice } from '../infrastructure/dtos/Offices'

export interface IPatientAppointmentState {
  office_id: string
  date_appointment: string
  hour_appointment: string
  consultation_time_minutes: number
  patient_name: string
  patient_last_name: string
  patient_email: string
  patient_phone_number: string
  appointment_reason: string
  add_to_calendar: boolean
  office: MedicalOffice | null
  comment?: string
  reference?: string
  patient_id_number: string
}

export interface IScheduleSelected {
  office_id: string
  date_appointment: string
  hour_appointment: string
  consultation_time_minutes: number
  office: MedicalOffice | null
  comment?: string
  reference?: string
}

export interface IAppointmentInfo {
  patient_name: string
  patient_last_name: string
  patient_email: string
  patient_phone_number: string
  appointment_reason: string
  add_to_calendar: boolean
  patient_id_number: string
}

export const PatientAppointmentInitState: IPatientAppointmentState = {
  office_id: '',
  date_appointment: '',
  hour_appointment: '',
  consultation_time_minutes: 0,
  patient_name: '',
  patient_last_name: '',
  patient_email: '',
  patient_phone_number: '',
  appointment_reason: '',
  add_to_calendar: false,
  office: null,
  comment: '',
  reference: '',
  patient_id_number: ''
}

type RegisterMedicalContextProps = {
  PatientAppointment: IPatientAppointmentState
  saveScheduleSelected: (scheduleSelected: IScheduleSelected) => void
  saveAppointmentFormInfo: (appointmentInfo: IAppointmentInfo) => void
}

export const PatientAppointmentContext = createContext(
  {} as RegisterMedicalContextProps
)

export const PatientAppointmentProvider = ({ children }: any): any => {
  const [patientAppointment, setPatientAppointment] = useState(
    PatientAppointmentInitState
  )

  const saveScheduleSelected = (scheduleSelected: IScheduleSelected): void => {
    setPatientAppointment({
      ...patientAppointment,
      office_id: scheduleSelected.office_id,
      date_appointment: scheduleSelected.date_appointment,
      hour_appointment: scheduleSelected.hour_appointment,
      consultation_time_minutes: scheduleSelected.consultation_time_minutes,
      office: scheduleSelected.office,
      comment: scheduleSelected.comment || '',
      reference: scheduleSelected.reference
    })
  }

  const saveAppointmentFormInfo = (appointmentInfo: IAppointmentInfo): void => {
    setPatientAppointment({
      ...patientAppointment,
      patient_name: appointmentInfo.patient_name,
      patient_last_name: appointmentInfo.patient_last_name,
      patient_email: appointmentInfo.patient_email,
      patient_phone_number: appointmentInfo.patient_phone_number,
      appointment_reason: appointmentInfo.appointment_reason,
      add_to_calendar: appointmentInfo.add_to_calendar,
      patient_id_number: appointmentInfo.patient_id_number
    })
  }

  return (
    <PatientAppointmentContext.Provider
      value={{
        PatientAppointment: patientAppointment,
        saveScheduleSelected,
        saveAppointmentFormInfo
      }}
    >
      {children}
    </PatientAppointmentContext.Provider>
  )
}
