


export default function PrescriptionResume() {

  return (
    <>
      <h1>PrescriptionResume</h1>
    </>
  )
}
