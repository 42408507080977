import { styled } from '@mui/material'
import ButtonWrapper from '../wrapper/ButtonWrapper'

export const PrimaryButton = styled(ButtonWrapper)(({theme}) => ({
  borderRadius: '2.625rem',
  backgroundColor: theme.palette.primary.dark,
  fontSize: '14px',
  height: '3.4375rem',
  textTransform: 'none'
}))
